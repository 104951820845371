import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, InputGroup } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner';
import axios from 'axios';
import ReactTooltip from 'react-tooltip';
import { Helmet } from 'react-helmet';
import { BASE_URL } from '../../../../env/Baseurl';
import '../../../../styles/adminlte.min.css';
import '../../../../styles/dashboard-style.css';
import Header from '../../../global/Header';
import Sidebar from '../../../global/Sidebar';
import Footer from '../../../global/Footer';
import { GetWhatWasLearned } from '../../../../services/part4/what-was-learned';
import { myprintFunction } from '../../../../actions/NormalPrintFunction';
// import FooterOfPrint from '../../../global/FootorOfPrint';



const Handout4 = () => {

    const [whatWasLearned, errorMessage] = GetWhatWasLearned();
    const [successMessage, setSuccessMessage] = useState('');
    const [errorApiMessage, setErrorMessage] = useState('');

    const [state, setState] = useState();

    const [currentRadioValue, setCurrentRadioValue] = useState()

    const preTreatmentProgress = localStorage.setItem('preTreatmentProgress', 12);

    const userInfo = localStorage.getItem('user');
    const user = JSON.parse(userInfo);

    let navigate = useNavigate();


    return (
        <>
            <div id="printAble"></div>
            <div id="mainContainer" className="dashboardContent">
                <Helmet>
                    <title>Handout - A Behavioural Experiment| ASRC iGlebe</title>
                </Helmet>
                <div>
                    {/* <Header/> */}
                    <Header targeted_print_component_id={"main"} myprintFunction={myprintFunction} />
                </div>
                <div className="row">
                    <div className="col-md-2  position-static pl-0">
                        <div className="leftside">
                            <Sidebar />
                        </div>
                    </div>
                    <div id="main" className="col-md-10 content-widthsidebar content-wrapper">
                        <div className="card">
                            <div className="card-body">
                                <div className="title mb-3">
                                    Handout - A Behavioural Experiment
                                </div>
                                <p>
                                    <span className='redText'>My causal thought was:</span> {whatWasLearned.length !== 0 && <>{whatWasLearned.my_casual_thought_was}</>}
                                </p>
                                <p>
                                    <span className='redText'>The task I completed was:</span> {whatWasLearned.length !== 0 && <>{whatWasLearned.statement}</>}
                                </p>
                                <p className='redText'>
                                    I predicted these three things:
                                </p>
                                <ul>
                                    {whatWasLearned !== undefined && whatWasLearned.length !== 0 ? (
                                        <div>

                                            {whatWasLearned.pred_prob_data.map((item, index) => {
                                                const card = (
                                                    <li key={index}>{item.statement}</li>
                                                )
                                                return card;
                                            })}
                                        </div>
                                    )
                                        :
                                        <div className="loader-icon">
                                            <TailSpin color="#4f5962" height={50} width={50} />
                                        </div>
                                    }
                                </ul>
                                <p className='redText'>
                                    This is what happened:
                                </p>
                                <ul>
                                    {whatWasLearned !== undefined && whatWasLearned.length !== 0 ? (
                                        <div>

                                            {whatWasLearned.This_is_what_happened.map((item, index) => {
                                                const card = (
                                                    <li key={index}>{item.userresponce}</li>
                                                )
                                                return card;
                                            })}
                                        </div>
                                    )
                                        :
                                        <div className="loader-icon">
                                            <TailSpin color="#4f5962" height={50} width={50} />
                                        </div>
                                    }
                                </ul>
                            </div>
                        </div>
                        {/* <div>
                            <FooterOfPrint />
                        </div> */}
                    </div>
                    <div className="col-md-12 dashboard-footer">
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Handout4;