import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import ExcerciseAnswers from "../templates/Answers/ExerciseAnswers";
import { GetUserResponse } from "../../../../services/part1/get-user-response";
import ModuleCompletionEmails from "../../../../services/module-completion-emails";
import { useTranslation } from "react-i18next";

const ThinkingResponse4 = () => {
  const { t } = useTranslation("partOneThinkingResponseFour");

  const userInfo = localStorage.getItem("user"); // Logged in user params
  const user = JSON.parse(userInfo);

  const [userResponse, errorMessage] = GetUserResponse(4);

  /*******   Page Params  *******/

  const title = t("title");

  const content = `<div>${t("description")}</div>`;

  /*******   Hooks  *******/

  useEffect(() => {
    ModuleCompletionEmails("sendmailPart1"); // Sending module completion email
  }, []);

  /*******   Render  *******/

  return (
    <div>
      <Helmet>
        <title>{t("helmet")}</title>
      </Helmet>
      <ExcerciseAnswers
        title={title}
        content={content}
        responses={userResponse}
        link="part_1/complete"
        type={"ThinkingResponse1"}
        sitnum={4}
        error={errorMessage}
        previousLink="part_1/thinking_exercise_4"
      />
    </div>
  );
};

export default ThinkingResponse4;
