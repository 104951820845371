import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import axios from "axios";
import { Helmet } from "react-helmet";
import { BASE_URL } from "../../../env/Baseurl";
import { register } from "../../../actions/auth";
import { CLEAR_MESSAGE } from "../../../actions/types";
import UserAccountInfo from "../../registration/UserAccountInfo";
import UserLocationInfo from "../../registration/UserLocationInfo";
import UserNameInfo from "../../registration/UserNameInfo";
import UserPasswordInfo from "../../registration/UserPasswordInfo";
import UserSituationInfo from "../../registration/UserSituationInfo";
import "../../../styles/login.css";
import { Link } from "react-router-dom";
import UserLanguage from "../../registration/UserLanguage";
import i18n from "i18next";

const RegisterScreen = () => {
  /*******   States  *******/
  const [emptyCheck, setEmptyCheck] = useState({
    user_email: "",
    user_name: "",
    password: "",
    confirm_password: "",
    user_situation: "",
    applied_situation: "",
    first_name: "",
    last_name: "",
    // city: "",
    country: "",
    terms: "",
    languageCode: "",
  });
  const [values, setValues] = useState({
    user_email: "",
    user_name: "",
    password: "",
    confirm_password: "",
    user_situation: "",
    applied_situation: "",
    first_name: "",
    last_name: "",
    city: "",
    country: "",
    countryCode: "",
    dialCode: "",
    languageCode: "",
    language: "",
  });
  // useEffect(() => {
  //   console.log(values, "in the beginig")
  // }, [values])
  const [step, setStep] = useState(1);
  const [emailCheck, setEmailCheck] = useState();
  const [checked, setChecked] = useState(false);
  const [successful, setSuccessful] = useState(false);

  /*******   Redux  *******/
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();

  /*******   HelperFunctions  *******/

  const domainName = window.location.hostname;

  let navigate = useNavigate();

  const emailChecker = async () => {
    try {
      const params = JSON.stringify({
        email: values.user_email,
      });
      const response = await axios.post(BASE_URL + "checkEmail", params, {
        headers: {
          "content-type": "application/json",
        },
      });
      setEmailCheck(response.data.status);
    } catch (error) {
      setEmailCheck("Internal server error. Please try again later.");
    }
  };

  const nextStep = (e) => {
    if (step === 1) {
      if (values.user_email === "" || values.user_name === "") {
        {
          values.user_email === ""
            ? setEmptyCheck({
                user_email: "Please fill out Email field to continue.",
              })
            : setEmptyCheck({
                user_name: "Please fill out Username field to continue.",
              });
        }
      } else if (validator.isEmail(values.user_email) === false) {
        setEmptyCheck({ user_email: "Please check the email format." });
      } else if (emailCheck === false) {
        setEmptyCheck({
          user_email:
            "This email already exists in our system, Please use another one",
        });
      } else {
        setEmptyCheck({ user_email: "" });
        setStep(step + 1);
      }
    }
    if (step === 2) {
      if (values.password === "" || values.confirm_password === "") {
        {
          values.password === ""
            ? setEmptyCheck({
                password: "Please fill out Password field to continue.",
              })
            : setEmptyCheck({
                confirm_password:
                  "Please fill out Confirm Password field to continue.",
              });
        }
      } else if (values.password !== values.confirm_password) {
        setEmptyCheck({
          confirm_password: "Password and Confirm Password do not match.",
        });
      } else if (values.password === values.confirm_password) {
        setEmptyCheck({ confirm_password: "Password match." });
        setStep(step + 1);
      }
    }
    if (step === 3) {
      if (values.user_situation === "" || values.applied_situation === "") {
        if (values.user_situation === "") {
          setEmptyCheck({
            user_situation: "Please select one of the options to continue.",
          });
        } else if (
          values.user_situation === "I am a person who stutters" &&
          values.applied_situation === ""
        ) {
          setEmptyCheck({
            applied_situation: "Please select one of the options to continue.",
          });
        } else {
          setValues({ ...values, ["applied_situation"]: "none" });
        }
      } else {
        setStep(step + 1);
      }
    }
    if (step === 4) {
      if (values.first_name === "" || values.last_name === "") {
        {
          values.first_name === ""
            ? setEmptyCheck({
                first_name: "Please fill out First Name field to continue.",
              })
            : setEmptyCheck({
                last_name: "Please fill out Last Name field to continue.",
              });
        }
      } else {
        setStep(step + 1);
      }
    } else if (step === 5) {
      if (values?.languageCode === "") {
        setEmptyCheck({
          languageCode: "Please select one of the options to continue.",
        });
      } else {
        i18n.changeLanguage(values?.languageCode);
        localStorage.setItem("languageCode", values?.languageCode);
        setStep(step + 1);
      }
    } else if (step === 6 && checked === false) {
      // if (values.country === "" || values.city === "") {
      //   {
      //     values.country === "" ?
      //       setEmptyCheck({ country: 'Please fill out Country field to continue.' })
      //       :
      //       setEmptyCheck({ city: 'Please fill out City field to continue.' })
      //   }
      if (values.country === "") {
        setEmptyCheck({
          country: "Please fill out Country field to continue.",
        });
      } else {
        setEmptyCheck({
          terms: "Please accept terms and conditions to proceed.",
        });
      }
    }
    // else if (step === 5 && checked === true && values.city === "") {
    //   setEmptyCheck({ city: 'Please fill out City field to continue.' })

    // }
    else if (step === 6 && checked === true && values.country === "") {
      setEmptyCheck({ country: "Please fill out Country field to continue." });
    } else if (
      step === 6 &&
      checked === true &&
      // values.city !== "" &&
      values.country !== ""
    ) {
      e.preventDefault();
      dispatch(
        register(
          values.user_email,
          values.user_name,
          values.password,
          values.confirm_password,
          values.user_situation,
          values.applied_situation,
          values.first_name,
          values.last_name,
          values.city,
          values.country,
          values.countryCode,
          values.dialCode,
          domainName,
          values.languageCode
        )
      )
        .then(() => {
          setSuccessful(false);
        })
        .catch(() => {
          setSuccessful(false);
        });
    }
  };

  const prevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleChange = (name) => (e) => {
    setValues({ ...values, [name]: e.target.value });
  };

  const locationHandleChange = (name) => (e) => {
    const result = e.target.value.replace(/[^a-z]/gi, "");

    setValues({ ...values, [name]: result });
  };

  const selectChange = (name, value) => (e) => {
    setValues({ ...values, [name]: value.value });
  };

  const num = step * 20;

  const changePasswordColor = () => ({
    width: `${num}%`,
    background: "#FF2305",
    height: "7px",
  });

  const handleLogin = () => {
    navigate("/");
  };
  const handleForgotPassword = () => {
    navigate("/reset-password");
  };
  const hangleIdleTime = () => {
    navigate("/useIdleTimer");
  };

  /*******   Hooks  *******/
  useEffect(() => {
    dispatch({
      type: CLEAR_MESSAGE,
    });
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (
        message ===
        "Successfully Registered. You will be redirected to dashboard."
      ) {
        navigate("/intro/welcome", { replace: true });
      }
    }, 2000);
  }, [message]);

  /*******   Render  *******/
  return (
    <div className="d-flex align-items-center login-container-wrap">
      <Helmet>
        <title>Create new account | ASRC iGlebe</title>
      </Helmet>
      <div className="container">
        <div>
          {
            {
              1: (
                <UserAccountInfo
                  handleChange={handleChange}
                  emailChecker={emailChecker}
                  emptyCheck={emptyCheck}
                  values={values}
                />
              ),
              2: (
                <UserPasswordInfo
                  handleChange={handleChange}
                  emptyCheck={emptyCheck}
                  values={values}
                />
              ),
              3: (
                <UserSituationInfo
                  handleChange={handleChange}
                  selectChange={selectChange}
                  emptyCheck={emptyCheck}
                  values={values}
                  setValues={setValues}
                />
              ),
              4: (
                <UserNameInfo
                  handleChange={handleChange}
                  emptyCheck={emptyCheck}
                  values={values}
                />
              ),
              5: (
                <UserLanguage
                  emptyCheck={emptyCheck}
                  values={values}
                  setValues={setValues}
                />
              ),
              6: (
                <UserLocationInfo
                  locationHandleChange={locationHandleChange}
                  setChecked={setChecked}
                  checked={checked}
                  emptyCheck={emptyCheck}
                  values={values}
                  setValues={setValues}
                />
              ),
            }[step]
          }
          <div className="register-footer-btn regbtn-sections text-center">
            <div className="row m-0">
              <div className="col-md-6"></div>
              <div className="col-md-6 reg-btn-block">
                <div className="regbtn-block">
                  {step > 1 ? (
                    <button
                      onClick={prevStep}
                      className="btn  prevbtn btn-block btn-primary btn-sm"
                    >
                      Previous Page
                    </button>
                  ) : null}
                  <button
                    onClick={nextStep}
                    className="btn nextbtn btn-block btn-primary btn-sm border-0"
                  >
                    {step === 6 ? "Submit" : "Continue"}
                  </button>
                </div>
                <div className="card-body progress-barblock mt-3">
                  <div className="progress" style={{ height: "7px" }}>
                    <div
                      className="progress-bar"
                      style={changePasswordColor()}
                    ></div>
                  </div>
                </div>
                {/* <div className='card-body' style={{ paddingTop: "0px", marginTop: "16px" }}>
                  <div className="row">
                    <div className='col-lg-6' style={{}}>
                      <button
                        onClick={handleLogin}
                        className="btn btn-block btn-primary" style={{ marginTop: '0px' }}>
                        Login
                      </button>
                    </div>
                    <div className='col-lg-6 mt-2 mt-lg-0 '>
                      <button
                        onClick={handleForgotPassword}
                        className="btn btn-block btn-primary" style={{ marginTop: '0px' }}>
                        Forgot Password
                      </button>
                    </div>
                  </div>
                </div> */}
                <div
                  className=" form-group login-sign-up text-center signuptext align-item-top d-flex flex-row justify-content-center"
                  style={{ gap: "6px", marginTop: "13px" }}
                >
                  <Link to="/">Login</Link>
                  <span className="btn-seprator">|</span>
                  <Link to="/reset-password">Forgot Password</Link>
                </div>
                {message && (
                  <div className="form-group px-3 pt-3">
                    <div
                      className={
                        message ===
                        "Successfully Registered. You will be redirected to dashboard."
                          ? "alert alert-success"
                          : "alert alert-danger"
                      }
                      role="alert"
                    >
                      {message}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterScreen;
