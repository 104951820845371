import React, { useState } from 'react';
import { Button, InputGroup } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner';
import ReactTooltip from 'react-tooltip';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import { Helmet } from 'react-helmet';

import { BASE_URL } from '../../../../env/Baseurl';
import ErrorLogging from '../../../../services/error-logs';
import RefreshToken from '../../../../services/refresh-token';
import '../../../../styles/adminlte.min.css';
import '../../../../styles/dashboard-style.css';
import Header from '../../../global/Header';
import Sidebar from '../../../global/Sidebar';
import Footer from '../../../global/Footer';
import { GetPredictionSituation, GetUserPredictionSituation } from '../../../../services/part4/predictions-situation-task';
import FooterOfPrint from '../../../global/FootorOfPrint';
import { useTranslation } from 'react-i18next';



const Part4SixthPage = () => {

     /*******   Multi-language Translation *******/
     const {t} = useTranslation(["partFourSixthPage", "actions"])



    const [predictionSituation, errorPredictionMessage] = GetPredictionSituation();             // Get Prediction Situation from api
    const [predictionUserSituation, errorUserMessage] = GetUserPredictionSituation();           // Get User Prediction User from api

    /*******   States *******/

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [state, setState] = useState({ data: [] });
    const [disabled, setDisabled] = useState(true);

    // error modal
    const [showError, setShowError] = useState(false);
    const handle = () => setShowError(true);
    let close = () => setShowError(false);

    /*******  Constants  *******/

    const options = [
        "0",
        "10",
        "20",
        "30",
        "40",
        "50",
        "60",
        "70",
        "80",
        "90",
        "100"
    ];

    /*******   Helper Functions  *******/

    let navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (state.data.some(item => item.predictions_userresponce_id === name)) {

            let updatedData = state.data.map(item => {
                if (item.predictions_userresponce_id === name) {
                    return { ...item, "predictions_userresponce_id": name, "rating_scale": value };
                }
                return item;
            });

            setState({ data: updatedData });
        } else {
            setState(state => ({
                data: [...state.data, { "predictions_userresponce_id": name, "rating_scale": value }]
            }));

        }

        if (state.data.length === 2) {
            setDisabled(false);
        }

    };

    const postSubmission = async () => {

        const userInfo = localStorage.getItem('user');                                      // Logged in user info
        const user = JSON.parse(userInfo);

        try {
            const params = JSON.stringify({
                "data": state.data
            });
            const response = await axios.post(BASE_URL + `prediction_probabilitie_userresponce/${user.userInfo._id}`, params, {
                "headers": {
                    "Authorization": `Bearer ${user.token}`,
                    "content-type": "application/json",
                },
            })
            setSuccessMessage(response.data);
            if (state.data.length === 3) {
                navigate('/part_4/the_experiment', { replace: true })

            } else {
                alert('Please fill all the fields.');
            }
        } catch (error) {
            if (error.response.status === 401) {
                RefreshToken(user.userInfo._id)
                postSubmission();
            }
            else {
                setErrorMessage('Please check Part 4 Prediction Probabilitie Userresponse Api');
                ErrorLogging('HTTP POST Part 4 /prediction_probabilitie_userresponce/userId' + error);
                handle();
            }

        }

    };

    const nextPage = () => {
        navigate('/part_4/the_experiment', { replace: true })
    };

    const previousPage = () => {
        navigate(`/part_4/predictions`, { replace: true })

    };


    //print function
    const myprintFunction = () => {
        var realData = document.getElementById("main").innerHTML

        var secondData = document.getElementById("printAble").innerHTML
        document.getElementById('printAble').innerHTML = realData
        document.getElementById("mainContainer").style.display = "none";
        // document.getElementById("printFooter").style.display = "block";
        window.print();
        document.getElementById("mainContainer").style.display = "block";
        document.getElementById("printAble").innerHTML = secondData;
        // if (dataSubmitted === true) {
        //     document.getElementById('main').innerHTML = realData;
        // }
        if (predictionUserSituation !== undefined && predictionUserSituation.length !== 0 && predictionUserSituation.global_disable_status === false) {

        }
        else {
            document.getElementById('main').innerHTML = realData;
        }
    }


    /*******   Render  *******/

    return (
        <>
            <div id="printAble"></div>
            <div id="mainContainer" className="dashboardContent">
                <Helmet>
                    <title>{t('partFourSixthPage:helmet')}</title>
                </Helmet>
                <div>
                    {/* <Header/> */}
                    <Header targeted_print_component_id={"main"} myprintFunction={myprintFunction} />
                </div>
                <div className="row">
                    <div className="col-md-2  position-static pl-0">
                        <div className="leftside">
                            <Sidebar />
                        </div>
                    </div>
                    <div className="col-md-10 content-widthsidebar content-wrapper">
                        <div className="card">
                            <div className="card-body">
                                <div id="main">
                                    <div className="card-body">
                                        <div className="title mb-3">
                                        {t('partFourSixthPage:title')}
                                        </div>
                                        <p>
                                            <span className='redText'>{t('partFourSixthPage:content.line1')}</span>{predictionSituation.length !== 0 && <>{predictionSituation.situationtask_data.situation}</>}
                                        </p>
                                        <p>
                                            <span className='redText'>{t('partFourSixthPage:content.line2')} </span> {predictionSituation.length !== 0 && <>{predictionSituation.situationtask_data.task}</>}
                                        </p>
                                        <p className='redText'>
                                        {t('partFourSixthPage:content.line3')}
                                        </p>
                                        <ul>
                                            {
                                                errorPredictionMessage ? (<div className="container mt-5">
                                                    <div className='row justify-content-center'>
                                                        <div className="alert alert-danger text-center" role="alert">
                                                            {errorPredictionMessage}
                                                        </div>
                                                    </div>
                                                </div>)
                                                    :
                                                    predictionSituation !== undefined && predictionSituation.length !== 0 ? (
                                                        <div>
                                                            {predictionSituation.safty_behaviour_data.map((item, index) => {
                                                                const card = (
                                                                    <li key={index}>{item.question}</li>
                                                                )
                                                                return card;
                                                            })}
                                                        </div>
                                                    )
                                                        :
                                                        <div className="loader-icon">
                                                            <TailSpin color="#4f5962" height={50} width={50} />
                                                        </div>
                                            }
                                        </ul>
                                        <p className='redText'>
                                        {t('partFourSixthPage:content.line4')}
                                        </p>
                                        <div>
                                            {
                                                errorUserMessage ? (<div className="container mt-5">
                                                    <div className='row justify-content-center'>
                                                        <div className="alert alert-danger text-center" role="alert">
                                                            {errorUserMessage}
                                                        </div>
                                                    </div>
                                                </div>)
                                                    :
                                                    predictionUserSituation !== undefined && predictionUserSituation.length !== 0 ? (
                                                        <div className="table-responsive">
                                                            <table className="table questions-table predictions">
                                                                <thead>
                                                                    <tr>
                                                                        <th></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {predictionUserSituation.predec_data.map((item, index) => (
                                                                        <tr key={index}>
                                                                            <td>{"'" + item.predictions_question + "'"}</td>
                                                                            {options.map((option) => (
                                                                                <td className='options' key={`${item.id}_${option}`}>

                                                                                    <InputGroup.Checkbox
                                                                                        defaultChecked={item.responce === option ? true : false}
                                                                                        disabled={item.disable_status}
                                                                                        className="radio-size"
                                                                                        type="radio"
                                                                                        aria-label="radio 1"
                                                                                        value={option}
                                                                                        name={item.id}
                                                                                        onChange={handleChange}
                                                                                    />
                                                                                    <label htmlFor="name">{option}</label>
                                                                                    <></>
                                                                                </td>
                                                                            ))}
                                                                        </tr>
                                                                    ))}
                                                                </tbody>

                                                            </table>
                                                        </div>
                                                    ) :
                                                        <div className="loader-icon">
                                                            <TailSpin color="#4f5962" height={50} width={50} />
                                                        </div>
                                            }
                                        </div>
                                    </div>
                                    {/* <div>
                                        <FooterOfPrint />
                                    </div> */}
                                </div>


                                <div className='row'>
                                    <div className='previous1 col-6'>

                                        <Button
                                            className="user-reponse-submit btn btn-primary link-sty"
                                            onClick={previousPage}

                                        >
                                            {t('actions:previous')}
                                        </Button>
                                    </div>
                                    <div className='next col-6'>
                                        {predictionUserSituation !== undefined && predictionUserSituation.length !== 0 && predictionUserSituation.global_disable_status === false ? (
                                            <>
                                                <span data-tip={disabled === true ? t('partFourSixthPage:toolTipText') : ""} data-for='toolTip'>
                                                    <Button
                                                        className="user-reponse-submit btn btn-primary link-sty"
                                                        onClick={postSubmission}
                                                        disabled={disabled}
                                                    >
                                                       {t('actions:continue')}
                                                    </Button>
                                                </span>
                                                <ReactTooltip id="toolTip" />
                                            </>
                                        ) :
                                            <>
                                                <Button
                                                    className="user-reponse-submit"
                                                    onClick={nextPage}
                                                >
                                                  {t('actions:continue')}
                                                </Button>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* error message modal */}

                    <Modal show={showError} >
                        <Modal.Body>{errorMessage}</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={close}>
                            {t('actions:close')}
                            </Button>

                        </Modal.Footer>
                    </Modal>
                    <div className="col-md-12 dashboard-footer">
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Part4SixthPage;