import React, { useEffect, useState, useLocation } from "react";
import axios from "axios";
import "../../../../../styles/adminlte.min.css";
import { Button, InputGroup } from "react-bootstrap";
import { TailSpin } from "react-loader-spinner";
import ReactTooltip from "react-tooltip";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { BASE_URL } from "../../../../../env/Baseurl";
import ErrorLogging from "../../../../../services/error-logs";
import RefreshToken from "../../../../../services/refresh-token";
import Header from "../../../../global/Header";
import Sidebar from "../../../../global/Sidebar";
import Footer from "../../../../global/Footer";
import FooterOfPrint from "../../../../global/FootorOfPrint";
import { useTranslation } from "react-i18next";

const Questionnaire = ({
  title,
  content,
  link,
  questions,
  options,
  type,
  error,
  previousLink,
}) => {
  const { t } = useTranslation(["mcq", "actions"]);

  /*******   States *******/

  const [state, setState] = useState({ data: [] });
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [disabled, setDisabled] = useState(true);
  // save as draft modal

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  let handleClose = () => setShow(false);

  // error modal
  const [showError, setShowError] = useState(false);
  const handle = () => setShowError(true);
  let close = () => setShowError(false);

  /*******  Helper Functions and variables  *******/
  let navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (state.data.some((item) => item.question_id === name)) {
      // Checking Question ID in state on change

      let updatedData = state.data.map((item) => {
        if (item.question_id === name) {
          return { ...item, question_id: name, userResponce: value }; // Updating user selection in state
        }
        return item;
      });

      setState({ data: updatedData });
    } else {
      setState((state) => ({
        data: [...state.data, { question_id: name, userResponce: value }], // Adding new question response in state
      }));
    }
    if (state.data.length + 1 >= questions.count) {
      setDisabled(false);
    }
  };

  const postSubmission = async () => {
    const userInfo = localStorage.getItem("user");

    const user = JSON.parse(userInfo);
    try {
      const params = JSON.stringify({
        data: state.data,
        saveasdraft: "false",
      });
      const response = await axios.post(
        BASE_URL + `createQuestionnairs_${type}/${user.userInfo._id}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "content-type": "application/json",
            "languageCode": user.userInfo.languageCode
          },
        }
      );
      setSuccessMessage(response.data);

      if (state.data.length >= questions.count) {
        navigate(`/${link}`, { replace: true });
      } else {
      }
    } catch (error) {
      if (error.response.status === 401) {
        RefreshToken(user.userInfo._id);
        postSubmission();
      } else {
        setErrorMessage("Please check Create Questionnaire Api");
        ErrorLogging("HTTP POST /createQuestionnairs_type/userId" + error);
        handle();
      }
    }
  };

  // SAVE AS DRAFT

  const saveDraft = async () => {
    const userInfo = localStorage.getItem("user");
    const user = JSON.parse(userInfo);
    try {
      const params = JSON.stringify({
        data: state.data,
        saveasdraft: "true",
      });
      const response = await axios.post(
        BASE_URL + `createQuestionnairs_${type}/${user.userInfo._id}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "content-type": "application/json",
            "languageCode": user.userInfo.languageCode,
          },
        }
      );

      if (response.data.status === true) {
        setSuccessMessage("Saved As Draft");
        handleShow();
      }
    } catch (error) {
      if (error.response.status === 401) {
        RefreshToken(user.userInfo._id);
        saveDraft();
      } else {
        setErrorMessage("Network Error, Please Try Again!");
        ErrorLogging("HTTP POST /createQuestionnairs_type/userId" + error);
        handle();
      }
    }
  };

  const nextPage = () => {
    navigate(`/${link}`, { replace: true });
  };

  const previousPage = () => {
    navigate(`/${previousLink}`, { replace: true });
  };

  // close modal on its own

  setTimeout(handleClose, 1000);

  useEffect(() => {
    if (questions.count === 0) {
      setDisabled(false);
    }
  }, [questions]);

  //print function
  const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML;

    var secondData = document.getElementById("printAble").innerHTML;
    document.getElementById("printAble").innerHTML = realData;
    document.getElementById("mainContainer").style.display = "none";
    // document.getElementById("printFooter").style.display = 'block'
    window.print();
    document.getElementById("mainContainer").style.display = "block";
    document.getElementById("printAble").innerHTML = secondData;
    // if (dataSubmitted === true) {
    //     document.getElementById('main').innerHTML = realData;
    // }
    if (
      questions !== undefined &&
      questions.length !== 0 &&
      questions.global_disable_status === false
    ) {
    } else {
      document.getElementById("main").innerHTML = realData;
    }
  };

  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <div>
          {/* <Header /> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row content-with-sidebar-row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-wrapper content-div">
            <div className="content">
              <div id="main">
                <div className="row header-titlewith-text m-0">
                  <div className="title col-md-6 p-0">{title}</div>
                  <div className="col-md-6 text-right p-0">
                    <span> {t("mcq:welcome")}</span> / {title}
                  </div>
                </div>
                <div className="content-topheads-text">
                  <div className="note-textheads">
                    {t("mcq:importantLabel")}
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: content }}></div>
                </div>
                <div></div>
                {error ? (
                  <div className="container mt-5">
                    <div className="row justify-content-center">
                      <div
                        className="alert alert-danger text-center"
                        role="alert"
                      >
                        {error}
                      </div>
                    </div>
                  </div>
                ) : questions !== undefined && questions.length !== 0 ? (
                  <div className="table-responsive">
                    <table className="table questions-table">
                      <thead>
                        <tr>
                          <th>{t("mcq:headingLabel")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {questions.questionnairs.map((item, index) => (
                          <tr key={item.question}>
                            <td>{index + 1 + "." + " " + item.question}</td>
                            {options.map((option) => (
                              <td key={`${item.question}_${option}`}>
                                <InputGroup.Checkbox
                                  defaultChecked={
                                    item.responce === option ? true : false
                                  }
                                  disabled={item.disable_status}
                                  className="radio-size"
                                  type="radio"
                                  aria-label="radio 1"
                                  value={option}
                                  name={item._id}
                                  selected={
                                    Boolean(state[item.question]) &&
                                    state[item.question] === option
                                  }
                                  onChange={handleChange}
                                />
                                <label htmlFor="name">{option}</label>
                                <></>
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="loader-icon">
                    <TailSpin color="#4f5962" height={50} width={50} />
                  </div>
                )}
                {/* <div>
                                    <FooterOfPrint />
                                </div> */}
              </div>
            </div>
            <div className="row">
              <div className="previous1 col-6">
                <Button
                  className="user-reponse-submit btn btn-primary link-sty"
                  onClick={previousPage}
                >
                  {t("actions:previous")}
                </Button>
              </div>
              <div className="next col-6">
                {questions !== undefined &&
                questions.length !== 0 &&
                questions.global_disable_status === false ? (
                  <>
                    <Button
                      className="user-reponse-submit save-draft-btn"
                      onClick={saveDraft}
                    >
                      {t("actions:save")}
                    </Button>
                  </>
                ) : (
                  <></>
                )}
                {questions !== undefined &&
                questions.length !== 0 &&
                questions.global_disable_status === false ? (
                  <>
                    <span
                      data-tip={
                        disabled === true
                          ? t('mcq:toolTipText')
                          : ""
                      }
                      data-for="toolTip"
                    >
                      <Button
                        className="user-reponse-submit"
                        onClick={postSubmission}
                        disabled={disabled}
                      >
                        {t("actions:continue")}
                      </Button>
                    </span>
                    <ReactTooltip id="toolTip" />
                  </>
                ) : (
                  <>
                    <Button className="user-reponse-submit" onClick={nextPage}>
                      {t("actions:continue")}
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>

          {/* save as draft modal */}

          <Modal show={show} onHide={handleClose}>
            {successMessage ? (
              <Modal.Body>{successMessage}</Modal.Body>
            ) : (
              <Modal.Body>{errorMessage}</Modal.Body>
            )}
          </Modal>

          {/* error message modal */}

          <Modal show={showError}>
            <Modal.Body>{errorMessage}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={close}>
                {t("actions:close")}
              </Button>
            </Modal.Footer>
          </Modal>

          <div className="col-md-12 dashboard-footer">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Questionnaire;
