import React from "react";
import { Helmet } from "react-helmet";
import ChallengeExercise from "../templates/InputForms/ChallengeExercise";
import { GetChallengeQuestions } from "../../../../services/part2/get-challenge-forms";
import { useTranslation } from "react-i18next";

const Challenge3 = () => {
  const { t } = useTranslation(["partTwoChallengeThree"]);

  const [challengeQuestions, errorMessage] =
    GetChallengeQuestions("challange_show3"); // Input Questions list from API

  /*******   Page Params  *******/

  const title = t("title");

  const content = t("content");

  /*******   Render  *******/

  return (
    <div className="welcome-content">
      <Helmet>
        <title>{t("helmet")}</title>
      </Helmet>
      <ChallengeExercise
        title={title}
        content={content}
        questions={challengeQuestions}
        type={"create_chall3"}
        link="part_2/challenge_3_sample_answers"
        subtitle={t('subTitle')}
        error={errorMessage}
        previousLink="part_2/challenge_2_sample_answers"
      />
    </div>
  );
};

export default Challenge3;
