import React from 'react';

import { DOMAIN_NAME } from '../../env/Baseurl';

const UserAccountInfo = ({ handleChange, emailChecker, emptyCheck, values }) => {


    // ============Local Storage ==========================

    // const userInfo = localStorage.getItem('user'); 
    // const user = JSON.parse(userInfo);
    // const languageCode =user.userInfo.languageCode;
        
    /*******   Render  *******/

    return(
        <div>
            <div className="row m-0 align-items-center login-col-wrap">
                <div className="col-md-6">
                    <div className="text-center">
                        <img 
                            height="auto" 
                            width="450px" 
                            src={`${DOMAIN_NAME}images/login/login8464.png`} 
                            alt="Login Group"
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div>
                        <h3 className="text-center pagehead-texttitle">
                            Create New Account
                        </h3>
                        <form>
                            <div className="card-body">
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                            Email Address
                                    </label>
                                    <input 
                                        type="email" 
                                        className="form-control" 
                                        id="exampleInputEmail1"
                                        defaultValue={values.user_email} 
                                        placeholder="Enter a Valid Email Address" 
                                        onChange={handleChange("user_email")}
                                        onKeyUp={()=>emailChecker()} 
                                    />
                                        <small className="text-danger">
                                            {emptyCheck.user_email}
                                        </small>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">
                                        Username
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control" 
                                        id="exampleInputPassword1"
                                        defaultValue={values.user_name} 
                                        placeholder="Enter a Username (Special Characters are allowed)"
                                        onChange={handleChange("user_name")} 
                                    />
                                    {values.user_name === "" && ( 
                                        <small className="text-danger">
                                            {emptyCheck.user_name}
                                        </small>
                                    )}
                                </div>
                            </div>
                        </form>    
                    </div>
                </div>
            </div>
        </div>
    );

};

export default UserAccountInfo;