import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import '../../../../styles/adminlte.min.css';
import '../../../../styles/dashboard-style.css';
import Header from '../../../global/Header';
import Sidebar from '../../../global/Sidebar';
import Footer from '../../../global/Footer';
import audio1 from '../../../../Audio/attentionHandout1.mp3';
import audio2 from '../../../../Audio/rescript.mp3';
import { myprintFunction } from '../../../../actions/NormalPrintFunction';
// import FooterOfPrint from '../../../global/FootorOfPrint';

const Handout6 = () => {

    let navigate = useNavigate();


    return (
        <>
            <div id="printAble"></div>
            <div id="mainContainer" className="dashboardContent">
                <Helmet>
                    <title>Handout - Attention Training and Image Re-scripting</title>
                </Helmet>
                <div>
                    {/* <Header/> */}
                    <Header targeted_print_component_id={"main"} myprintFunction={myprintFunction} />
                </div>
                <div className="row">
                    <div className="col-md-2  position-static pl-0">
                        <div className="leftside">
                            <Sidebar />
                        </div>
                    </div>
                    <div id="main" className="col-md-10 content-widthsidebar content-wrapper">
                        <div className="card">
                            <div className="card-body">
                                <div className="title mb-3">
                                    Handout - Attention Training and Image Re-scripting
                                </div>
                                <p>
                                    You can download the attentional exercise here again. Continuing to use these exercises will train your brain to be better at focusing your attention on the things that are more helpful in life.
                                </p>
                                <p>
                                    <a href={audio1} target="_blank" rel="noreferrer">
                                        Attentional exercise.
                                    </a>
                                </p>
                                <p>
                                    You can also download the re-scripting exercise. You can continue to use this for new images that may pop into your head and need to be UPDATED.
                                </p>
                                <p>
                                    <a href={audio2} rel="noreferrer">
                                        Re-scripting exercise.
                                    </a>
                                </p>

                            </div>
                        </div>
                        {/* <div>
                            <FooterOfPrint />
                        </div> */}
                    </div>
                    <div className="col-md-12 dashboard-footer">
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Handout6;