import React from 'react';
import { Helmet } from 'react-helmet';
import Questionnaire from '../templates/Questionnaire/Mcq';
import TranslatedOptions from '../../../../constants/pretreatment/UtbasQuestionnaire';
import { GetPostTreatmentQuestionnaire } from '../../../../services/post-treatment/get-post-treatment-questionnaire';
import { useTranslation } from 'react-i18next';


const PostUtbasQuestionnaire = () => {

    const { t } = useTranslation(["posttreatmentPostUtbasQuestionnaire"])

    const options = TranslatedOptions();

    const moduleName = localStorage.setItem('moduleName', 'Post-Treatment');
    const postTreatmentAccess = localStorage.setItem('postTreatmentAccess', "true")


    const [questionnaire, errorMessage] = GetPostTreatmentQuestionnaire("UTBAS");            // Get Questionnaire from API

    /*******  Page Params  *******/

    const title = t('posttreatmentPostUtbasQuestionnaire:title');

    const content = 
    `<div>
        <p className='redText'>${t('posttreatmentPostUtbasQuestionnaire:content.line1')}</p>
        
        <p>${t('posttreatmentPostUtbasQuestionnaire:content.line2')}</p>
    </div>`;

    /*******  Render  *******/

    return (
        <div>
            <Helmet>
                <title>{t('posttreatmentPostUtbasQuestionnaire:helmet')}</title>
            </Helmet>
            <Questionnaire
                title={title}
                content={content}
                link='part_7/avoidance_questionnaire'
                questions={questionnaire}
                options={options}
                type={'utbas'}
                error={errorMessage}
                previousLink='part_7/handouts'
            />
        </div>
    );

};

export default PostUtbasQuestionnaire;