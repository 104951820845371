import React, { useEffect } from 'react';
import { Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner';
import { Helmet } from 'react-helmet';
import '../../../../styles/adminlte.min.css';
import '../../../../styles/dashboard-style.css';
import Header from '../../../global/Header';
import Sidebar from '../../../global/Sidebar';
import Footer from '../../../global/Footer';
import { GetAnxietyModel } from '../../../../services/part3/get-society-anxiety-model';
import ModuleCompletionEmails from '../../../../services/module-completion-emails';
import { DOMAIN_NAME } from '../../../../env/Baseurl';
import { myprintFunction } from '../../../../actions/NormalPrintFunction';
import FooterOfPrint from '../../../global/FootorOfPrint';
import { useTranslation } from "react-i18next";

const YourModelAnxiety = () => {

    const { t } = useTranslation(["partThreeYourModelAnxiety", "actions"]);

    const [anxietyModel, errorMessage] = GetAnxietyModel();                     // Get User Model from api

    // ============Local Storage ==========================

    const userInfo = localStorage.getItem('user'); 
    const user = JSON.parse(userInfo);
    const languageCode =user.userInfo.languageCode;


    /*******   Helper Functions  *******/

    let navigate = useNavigate();

    const nextPage = () => {

        navigate('/part_4/intro', { replace: true })

    };

    const previousPage = () => {

        navigate(`/part_3/your_model`, { replace: true })

    };
    /*******   Hooks  *******/

    useEffect(() => {

        ModuleCompletionEmails('sendmailPart3');
    }, []);


    /*******   Render  *******/

    return (
        <>
            <div id="printAble"></div>
            <div id="mainContainer" className="dashboardContent">
                <Helmet>
                    <title>{t('partThreeYourModelAnxiety:helmet')}</title>
                </Helmet>
                <div>
                    {/* <Header/> */}
                    <Header targeted_print_component_id={"main"} myprintFunction={myprintFunction} />
                </div>
                <div className="row">
                    <div className="col-md-2  position-static pl-0">
                        <div className="leftside">
                            <Sidebar />
                        </div>
                    </div>
                    <div className="col-md-10 content-widthsidebar content-wrapper">
                        <div className="card">
                            <div className="card-body">
                                <div id="main">
                                    <div className="card-body">
                                        <div className="title mb-3">
                                        {t('partThreeYourModelAnxiety:title')}
                                        </div>
                                        {
                                            errorMessage ? (<div className="container mt-5">
                                                <div className='row justify-content-center'>
                                                    <div className="alert alert-danger text-center" role="alert">
                                                        {errorMessage}
                                                    </div>
                                                </div>
                                            </div>)
                                                :
                                                anxietyModel !== undefined && anxietyModel.length !== 0 ? (
                                                    <>
                                                        <div className='outer-main-div'>
                                                            <div className='content-wth-bg'>
                                                                <div style={{ backgroundImage: `url(${DOMAIN_NAME}images/${languageCode}/part3/mind-map.png)`, backgroundRepeat: 'no-repeat', width: '1250px', backgroundPositionX: 'center' }} className='mainContent'>
                                                                    <div className='afterDiv'>
                                                                        <div className='first-content-ul pt-3'>
                                                                            <ul className='pt-3'>
                                                                                <li className='pt-3'>
                                                                                    <p className='pt-3'>
                                                                                        {anxietyModel.length !== 0 && <>{anxietyModel.your_situations.data}</>}
                                                                                    </p>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                        <br />
                                                                        <br />
                                                                        <div className='second-content-ul pt-3'>
                                                                            <ul className='pt-3'>
                                                                                <li className='pt-3'>
                                                                                    <p className='pt-3'>{anxietyModel.length !== 0 && <>{anxietyModel.your_casuals.data}</>}</p>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                        <br />
                                                                        <br />
                                                                        <br />
                                                                        <br />
                                                                        <br />
                                                                        <br />
                                                                        <div id="lowerRow" className='row m-3 p-3'>
                                                                            <div className='col-md-4 m-3 p-1' id="firstCol">
                                                                                {/* first column */}
                                                                                <ul>
                                                                                    {anxietyModel !== undefined && anxietyModel.length !== 0 ? (
                                                                                        <div className='first-column-content' style={{ width: '100px' }}>
                                                                                            {anxietyModel.your_behiviour.map((item, index) => {
                                                                                                const card = (
                                                                                                    <li key={index}>
                                                                                                        {item}
                                                                                                    </li>
                                                                                                )
                                                                                                return card;
                                                                                            })}
                                                                                        </div>
                                                                                    )
                                                                                        :
                                                                                        <div className="loader-icon">
                                                                                            <TailSpin color="#4f5962" height={50} width={50} />
                                                                                        </div>
                                                                                    }
                                                                                </ul>
                                                                            </div>
                                                                            <div className='col-md-4 m-3 p-1' id="secondCol">
                                                                                {/* second column */}
                                                                                <ul>
                                                                                    {anxietyModel !== undefined && anxietyModel.length !== 0 ? (
                                                                                        <div>
                                                                                            {anxietyModel.your_image.map((item, index) => {
                                                                                                const card = (
                                                                                                    <li key={index}>
                                                                                                        {item}
                                                                                                    </li>
                                                                                                )
                                                                                                return card;
                                                                                            })}
                                                                                        </div>
                                                                                    )
                                                                                        :
                                                                                        <div className="loader-icon">
                                                                                            <TailSpin color="#4f5962" height={50} width={50} />
                                                                                        </div>
                                                                                    }
                                                                                </ul>
                                                                            </div>
                                                                            <div className='col-md-4 m-3 p-1' id='thirdCol'>
                                                                                {/* third colimn */}
                                                                                <ul>
                                                                                    {anxietyModel !== undefined && anxietyModel.length !== 0 ? (
                                                                                        <div>
                                                                                            {anxietyModel.your_physiological.map((item, index) => {
                                                                                                const card = (
                                                                                                    <li key={index}>
                                                                                                        {item}
                                                                                                    </li>
                                                                                                )
                                                                                                return card;
                                                                                            })}
                                                                                        </div>
                                                                                    )
                                                                                        :
                                                                                        <div className="loader-icon">
                                                                                            <TailSpin color="#4f5962" height={50} width={50} />
                                                                                        </div>
                                                                                    }
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                                    :
                                                    <div className="loader-icon">
                                                        <TailSpin color="#4f5962" height={50} width={50} />
                                                    </div>
                                        }
                                    </div>
                                    {/* <div>
                                        <FooterOfPrint />
                                    </div> */}
                                </div>

                                <div className='row'>
                                    <div className='previous1 col-6'>

                                        <Button
                                            className="user-reponse-submit btn btn-primary link-sty"
                                            onClick={previousPage}

                                        >
                                            {t("actions:previous")}
                                        </Button>
                                    </div>
                                    <div className='next col-6'>
                                        <Button className="user-reponse-submit btn btn-primary link-sty" onClick={nextPage}>{t("actions:continue")}</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 dashboard-footer">
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
};

export default YourModelAnxiety;