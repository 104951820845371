import { useEffect, useState} from 'react';
import axios from "axios";

import ErrorLogging from '../error-logs';
import { BASE_URL } from '../../env/Baseurl';
import RefreshToken from '../refresh-token';


const GetAvoidanceUtbasQuestionnaire =  (type) => {
    
    const [questionnaire, setquestionnaire] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const fetchQuestionnaire = async () => {

        const userInfo = localStorage.getItem('user');
        const user = JSON.parse(userInfo);

        try{
            const params = JSON.stringify({
                "question_category": type,
            });    

            const response = await axios.post(BASE_URL + `get_utbasAvoidance_questionpart3/${user.userInfo._id}`, params, {
                "headers": {
                    "Authorization": `Bearer ${user.token}`,
                    "content-type": "application/json",
                    "languageCode": user.userInfo.languageCode,
                    },    
            })
            setquestionnaire(response.data);
        } catch(error){
            if (error.response.status === 401) {
                RefreshToken(user.userInfo._id)
                fetchQuestionnaire();
            }
            else if(error.response.status === 403 || error.response.status === 500 || error.response.status === 503)
            {
            setErrorMessage('Internal Server Error, Please Try After Sometime!');
            ErrorLogging('HTTP GET /getQuestionnairsBycatecory' + error);
            }
            else{
            setErrorMessage('There is some error, please try again later.');
            ErrorLogging('HTTP GET /getQuestionnairsBycatecory' + error);
            }
        }     
    };

    useEffect(() => {
        fetchQuestionnaire();
    }, []);
  
  return [questionnaire, errorMessage];
};

export {
    GetAvoidanceUtbasQuestionnaire,
}