import React from 'react';
import { Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import '../../../../styles/adminlte.min.css';
import '../../../../styles/dashboard-style.css';
import Header from '../../../global/Header';
import Sidebar from '../../../global/Sidebar';
import Footer from '../../../global/Footer';
import { DOMAIN_NAME } from '../../../../env/Baseurl';
import { myprintFunction } from '../../../../actions/NormalPrintFunction';
import FooterOfPrint from '../../../global/FootorOfPrint';
import { useTranslation } from 'react-i18next';
// import welcomeImage from '../../../../public/images/part6/Part6-Welcome.png'

const WelcomeToPart6 = () => {

    const { t } = useTranslation(["partSixWelcomeToPartSix", "actions"])

    const moduleName = localStorage.setItem('moduleName', 'Part 6');
    const part6Accessed = localStorage.setItem('part6Accessed', "true")

    // ============Local Storage ==========================

    const userInfo = localStorage.getItem('user'); 
    const user = JSON.parse(userInfo);
    const languageCode =user.userInfo.languageCode;



    /*******   Helper Functions  *******/

    let navigate = useNavigate();

    const nextPage = () => {

        navigate('/part_6/gaining_control', { replace: true })

    };

    const previousPage = () => {

        navigate(`/part_5/should_sample_answers`, { replace: true })

    };

    /*******   Render  *******/

    return (
        <>
            <div id="printAble"></div>
            <div id="mainContainer" className="dashboardContent">
                <Helmet>
                    <title>{t('partSixWelcomeToPartSix:helmet')}</title>
                </Helmet>
                <div>
                    {/* <Header/> */}
                    <Header targeted_print_component_id={"main"} myprintFunction={myprintFunction} />
                </div>
                <div className="row">
                    <div className="col-md-2  position-static pl-0">
                        <div className="leftside">
                            <Sidebar />
                        </div>
                    </div>
                    <div className="col-md-10 content-widthsidebar content-wrapper">
                        <div className="card">
                            <div className="card-body">
                                <div id="main">
                                    <div className="card-body">
                                        <div className="title mb-3">
                                        {t('partSixWelcomeToPartSix:title')}
                                        </div>
                                        <div className='img-how-to-sty centre-align'>
                                            {/* <img src={`${DOMAIN_NAME}images/part6/part6-welcome.png`} alt="welcome-part-6" /> */}
                                            <img src={`${DOMAIN_NAME}images/${languageCode}/part6/Part6-Welcome.png`} alt="welcome-part-6" />
                                            {/* <img src={welcomeImage} alt="welcome-part-6" /> */}
                                        </div>
                                        <p className='redText'>
                                        {t('partSixWelcomeToPartSix:content.line1')}
                                        </p>
                                        <p>
                                        {t('partSixWelcomeToPartSix:content.line2')}
                                        </p>
                                        <p>
                                        {t('partSixWelcomeToPartSix:content.line3')}
                                        </p>
                                        <p>
                                        {t('partSixWelcomeToPartSix:content.line4')}
                                        </p>
                                        <p>
                                        {t('partSixWelcomeToPartSix:content.line5')}
                                        </p>
                                        <p>
                                        {t('partSixWelcomeToPartSix:content.line6')}
                                        </p>
                                        <p>
                                        {t('partSixWelcomeToPartSix:content.line7')}
                                        </p>
                                        <p>
                                            <span className='redText'>{t('partSixWelcomeToPartSix:content.line8')}</span> {t('partSixWelcomeToPartSix:content.line9')}
                                        </p>
                                        <p>
                                            <span className='redText'>{t('partSixWelcomeToPartSix:content.line10')}</span> {t('partSixWelcomeToPartSix:content.line11')}
                                        </p>
                                    </div>
                                    {/* <div>
                                        <FooterOfPrint/>
                                    </div> */}
                                </div>

                                <div className='row'>
                                    <div className='previous1 col-6'>
                                        <Button
                                            className="user-reponse-submit btn btn-primary link-sty"
                                            onClick={previousPage}
                                        >
                                            {t('actions:previous')}
                                        </Button>
                                    </div>
                                    <div className='next col-6'>

                                        <Button
                                            className="user-reponse-submit btn btn-primary link-sty"
                                            onClick={nextPage}
                                        >
                                           {t('actions:continue')}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 dashboard-footer">
                        <Footer />
                    </div>
                </div>
            </div>
        </>

    );
};

export default WelcomeToPart6;
