import { useEffect, useState} from 'react';
import axios from "axios";

import { BASE_URL } from '../../env/Baseurl';
import ErrorLogging from '../error-logs';
import RefreshToken from '../refresh-token';


const GetEssayResponse =  () => {
    
    const [essayResponse, setEssayResponse] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const fetchEssayResponse = async () => {

        const userInfo = localStorage.getItem('user');
        const user = JSON.parse(userInfo);

        try{
               const response = await axios.get(BASE_URL + `user_eassay_responce/${user.userInfo._id}`, {
                "headers": {
                "Authorization": `Bearer ${user.token}`,
                "content-type": "application/json",
                "languageCode": user.userInfo.languageCode,
                },   
            })
            setEssayResponse(response.data);
        } catch(error){
            if (error.response.status === 401) {
                RefreshToken(user.userInfo._id)
                fetchEssayResponse();
            }
            else if(error.response.status === 403 || error.response.status === 500 || error.response.status === 503)
            {
            setErrorMessage('Internal Server Error, Please Try After Sometime!');
            ErrorLogging('HTTP GET /behavioural_causal_thought_page/userId' + error);
            }
            else{
            setErrorMessage('There is some error, please try again later.');
            ErrorLogging('HTTP GET /user_eassay_responce/userId' + error);
            }
        }     
    };

    useEffect(() => {
        fetchEssayResponse();
    }, []);
  
  return [essayResponse, errorMessage];
};

export {
    GetEssayResponse
}