import React from 'react';
import { Helmet } from 'react-helmet';
import ContentPage from '../templates/ContentPages';
import { useTranslation } from 'react-i18next';

const Part7ThirdPage = () => {

    const { t } = useTranslation(["partSevenThirdPage"])

    /*******  Page Params  *******/

    const title = t('partSevenThirdPage:title');

    const content = 
    `<div>
        <p>${t('partSevenThirdPage:content.line1')}</p>
        
        <p>${t('partSevenThirdPage:content.line2')}</p>
        
        <p>${t('partSevenThirdPage:content.line3')}</p>
    </div>`;
    
    /*******  Render  *******/

    return(
        <div  className='welcome-content'>
            <Helmet>
                <title>${t('partsevenThirdPage:helmet')}</title>
            </Helmet>
            <ContentPage 
                title={title}
                content={content}
                link='part_7/ways_to_protect_yourself'
                previousLink= 'part_7/preventing_relapse_2'
            />
        </div>        
    );
};

export default Part7ThirdPage;