import React from 'react';
import { Helmet } from 'react-helmet';
import Questionnaire from '../templates/Questionnaire/Mcq';
import TranslatedOptions from '../../../../constants/pretreatment/AvoidanceQuestionnaire';
import { GetPostTreatmentQuestionnaire } from '../../../../services/post-treatment/get-post-treatment-questionnaire';
import { useTranslation } from 'react-i18next';

const PostAvoidanceQuestionnaire = () => {

    const { t } = useTranslation(["posttreatmentPostAvoidanceQuestionnaire"])

    const options = TranslatedOptions();

    const [questionnaire, errorMessage] = GetPostTreatmentQuestionnaire("Avoidance");        // Get Questionnaire from API

    /*******  Page Params  *******/

    const title = t('posttreatmentPostAvoidanceQuestionnaire:title');

    const content = 
    `<div>
        <p className='redText'>${t('posttreatmentPostAvoidanceQuestionnaire:content.line1')}</p>
    </div>`;
    
    /*******  Render  *******/

    return(
        <div>
            <Helmet>
                <title>{t('posttreatmentPostAvoidanceQuestionnaire:helmet')}</title>
            </Helmet>
            <Questionnaire 
                title={title}
                content={content}
                link='part_7/dass_questionnaire'
                questions={questionnaire}
                options={options}
                type={'avoidance'}
                error={errorMessage}
                previousLink='part_7/utbas_questionnaire'
            />
        </div>
    );

};

export default PostAvoidanceQuestionnaire;