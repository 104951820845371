import React from 'react';
import "../styles/adminlte.min.css"
import "../styles/dashboard-style.css"
import "../styles/login.css"
import { jsPDF } from "jspdf";
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';




const printFunction = (id) => {
   
    if (id != undefined || id != null) {

        // let pri;
        // var content = document.getElementById(id.targeted_print_component_id);
        // console.log(id.targeted_print_component_id, "its id");
        // const iframe = document.createElement('iframe')
        // // iframe.setAttribute('title', "hello")
        // // iframe.setAttribute('id', "2")
        // iframe.setAttribute('style', 'height:0px; widht:0px; position:absolute: id: printIframe')
        // document.body.appendChild(iframe)
        // pri = iframe.contentWindow
        // // console.log(pri)

        // pri.document.open()
        // pri.document.write(content.innerHTML)
        // // pri.document.write(content.innerHTML)
        // pri.document.close()
        // pri.focus()
        // pri.print()



        let data = document.getElementById(id)
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = data.innerHTML
        window.print();
        document.body.innerHTML = originalContents;
        window.close();
        // window.location.reload(true);



    }
}

export { printFunction };