import React, { useState, useEffect } from 'react';
import { Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner';
import axios from 'axios';
import ReactTooltip from 'react-tooltip';
import Modal from 'react-bootstrap/Modal';
import { Helmet } from 'react-helmet';
import { BASE_URL } from '../../../../env/Baseurl';
import ErrorLogging from '../../../../services/error-logs';
import RefreshToken from '../../../../services/refresh-token';
import '../../../../styles/adminlte.min.css';
import '../../../../styles/dashboard-style.css';
import Header from '../../../global/Header';
import Sidebar from '../../../global/Sidebar';
import Footer from '../../../global/Footer';
import { GetBehaviouralCausalThought } from '../../../../services/part4/behavioural-causal-thought';
import FooterOfPrint from '../../../global/FootorOfPrint';
import { useTranslation } from 'react-i18next';


const Part4TenthPage = () => {

    const { t } = useTranslation(["partFourTenthPage", "actions"])

    const [behaviouralCausalThought, errorMessage] = GetBehaviouralCausalThought();         // Get Behavioural Data form Api

    /*******   States *******/

    const [disabled, setDisabled] = useState(true);
    const [state, setState] = useState({ data: [] });
    const [successMessage, setSuccessMessage] = useState('');
    const [errorApiMessage, setErrorMessage] = useState('');
    const [textBoxValidation, setTextboxValidation] = useState({
        id: "",
        errorMessage: "",
        disabledMessage: ""
    });
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    let handleClose = () => setShow(false);

    // error modal
    const [showError, setShowError] = useState(false);
    const handle = () => setShowError(true);
    let close = () => setShowError(false);

    /*******   Helper Functions  *******/

    let navigate = useNavigate();

    const specialCharactersRegex = /^[^a-zA-Z0-9]+$/;

    const handleChange = (inputId) => (e) => {
        const { value } = e.target;

        if (state.data.some(item => item.question_id === inputId)) {

            let updatedData = state.data.map(item => {
                if (item.question_id === inputId) {
                    return { ...item, "question_id": inputId, "user_responce": value };
                }
                return item;
            });

            setState({ data: updatedData });
        } else {
            setState(state => ({
                data: [...state.data, { "question_id": inputId, "user_responce": value }]
            }));
        }

        if (value !== "" || value === "") {
            specialCharactersRegex.test(
                value) ?
                setTextboxValidation({
                    id: inputId,
                    errorMessage: t('partFourTenthPage:textBoxValidation.validation1'),
                    disabledMessage: t('partFourTenthPage:textBoxValidation.validation2')
                })
                :
                setTextboxValidation({
                    id: inputId,
                    errorMessage: "",
                    disabledMessage: t('partFourTenthPage:textBoxValidation.validation3')
                })
        };

    };

    const postSubmission = async () => {

        const userInfo = localStorage.getItem('user');                                  // Logged in user info
        const user = JSON.parse(userInfo);

        try {
            const params = JSON.stringify({
                "data": state.data,
                "saveasdraft": "false"
            });
            const response = await axios.post(BASE_URL + `behavioural_causal_thought_userresponce/${user.userInfo._id}`, params, {
                "headers": {
                    "Authorization": `Bearer ${user.token}`,
                    "content-type": "application/json",
                    "languageCode": user.userInfo.languageCode,
                },
            })
            setSuccessMessage(response.data);
            if (state.data.length >= behaviouralCausalThought.count) {
                navigate(`/part_4/behavioural_causal_thought_sample_answers`, { replace: true })
            } else {
                alert('Please fill all the fields.');
            }
        } catch (error) {
            if (error.response.status === 401) {
                RefreshToken(user.userInfo._id)
                postSubmission();
            }
            else {
                setErrorMessage('Please check Part 4 Behavioural Casual Thought Userresponse Api');
                ErrorLogging('HTTP POST Part 4 /behavioural_causal_thought_userresponce/userId' + error);
                handle();
            }
        }

    };


    const saveDraft = async () => {

        const userInfo = localStorage.getItem('user');                                  // Logged in user info
        const user = JSON.parse(userInfo);

        try {
            const params = JSON.stringify({
                "data": state.data,
                "saveasdraft": "true"
            });
            const response = await axios.post(BASE_URL + `behavioural_causal_thought_userresponce/${user.userInfo._id}`, params, {
                "headers": {
                    "Authorization": `Bearer ${user.token}`,
                    "content-type": "application/json",
                    "languageCode": user.userInfo.languageCode,
                },
            })
            if (response.data.status === true) {
                setSuccessMessage('Saved As Draft');
                handleShow();
            }
        } catch (error) {
            if (error.response.status === 401) {
                RefreshToken(user.userInfo._id)
                saveDraft();
            }
            else {
                setErrorMessage('Please check Part 4 Behavioural Casual Thought Userresponse Api');
                ErrorLogging('HTTP POST Part 4 /behavioural_causal_thought_userresponce/userId' + error);
                handle();
            }
        }

    };

    const nextPage = () => {
        navigate('/part_4/behavioural_causal_thought_sample_answers', { replace: true })
    };

    const previousPage = () => {

        navigate(`/part_4/perceived_negative_evaluations`, { replace: true })

    };

    // close modal on its own

    setTimeout(handleClose, 1000);

    /*******   Hooks  *******/

    useEffect(() => {

        const arr = Array.from(state.data.filter((e) => e.user_responce === ''));
        const arr1 = Array.from(state.data.filter((e) => specialCharactersRegex.test(e.user_responce)));

        if (behaviouralCausalThought !== undefined && behaviouralCausalThought.length !== 0) {
            const { data = [] } = behaviouralCausalThought.questions;

            if (state.data.length >= behaviouralCausalThought.count && arr.length === 0 && arr1.length === 0) {
                setDisabled(false);
            } else {
                setDisabled(true);
            }
        }

    }, [state.data]);

    useEffect(() => {
        if (behaviouralCausalThought.count === 0) {
            setDisabled(false);
        }
    }, [behaviouralCausalThought]);


    //print function
    const myprintFunction = () => {
        var realData = document.getElementById("main").innerHTML

        var secondData = document.getElementById("printAble").innerHTML
        document.getElementById('printAble').innerHTML = realData
        document.getElementById("mainContainer").style.display = "none";
        // document.getElementById("printFooter").style.display = "block";
        window.print();
        document.getElementById("mainContainer").style.display = "block";
        document.getElementById("printAble").innerHTML = secondData;
        // if (dataSubmitted === true) {
        //     document.getElementById('main').innerHTML = realData;
        // }
        if (behaviouralCausalThought !== undefined && behaviouralCausalThought.length !== 0 && behaviouralCausalThought.global_disable_status === false) {

        }
        else {
            document.getElementById('main').innerHTML = realData;
        }
    }


    /*******   Render  *******/

    return (
        <>
            <div id="printAble"></div>
            <div id="mainContainer" >
                <Helmet>
                    <title>{t('partFourTenthPage:helmet')}</title>
                </Helmet>
                <div>
                    {/* <Header /> */}
                    <Header targeted_print_component_id={"main"} myprintFunction={myprintFunction} />
                </div>
                <div className="row">
                    <div className="col-md-2  position-static pl-0">
                        <div className="leftside">
                            <Sidebar />
                        </div>
                    </div>
                    <div className="col-md-10 content-wrapper content-div btn-show">
                        <div className="content">
                            <div id="main">
                                <div className="row header-titlewith-text m-0">
                                    <div className="title col-md-6 p-0">
                                    {t('partFourTenthPage:title')}
                                    </div>
                                    <div className="col-md-6 text-right p-0">
                                        <span> {t('partFourTenthPage:content.line1')}</span> {t('partFourTenthPage:content.line2')}
                                    </div>
                                </div>
                                {
                                    errorMessage ? (<div className="container mt-5">
                                        <div className='row justify-content-center'>
                                            <div className="alert alert-danger text-center" role="alert">
                                                {errorMessage}
                                            </div>
                                        </div>
                                    </div>)
                                        :
                                        behaviouralCausalThought !== undefined && behaviouralCausalThought.length !== 0 ? (
                                            <div className="content-wrap">
                                                <div className="content-topheads-text">
                                                    <div className="user-res-situation">
                                                        {behaviouralCausalThought.utbus_data.statement}
                                                    </div>
                                                    {behaviouralCausalThought.questions.map((item, index) => {
                                                        const card = (
                                                            <div className="left-content" key={index}>
                                                                <div className='row'>
                                                                    <div className='col-lg-12'>
                                                                        <p className="questions redText">
                                                                            {(index + 1) + "." + " " + item.question}
                                                                        </p>
                                                                        <div className="textbox">
                                                                            <textarea
                                                                                readOnly={item.disable_status}
                                                                                className="form-control"
                                                                                id="ControlTextarea"
                                                                                rows="10"
                                                                                placeholder= {t('partFourTenthPage:placeholder')}
                                                                                defaultValue={item.responce}
                                                                                onChange={handleChange(item._id)}
                                                                            />
                                                                        </div>
                                                                        {textBoxValidation.id === item._id && (
                                                                            <small className="text-danger">
                                                                                {textBoxValidation.errorMessage}
                                                                            </small>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        )
                                                        return card;
                                                    })}
                                                </div>
                                            </div>
                                        )
                                            :
                                            <div className="loader-icon">
                                                <TailSpin color="#4f5962" height={50} width={50} />
                                            </div>
                                }
                                {/* <div>
                                    <FooterOfPrint/>
                                </div> */}
                            </div>
                            <div className='row'>
                                <div className='previous1 col-6'>

                                    <Button
                                        className="user-reponse-submit"
                                        onClick={previousPage}

                                    >
                                       {t('actions:previous')}
                                    </Button>
                                </div>
                                <div className='next col-6'>
                                    {behaviouralCausalThought !== undefined && behaviouralCausalThought.length !== 0 && behaviouralCausalThought.global_disable_status === false ? (
                                        <>
                                            <Button
                                                className="user-reponse-submit save-draft-btn"
                                                onClick={saveDraft}
                                            >
                                                {t('actions:save')}
                                            </Button>
                                        </>
                                    ) :
                                        <>
                                        </>
                                    }
                                    {behaviouralCausalThought !== undefined && behaviouralCausalThought.length !== 0 && behaviouralCausalThought.global_disable_status === false ? (
                                        <>
                                            <span data-tip={disabled === true ? textBoxValidation.disabledMessage : ""} data-for='toolTip'>
                                                <Button
                                                    className="user-reponse-submit"
                                                    onClick={postSubmission}
                                                    disabled={disabled}
                                                >
                                                    {t('actions:continue')}
                                                </Button>
                                            </span>
                                            <ReactTooltip id="toolTip" />
                                        </>
                                    ) :
                                        <>
                                            <Button
                                                className="user-reponse-submit"
                                                onClick={nextPage}
                                            >
                                                {t('actions:continue')}
                                            </Button>
                                        </>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal show={show} onHide={handleClose}>
                        {successMessage ?
                            <Modal.Body>{successMessage}</Modal.Body>
                            :
                            <Modal.Body>{errorMessage}</Modal.Body>
                        }
                    </Modal>
                    {/* error message modal */}

                    <Modal show={showError} >
                        <Modal.Body>{errorApiMessage}</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={close}>
                            {t('actions:close   ')}
                            </Button>

                        </Modal.Footer>
                    </Modal>
                    <div className="col-md-12 dashboard-footer">
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Part4TenthPage;