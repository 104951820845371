import React from 'react';
import { useTranslation } from 'react-i18next';

// const options = [
//      "Never have the thought",
//      "Rarely have the thought",
//      "Sometimes have the thought",
//      "Often have the thought",
//      "Always have the thought"
//     ];      

// export {
//     options
// };




const TranslatedOptions = () => {
  const { t } = useTranslation(["constantsPretreatmentUtbsQuestionnaire"]);

  return [
    t("options.never"),
    t("options.rarely"),
    t("options.sometimes"),
    t("options.often"),
    t("options.always")
  ];
};

export default TranslatedOptions;
