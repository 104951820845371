import { useEffect, useState} from 'react';
import axios from "axios";

import ErrorLogging from '../error-logs';
import { BASE_URL } from '../../env/Baseurl';
import RefreshToken from '../refresh-token';

const GetAllUtbas =  () => {
    
    const [questionnaire, setquestionnaire] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const fetchAllUtbas = async () => {

    const userInfo = localStorage.getItem('user');
    const user = JSON.parse(userInfo);
    
        try{
   
            const response = await axios.get(BASE_URL + `get_allUTBUS/${user.userInfo._id}`, {
                "headers": {
                    "Authorization": `Bearer ${user.token}`,
                    "content-type": "application/json",
                    "languageCode": user.userInfo.languageCode,
                    },   
            })
            setquestionnaire(response.data);
        } catch(error){
            if (error.response.status === 401) {
                RefreshToken(user.userInfo._id)
                fetchAllUtbas();
            }
            else if(error.response.status === 403 || error.response.status === 500 || error.response.status === 503)
            {
            setErrorMessage('Internal Server Error, Please Try After Sometime!');
            ErrorLogging('HTTP GET /get_allUTBUS' + error);
            }
            else{
                setErrorMessage('There is some error, please try again later.');
                ErrorLogging('HTTP GET /get_allUTBUS' + error);
            }
        }     
    };

    useEffect(() => {
        fetchAllUtbas();
    }, []);
  
  return [questionnaire, errorMessage];
};

export {
    GetAllUtbas,
}