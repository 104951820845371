import React, { useState, useEffect } from "react";
import "../../../../../styles/adminlte.min.css";
import { Button } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Modal from "react-bootstrap/Modal";

import { BASE_URL } from "../../../../../env/Baseurl";
import ErrorLogging from "../../../../../services/error-logs";
import RefreshToken from "../../../../../services/refresh-token";
import Header from "../../../../global/Header";
import Sidebar from "../../../../global/Sidebar";
import Footer from "../../../../global/Footer";
import FooterOfPrint from "../../../../global/FootorOfPrint";
import { myprintFunction } from "../../../../../actions/NormalPrintFunction";
import { useTranslation } from "react-i18next";

const ChallengeExercise = ({
  title,
  content,
  questions,
  link,
  type,
  subtitle,
  error,
  previousLink,
}) => {
  const { t } = useTranslation(["partTwoChallengeExercise", "actions"]);

  /*******   States *******/

  const [disabled, setDisabled] = useState(true);
  const [state, setState] = useState({ data: [] });
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [textBoxValidation, setTextboxValidation] = useState({
    id: "",
    errorMessage: "",
    disabledMessage: "",
  });
  // save as draft modal

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  let handleClose = () => setShow(false);

  // error modal
  const [showError, setShowError] = useState(false);
  const handle = () => setShowError(true);
  let close = () => setShowError(false);

  /*******   Helper Functions  *******/

  let navigate = useNavigate();

  const specialCharactersRegex = /^[^a-zA-Z0-9]+$/; // Regex to check for only special characters in textbox

  const handleChange = (inputId) => (e) => {
    const { value } = e.target;

    if (state.data.some((item) => item.challange_id === inputId)) {
      // Checking Input ID in state on change

      let updatedData = state.data.map((item) => {
        if (item.challange_id === inputId) {
          return {
            ...item,
            challange_id: inputId,
            userRecord: value,
            utbas_id: questions.statement_obj.id,
          }; // Updating user response in state
        }
        return item;
      });

      setState({ data: updatedData });
    } else {
      setState((state) => ({
        data: [
          ...state.data,
          {
            challange_id: inputId,
            userRecord: value,
            utbas_id: questions.statement_obj.id,
          },
        ], // Adding new user response in state
      }));
    }

    if (value !== "" || value === "") {
      // Setting Tooltip message for empty response or regex not matched
      specialCharactersRegex.test(value)
        ? setTextboxValidation({
            id: inputId,
            errorMessage: t("partTwoChallengeExercise:errorMessage1"),
            disabledMessage: t("partTwoChallengeExercise:disabledMessage1"),
          })
        : setTextboxValidation({
            id: inputId,
            errorMessage: "",
            disabledMessage: t("partTwoChallengeExercise:disabledMessage2"),
          });
    }
  };

  const postSubmission = async () => {
    const userInfo = localStorage.getItem("user"); // Logged in user params
    const user = JSON.parse(userInfo);

    try {
      const params = JSON.stringify({
        data: state.data,
        saveasdraft: "false",
      });
      const response = await axios.post(
        BASE_URL + `${type}/${user.userInfo._id}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "content-type": "application/json",
            "languageCode": user.userInfo.languageCode,
          },
        }
      );
      setSuccessMessage(response.data);

      if (state.data.length >= questions.count) {
        navigate(`/${link}`, { replace: true });
      } else {
      }
    } catch (error) {
      if (error.response.status === 401) {
        RefreshToken(user.userInfo._id);
        postSubmission();
      } else {
        setErrorMessage(t("partTwoChallengeExercise:errorMessage2"));
        ErrorLogging("HTTP POST Part 2 /_type/userId" + error);
        handle();
      }
    }
  };

  // SAVE AS DRAFT

  const saveDraft = async () => {
    const userInfo = localStorage.getItem("user"); // Logged in user params
    const user = JSON.parse(userInfo);

    try {
      const params = JSON.stringify({
        data: state.data,
        saveasdraft: "true",
      });
      const response = await axios.post(
        BASE_URL + `${type}/${user.userInfo._id}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "content-type": "application/json",
            "languageCode": user.userInfo.languageCode,
          },
        }
      );
      if (response.data.status === true) {
        setSuccessMessage("Saved As Draft");
        handleShow();
      }
    } catch (error) {
      if (error.response.status === 401) {
        RefreshToken(user.userInfo._id);
        saveDraft();
      } else {
        setErrorMessage(t("partTwoChallengeExercise:errorMessage2"));
        ErrorLogging("HTTP POST Part 2 /_type/userId" + error);
        handle();
      }
    }
  };

  const nextPage = () => {
    navigate(`/${link}`, { replace: true });
  };

  // close modal on its own

  setTimeout(handleClose, 1000);

  /*******   Hooks  *******/

  useEffect(() => {
    const arr = Array.from(state.data.filter((e) => e.userRecord === ""));
    const arr1 = Array.from(
      state.data.filter((e) => specialCharactersRegex.test(e.userRecord))
    );

    if (questions !== undefined && questions.length !== 0) {
      if (
        state.data.length >= questions.count &&
        arr.length === 0 &&
        arr1.length === 0
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
  }, [state.data]);

  useEffect(() => {
    if (questions.count === 0) {
      setDisabled(false);
    }
  }, [questions]);

  const previousPage = () => {
    navigate(`/${previousLink}`, { replace: true });
  };

  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer">
        <div>
          {/* <Header/> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row content-with-sidebar-row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-wrapper content-div">
            <div className="content">
              <div id="main">
                <div className="row header-titlewith-text m-0">
                  <div className="title col-md-6 p-0">{title}</div>
                  <div className="col-md-6 text-right p-0">
                    <span> {t("partTwoChallengeExercise:title")}</span> /{" "}
                    {title}
                  </div>
                </div>
                {error ? (
                  <div className="container mt-5">
                    <div className="row justify-content-center">
                      <div
                        className="alert alert-danger text-center"
                        role="alert"
                      >
                        {error}
                      </div>
                    </div>
                  </div>
                ) : questions !== undefined && questions.length !== 0 ? (
                  <div className="content-wrap">
                    <div className="content-topheads-text">
                      <div className="usr-jrny-text">{subtitle}</div>
                    </div>
                    <div className="content-topheads-text">
                      <div className="user-res-situation">
                        {questions.statement_obj.statement}
                      </div>
                      {questions.questions.map((item, index) => {
                        const card = (
                          <div className="left-content" key={index}>
                            <div className="row">
                              <div className="col-lg-12">
                                <p className="questions redText">
                                  {index + 1 + "." + " " + item.question}
                                </p>
                                <div className="textbox">
                                  <textarea
                                    readOnly={item.disable_status}
                                    className="form-control"
                                    id="ControlTextarea"
                                    rows="10"
                                    defaultValue={item.responce}
                                    placeholder={t('partTwoChallengeExercise:placeholders')}
                                    onChange={handleChange(item._id)}
                                  />
                                </div>
                                {textBoxValidation.id === item._id && (
                                  <small className="text-danger">
                                    {textBoxValidation.errorMessage}
                                  </small>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                        return card;
                      })}
                    </div>
                  </div>
                ) : (
                  <div className="loader-icon">
                    <TailSpin color="#4f5962" height={50} width={50} />
                  </div>
                )}
                {/* <div>
                                    <FooterOfPrint />
                                </div> */}
              </div>
              <div className="row">
                <div className="previous1 col-6">
                  <Button
                    className="user-reponse-submit"
                    onClick={previousPage}
                  >
                    {t("actions:previous")}
                  </Button>
                </div>
                <div className="next col-6">
                  {questions !== undefined &&
                  questions.length !== 0 &&
                  questions.global_disable_status === false ? (
                    <>
                      <Button
                        className="user-reponse-submit save-draft-btn"
                        onClick={saveDraft}
                      >
                        {t("actions:save")}
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                  {questions !== undefined &&
                  questions.length !== 0 &&
                  questions.global_disable_status === false ? (
                    <>
                      <span
                        data-tip={
                          disabled === true
                            ? textBoxValidation.disabledMessage
                            : ""
                        }
                        data-for="toolTip"
                      >
                        <Button
                          className="user-reponse-submit"
                          onClick={postSubmission}
                          disabled={disabled}
                        >
                          {t("actions:continue")}
                        </Button>
                      </span>
                      <ReactTooltip id="toolTip" />
                    </>
                  ) : (
                    <>
                      <Button
                        className="user-reponse-submit"
                        onClick={nextPage}
                      >
                        {t("actions:continue")}
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* save as draft modal */}

          <Modal show={show} onHide={handleClose}>
            {successMessage ? (
              <Modal.Body>{successMessage}</Modal.Body>
            ) : (
              <Modal.Body>{errorMessage}</Modal.Body>
            )}
          </Modal>

          {/* error message modal */}

          <Modal show={showError}>
            <Modal.Body>{errorMessage}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={close}>
                {t("actions:close")}
              </Button>
            </Modal.Footer>
          </Modal>

          <div className="col-md-12 dashboard-footer">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default ChallengeExercise;
