import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import '../../styles/dashboard-style.css';
import Header from '../../components/global/Header';
import { color } from '@mui/system';
import StaticFooter from './StaticFooter';
const TermsOfUse = () => {

    return (
        <div className="dashboardContent error-template">
            <Helmet>
                <title>Terms oF Use | ASRC iGlebe</title>
            </Helmet>
            <div>
                <Header />
            </div>
            <div>

                <div className='row '>
                    <div className='col-lg-3'></div>
                    <div className="col-lg-6 region region-content1 ">
                        <div id="block-satp-user-content" className="block block-system block-system-main-block">
                            <div className="content welcomeContent1" id='heading' >

                                Terms of Use

                            </div>
                        </div>
                        <div id="block-satp-user-content" className="block block-system block-system-main-block mt-3">
                            <div className="content">
                                <p>
                                    This site at https://iglebe.asrc.edu.au/app/ (Site) delivers a cognitive behaviour therapy (CBT) program for adults who stutter. The program is specifically designed to teach adults who stutter how to manage social anxiety (Program).
                                </p>

                                <p>
                                    The Site is provided by the University of Technology Sydney (UTS) (ABN 77 257 686 961) (“the University” "we", "us" or "our") through its Australian Stuttering Research Centre. The terms and conditions set out on this page (Terms) govern your access to, and use of, the Site.
                                </p>

                                <p>
                                    If you browse the Site, or proceed to access the Program, you are deemed to have accepted the Terms and agree to comply at all times, with all applicable laws in connection with the use of the Site, and with our by-laws, rules, policies and procedures, and that you are responsible for informing yourself of the requirements applicable to you under such by-laws, rules, policies and procedures available here: <a className='breakWord' href='https://www.uts.edu.au/about/uts-governance/policies'> https://www.uts.edu.au/about/uts-governance/policies</a><br />

                                    UTS may change the content of the Program, information on the Site, these Terms or our Privacy Policy at our discretion and without notice, subject to law. By continuing to use the Program you accept the Terms as they apply from time to time.
                                </p>

                            </div>
                        </div>



                        <div id="block-satp-user-content" className="block block-system block-system-main-block">
                            <div className="content welcomeContent1" >
                                <h3 id="para">Program</h3>
                            </div>
                        </div>
                        <div id="block-satp-user-content" className="block block-system block-system-main-block mt-3">
                            <div className="content">
                                <p>
                                    The Program provides CBT for social anxiety for adults who stutter. The procedures used within the Program are based on well-established cognitive behaviour therapy techniques. The program is free of charge and is available to adults who stutter who can read and write English.
                                </p>
                                <p>
                                    The Program has been developed by researchers at the Australian Stuttering Research Centre, University of Technology. Initial development of the program was supported by the University of Sydney.
                                </p>

                            </div>
                        </div>

                        <div id="block-satp-user-content" className="block block-system block-system-main-block">
                            <div className="content welcomeContent1" >
                                <h3 id="para">Content</h3>
                            </div>
                        </div>
                        <div id="block-satp-user-content" className="block block-system block-system-main-block mt-3">
                            <div className="content">
                                <p>
                                    The Program is tailored for each user based on their responses to a range of questionnaires administered at the beginning of the Program. While the Program involves no live clinician contact it uses the voices and faces of two clinical psychologists—a man and a woman—to talk the user through the Program.
                                </p>
                                <p>
                                    The Program is designed to be an individualised CBT package for each user. The Program has seven sections and the sections must be completed in sequential order. Each section may include on-screen text and graphics, embedded audio, practical tasks and handouts. The Program is designed for users to log in regularly, complete learning tasks online and complete homework tasks while away from the Program during their daily situations.
                                </p>

                                <p>
                                    The user receives automated emails throughout the Program. Emails are sent when a user completes a section. Emails are also sent if the user has not logged onto the Program for 3, 7, 10, 14, 21 or 21 days. The emails remind users they have 5 months to complete the Program.
                                </p>
                            </div>
                        </div>

                        <div id="block-satp-user-content" className="block block-system block-system-main-block">
                            <div className="content welcomeContent1" >
                                <h3 id="para">Important Information</h3>
                            </div>
                        </div>
                        <div id="block-satp-user-content" className="block block-system block-system-main-block mt-3">
                            <div className="content">
                                <p>
                                    The purpose of the Program is to provide an individualised CBT package for adults who stutter.
                                </p>
                                <p>
                                    The information contained in, or accessed through the Site is for your general information about social anxiety and to introduce you to common thought patterns that can result in anxiety. The information provided during the program may be used to assist you to manage social anxiety but is not intended to be used for medical advice. Information provided through the Site is not a substitute for independent professional advice and should not be used as an alternative to medical advice or professional health care.
                                </p>

                                <p>
                                    This Site is not designed to diagnose or treat anxiety or other mental health conditions. The diagnosis and treatment of clinical anxiety and depression requires a medical practitioner or qualified mental health professional. People seeking a diagnosis or treatment of depression, anxiety or other mental health disorders should consult a medical practitioner or mental health professional. The questionnaires in the Program are not designed to provide a diagnosis.
                                </p>

                                <p>
                                    By using this Site and continuing to access the Program, you acknowledge and agree that no information or advice provided on or available through this Site constitutes medical, psychological or health care advice and you acknowledge that you should consult an appropriate professional for specific advice tailored to your situation. You should also not disregard professional advice, or delay seeking it, because of any information contained in the Program, including any results provided (e.g. as part of the questionnaires or treatment plans).
                                </p>
                            </div>
                        </div>

                        <div id="block-satp-user-content" className="block block-system block-system-main-block">
                            <div className="content welcomeContent1" >
                                <h3 id="para">Supervision</h3>
                            </div>
                        </div>

                        <div id="block-satp-user-content" className="block block-system block-system-main-block mt-3">
                            <div cclassName="content">
                                <p>
                                    You acknowledge and agree that:
                                </p>
                                <p>
                                    <ul>
                                        <li>your participation in the Program will not be monitored or reviewed by a healthcare professional; and</li>
                                        <li>we are not required to follow up with you, regarding your care or mental health.</li>
                                    </ul>
                                </p>


                            </div>
                        </div>

                        <div id="block-satp-user-content" className="block block-system block-system-main-block">
                            <div className="content welcomeContent1" >
                                <h3 id="para">Liability and Warranties</h3>
                            </div>
                        </div>

                        <div id="block-satp-user-content" className="block block-system block-system-main-block mt-3">
                            <div className="content">
                                <p>
                                    The University makes no warranties or representations whatsoever regarding the quality, currency, content, completeness, accuracy or adequacy of the information on and available through this Site, or the suitability, and / or availability of the Program, including the suggested management plan. The Program is provided to you on an "as is" and "as available" basis.
                                </p>
                                <p>
                                    You agree that your participation in the Program is at your sole risk. We do not warrant that:
                                </p>
                                <p>
                                    <ul>
                                        <li>the Program or its contents will meet your specific needs or requirements;</li>
                                        <li>the Program or its contents will be uninterrupted, timely, secure or error-free;</li>
                                        <li>the results (including any test or assessment results) which may be obtained from the use of the Program will be accurate or reliable; or</li>
                                        <li>the quality of any products, services, information or other material you obtain through the Program will meet your expectations.</li>
                                    </ul>
                                </p>
                                <p>
                                    While the Program is subject to the University’s quality assurances processes, the Program is also subject to continuing research, clinical experience, reasonable differences in opinions among authorities, unique aspects of individual situations and the possibility of human error.
                                </p>
                                <p>
                                    Whilst we provide general health information, the circumstance of each individual will differ. You should exercise your own judgment with respect to your participation in the Program and you should carefully evaluate the currency, completeness, accuracy, reliability, suitability and relevance of the available information.
                                </p>

                            </div>
                        </div>

                        <div id="block-satp-user-content" className="block block-system block-system-main-block">
                            <div className="content welcomeContent1" >
                                <h3 id="para">Eligibility</h3>
                            </div>
                        </div>

                        <div id="block-satp-user-content" className="block block-system block-system-main-block mt-3">
                            <div className="content">
                                <p>
                                    The Program has been designed for adults who stutter.
                                </p>

                            </div>
                        </div>

                        <div id="block-satp-user-content" className="block block-system block-system-main-block">
                            <div className="content welcomeContent1" >
                                <h3 id="para">Intellectual Property Rights</h3>
                            </div>
                        </div>

                        <div id="block-satp-user-content" className="block block-system block-system-main-block mt-3">
                            <div className="content">
                                <p>
                                    All intellectual property rights to the Program (including the software, design, text, data, icons, logos, sound recordings and graphics comprised in the Program) are owned by the University of Technology Sydney.
                                </p>

                            </div>
                        </div>

                        <div id="block-satp-user-content" className="block block-system block-system-main-block">
                            <div className="content welcomeContent1" >
                                <h3 id="para">Acknowledgement</h3>
                            </div>
                        </div>

                        <div id="block-satp-user-content" className="block block-system block-system-main-block mt-3">
                            <div className="content">
                                <p>
                                    The University of Technology Sydney acknowledges that the University of Sydney was involved in the initial development of this website and some of the materials used in the Program.
                                </p>
                                <p>
                                    Information or materials procured from a third party may be the subject of copyright owned by that third party.
                                </p>

                            </div>
                        </div>


                        <div id="block-satp-user-content" className="block block-system block-system-main-block">
                            <div className="content welcomeContent1" >
                                <h3 id="para">System requirements</h3>
                            </div>
                        </div>

                        <div id="block-satp-user-content" className="block block-system block-system-main-block mt-3">
                            <div className="content">
                                <p>
                                    Some services available through the Site will require your computer, telephone, internet access, device and associated equipment to meet certain technical levels (System Requirements) in order to receive the services. UTS takes no responsibility for problems associated with or arising from your inability to access or receive the services due to your equipment or internet speed not meeting the System Requirements.
                                </p>

                            </div>
                        </div>

                        <div id="block-satp-user-content" className="block block-system block-system-main-block">
                            <div className="content welcomeContent1" >
                                <h3 id="para">General</h3>
                            </div>
                        </div>

                        <div id="block-satp-user-content" className="block block-system block-system-main-block mt-3">
                            <div className="content">
                                <p>
                                    This agreement is governed by the laws of New South Wales, Australia. Each party submits to the non-exclusive jurisdiction of the courts of that place.
                                </p>

                                <p>We may use any personal information in accordance with our privacy policy.</p>

                            </div>
                        </div>

                        <div id="block-satp-user-content" className="block block-system block-system-main-block">
                            <div className="content welcomeContent1" >
                                <h3 id="para">Access</h3>
                            </div>
                        </div>

                        <div id="block-satp-user-content" className ="block block-system block-system-main-block mt-3">
                            <div className="content">
                                <p>
                                    Your access to and use of the Site, is subject to these terms and conditions. You agree to be bound by, and comply with, these terms and conditions by browsing the Site.
                                </p>

                                <p>The Site may contain links to external websites that are not operated by us or our related bodies. These links are provided for your convenience only and:</p>
                                <p>
                                    <ol type='number'>
                                        <li>we make no representations or warranties, or have any responsibility or liability for those websites or their content;</li>
                                        <li> these links do not indicate, expressly or implied, that we endorse the site or the products or services that are provided at those websites; and</li>
                                        <li> we do not specifically endorse any organization, association or entity referred to in, or linked to, the Site.</li>
                                    </ol>
                                </p>

                            </div>
                        </div>

                        <div id="block-satp-user-content" className="block block-system block-system-main-block">
                            <div className="content welcomeContent1" >
                                <h3 id="para">Account and Login Details</h3>
                            </div>
                        </div>

                        <div id="block-satp-user-content" className="block block-system block-system-main-block mt-3">
                            <div className="content">
                                <p>
                                    Users will register to use the Site through the automated registration system. Users will register their first and last name, email address and city/country of address, and create a user name and login. User will have access to the site for 5 months from the time of registration.
                                </p>

                                <p>You agree not to access (or attempt to access) the Site by any means other than through the login provided to you. Your login consists of the user name and password that you register. You agree not to share your login with others or otherwise disclose your login to unauthorised third parties for any purpose whatsoever. You agree to immediately notify us of any unauthorised use of your login or any other breach of security that you are aware of.</p>

                            </div>
                        </div>

                        <div id="block-satp-user-content" className="block block-system block-system-main-block">
                            <div className="content welcomeContent1" >
                                <h3 id="para">Prohibited Actions</h3>
                            </div>
                        </div>

                        <div id="block-satp-user-content" className="block block-system block-system-main-block mt-3">
                            <div className="content">
                                <p>
                                    You may not:
                                </p>

                                <p>
                                    <ol type="number">
                                        <li>use the Site in a way that is contrary to these Terms, our by-laws, rules, policies and procedures;</li>
                                        <li>use the Site for any activities that breach any laws, infringe a third party’s rights or are contrary to any relevant standards or codes, including, but not limited to breaches of the Copyright Act 1968 (Cth);</li>
                                        <li>except as permitted under the Copyright Act 1968 (Cth), other applicable laws, or as expressly authorised by us or these Terms, you must not adapt, reproduce, copy, store, transmit, print, communicate to the public, distribute, publish, create derivative works from or use any part of the Site or Program for any purpose whatsoever;</li>
                                        <li>use the Site in a manner or way, or post to or transmit to or via the Site, any material which interferes with other users or our other customers or defames, harasses, threatens, menaces or offends any person or which prevents any other person from using or enjoying the Site;</li>
                                        <li>
                                            make fraudulent or speculative enquiries or requests through the Site;
                                        </li>
                                        <li>
                                            use another person’s details without their permission or impersonate another person when using the Site;
                                        </li>
                                        <li>share your password or log-in details;</li>
                                        <li>post or transmit any obscene, indecent, inflammatory or pornographic material or any other material that may give rise to civil or criminal proceedings;</li>
                                        <li>tamper with or hinder the operation of the Site;</li>
                                        <li>knowingly transmit any viruses, worms, defects, trojan horses or similar disabling or malicious code to the Site;</li>
                                        <li>use any robot, spider, site search and retrieval application or other mechanism to retrieve or index any portion of the Site;</li>
                                        <li>decipher, decompile, disassemble, or reverse engineer any of the software on the Site, or in any way used or downloaded from the Site;</li>
                                        <li>modify, adapt or translate any portion of the Site;</li>
                                        <li>use any of the software on the Site, or downloaded from the Site, to create a competing product;</li>
                                        <li>remove any copyright, trade mark or other proprietary rights notices contained in or on the Site;</li>
                                        <li>reformat or frame any portion of the web pages that are part of the Site;</li>
                                        <li>create accounts by automated means or under false or fraudulent pretences;</li>
                                        <li>use the Site to violate the security of any computer or other network or engage in illegal conduct;</li>
                                        <li>take any action that imposes or that would, in our reasonable opinion, result in an unreasonable or disproportionately large load on our infrastructure;</li>
                                        <li>use the Site other than in accordance with these terms and conditions; or</li>
                                        <li>attempt any of the above acts or engage or permit another person to do any of the above acts.</li>
                                    </ol>
                                </p>

                            </div>
                        </div>

                        <div id="block-satp-user-content" className="block block-system block-system-main-block">
                            <div className="content welcomeContent1" >
                                <h3 id="para">Termination</h3>
                            </div>
                        </div>

                        <div id="block-satp-user-content" className="block block-system block-system-main-block mt-3">
                            <div className="content">
                                <p>
                                    We may immediately suspend, terminate or limit your access to and use of the Site if you breach these Terms:
                                </p>

                                <p>
                                    <ol type="number">
                                        <li>the breach cannot be remedied; or</li>
                                        <li>you fail to the remedy the breach within 10 days of our notice to you of that breach; or</li>
                                        <li>if there is an emergency or we need to perform some maintenance on the Site.</li>
                                    </ol>
                                </p>

                            </div>
                        </div>

                    </div>


                   
                    <div className='col-md-3'></div>
                   <div className='fixedDiv col-md-12'>
                        <StaticFooter/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TermsOfUse;