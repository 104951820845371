import React from "react";
import { Helmet } from "react-helmet";
import InputForms from "../templates/InputForms/InputForms";
import { GetInputQuestions } from "../../../../services/part1/get-input-forms";
import { useTranslation } from "react-i18next";

const ThinkingExercise1 = () => {
  const { t } = useTranslation(["partOneThinkingExerciseOne"]);
  const [inputQuestions, errorMessage] = GetInputQuestions(1); // Input Questions list from API

  /*******   Page Params  *******/

  const title = t("partOneThinkingExerciseOne:title");

  const content = `<div>${t("partOneThinkingExerciseOne:content")}</div>`;

  const situation = `${t("partOneThinkingExerciseOne:situation")}`;

  // const images = [
  //     "alex_anxious.jpg",
  //     "alex_angry.jpg",
  //     "alex_sad.jpg",
  //     "alex_happy.jpg"
  // ];
  const images = [
    "Alex_anxious.png",
    "Alex_Angry.png",
    "Alex_sad.png",
    "Alex_happy.png",
  ];

  /*******   Render  *******/

  return (
    <div>
      <Helmet>
        <title>{t("partOneThinkingExerciseOne:helmet")}</title>
      </Helmet>
      <InputForms
        title={title}
        content={content}
        questions={inputQuestions}
        link="part_1/lets_review"
        type={"create_Excercise1"}
        situation={situation}
        sitnum={1}
        images={images}
        error={errorMessage}
        previousLink="part_1/waiting_for_the_bus"
      />
    </div>
  );
};

export default ThinkingExercise1;
