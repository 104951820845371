import React from "react";
import { Helmet } from "react-helmet";
import ContentPage from "../ContentPages";
import { DOMAIN_NAME } from "../../../../env/Baseurl";
import { useTranslation } from "react-i18next";
// import everyoneImage from '../../../../public/images/part1/Everyone.png'
// import cutieImage from '../../../../public/images/part1/Cutie.png'
const AnxiousCauses = () => {
  const { t } = useTranslation(["partOneAnxiousCauses"]);
  /*******   Page Params  *******/

   // ============Local Storage ==========================

   const userInfo = localStorage.getItem('user');
   const user = JSON.parse(userInfo);
   const languageCode = user.userInfo.languageCode;

  const title = t("partOneAnxiousCauses:title");

  const content = `<div className='wait-for-the'><p>${t(
    "partOneAnxiousCauses:description.line1"
  )}</p><p class='redText'>${t(
    "partOneAnxiousCauses:description.line2"
  )}</p><p class='img-how-to-sty'>
    <img class='part1_everyone_image' src=${DOMAIN_NAME}images/${languageCode}/part1/Everyone.png
  )} />
    </p><p><span class='redText'>${t(
      "partOneAnxiousCauses:description.line3"
    )}</span> ${t("partOneAnxiousCauses:description.line4")}</p><p>${t(
    "partOneAnxiousCauses:description.line5"
  )}</p><div class='row flx-col'><div class='col-sm-6'><p class='redText'>${t(
    "partOneAnxiousCauses:description.line6"
  )}</p><p>${t("partOneAnxiousCauses:description.line7")}</p><p>${t(
    "partOneAnxiousCauses:description.line8"
  )}</p><p>${t(
    "partOneAnxiousCauses:description.line9"
  )}</p><p class='redText'>${t(
    "partOneAnxiousCauses:description.line10"
  )}</p></div> <div class='col-sm-6'>
    <img class='part1_waiting_other_images' src=${DOMAIN_NAME}/images/${languageCode}/part1/Cutie.png
  )} />
    </div></div><div class='row flx-col'><div class='col-sm-6'><p class='redText'>${t(
      "partOneAnxiousCauses:description.line11"
    )}</p><p>${t("partOneAnxiousCauses:description.line12")}</p><p>${t(
    "partOneAnxiousCauses:description.line13"
  )}</p><p>${t(
    "partOneAnxiousCauses:description.line14"
  )}</p><p class='redText'>${t(
    "partOneAnxiousCauses:description.line15"
  )}</p></div> <div class='col-sm-6'><img class='part1_waiting_other_images' src=${DOMAIN_NAME}/images/${languageCode}/part1/Dan.png
  )} /></div></div><div class='row flx-col'><div class='col-sm-6'><p class='redText'>${t(
    "partOneAnxiousCauses:description.line16"
  )}</p><p>${t("partOneAnxiousCauses:description.line17")}</p><p>${t(
    "partOneAnxiousCauses:description.line18"
  )}</p><p class='redText'>${t(
    "partOneAnxiousCauses:description.line19"
  )}</p></div> <div class='col-sm-6'><img class='part1_waiting_other_images' src=${DOMAIN_NAME}/images/${languageCode}/part1/Mel.png
  )} /></div></div><p style='text-align:center;' class='redText'><strong><span style='font-size: x-large;text-align:center;'>${t(
    "partOneAnxiousCauses:description.line20"
  )}</span>&nbsp;${t(
    "partOneAnxiousCauses:description.line21"
  )}</strong></p></div>`;

  /*******   Render  *******/

  return (
    <div className="welcome-content">
      <Helmet>
        <title>{t("partOneAnxiousCauses:helmet")}</title>
      </Helmet>
      <ContentPage
        title={title}
        content={content}
        link="part_1/thinking_exercise_1"
        previousLink="part_1/anxiety_is_important_in_stuttering"
      />
    </div>
  );
};

export default AnxiousCauses;
