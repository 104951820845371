import { AUDIO_FAIL, AUDIO_SUCCESS, SET_MESSAGE } from "./types";
import audio from "../services/audio-service";

const getAudio = (pagePath) => (dispatch) => {
        
    return audio(pagePath).then(
      (response) => {
        dispatch({
          type: AUDIO_SUCCESS,
          payload: response,
        });
        dispatch({
          type: SET_MESSAGE,
          payload: response.message,
        });
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        dispatch({
          type: AUDIO_FAIL,
        });
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
        return Promise.reject();
      }
    );
  };

  export default getAudio;