import React, { useRef, forwardRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import ContentPage from "../templates/ContentPages";
import { useTranslation } from "react-i18next";
// import ReactToPrint from '../../../../actions/ReactToPrint';
const Welcome = () => {
  const { t } = useTranslation(["welcome"]);

  const moduleName = localStorage.setItem("moduleName", "Pre-Treatment"); // User visited module

  /*******   Page Params  *******/

  const title = t("title");

  const content = `
    <div>${t("description.line1")}</div>
    <div>${t("description.line2")}</div> 
    <div>${t("description.line3")}</div> 
    <div>${t("description.line4")}</div> 
    <div>${t("description.line5")}</div> 
    <div>${t("description.line6")}</div>`;

  /*******   Render  *******/

  return (
    <div className="welcome-content">
      <Helmet>
        <title>{t("helmet")}</title>
      </Helmet>
      <ContentPage title={title} content={content} link="intro/how_to" />
    </div>
  );
};

export default Welcome;
