import React, { useState, useEffect, useTransition } from 'react';
import { Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ReactTooltip from 'react-tooltip';
import Modal from 'react-bootstrap/Modal';
import { TailSpin } from 'react-loader-spinner'
import { Helmet } from 'react-helmet';

import { BASE_URL } from '../../../../env/Baseurl';
import ErrorLogging from '../../../../services/error-logs';
import '../../../../styles/adminlte.min.css';
import '../../../../styles/dashboard-style.css';
import Header from '../../../global/Header';
import Sidebar from '../../../global/Sidebar';
import Footer from '../../../global/Footer';
import RefreshToken from '../../../../services/refresh-token';
import { DOMAIN_NAME } from '../../../../env/Baseurl';
import { GetEssay } from '../../../../services/part5/get-essay';
import ModuleCompletionEmails from '../../../../services/module-completion-emails';
import { myprintFunction } from '../../../../actions/NormalPrintFunction';
import FooterOfPrint from '../../../global/FootorOfPrint';
import { useTranslation } from 'react-i18next';
// import part5Image from '../../../../public/images/part5/Part5_Welcome.png'

const WelcomeToPart5 = () => {

    const { t } = useTranslation(["partFiveWelcomeToPartFive", "actions"])

    const [essay, errorMessage] = GetEssay();      // Get Tyranny Should Response frpm api

    const moduleName = localStorage.setItem('moduleName', 'Part 5');
    const part5Accessed = localStorage.setItem('part5Accessed', "true")

    // ============Local Storage ==========================

    const userInfo = localStorage.getItem('user'); 
    const user = JSON.parse(userInfo);
    const languageCode =user.userInfo.languageCode;



    /*******   States *******/

    const [state, setState] = useState("");
    const [successMessage, setSuccessMessage] = useState('');
    const [postErrorMessage, setErrorMessage] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [textBoxValidation, setTextboxValidation] = useState({
        id: "",
        errorMessage: "",
        disabledMessage: ""
    });
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    let handleClose = () => setShow(false);

    // error modal
    const [showError, setShowError] = useState(false);
    const handle = () => setShowError(true);
    let close = () => setShowError(false);

    /*******   Helper Functions  *******/

    let navigate = useNavigate();

    const benResponse = t('partFiveWelcomeToPartFive:benResponse');

    const specialCharactersRegex = /^[^a-zA-Z0-9]+$/;

    const handleChange = () => (e) => {
        const { value } = e.target;

        setState(value);

        if (value !== "" || value === "") {
            specialCharactersRegex.test(
                value) ?
                setTextboxValidation({
                    errorMessage: t('partFiveWelcomeToPartFive:textBoxValidation.validation1'),
                    disabledMessage: t('partFiveWelcomeToPartFive:textBoxValidation.validation2')
                })
                :
                setTextboxValidation({
                    errorMessage: "",
                    disabledMessage: t('partFiveWelcomeToPartFive:textBoxValidation.validation3')
                })
        };

    };

    const postSubmission = async () => {

        const userInfo = localStorage.getItem('user');
        const user = JSON.parse(userInfo);

        try {
            const params = JSON.stringify({
                "essay_text": state,
                "saveasdraft": "false"
            });
            const response = await axios.post(BASE_URL + `essay_create/${user.userInfo._id}`, params, {
                "headers": {
                    "Authorization": `Bearer ${user.token}`,
                    "content-type": "application/json",
                    "languageCode": user.userInfo.languageCode,
                },
            })
            setSuccessMessage(response.data);
            if (state !== "") {
                navigate('/part_5/should_statements', { replace: true })
            }
            else {
                alert("Please fill the essay field.");
            }
        } catch (error) {
            if (error.response.status === 401) {
                RefreshToken(user.userInfo._id)
                postSubmission();
            }
            else {
                setErrorMessage('Please check Create Questionnaire Api');
                ErrorLogging('HTTP POST /createQuestionnairs_type/userId' + error);
                handle();
            }

        }

    };


    const saveDraft = async () => {

        const userInfo = localStorage.getItem('user');
        const user = JSON.parse(userInfo);

        try {
            const params = JSON.stringify({
                "essay_text": state,
                "saveasdraft": "true"
            });
            const response = await axios.post(BASE_URL + `essay_create/${user.userInfo._id}`, params, {
                "headers": {
                    "Authorization": `Bearer ${user.token}`,
                    "content-type": "application/json",
                    "languageCode": user.userInfo.languageCode,
                },
            })
            if (response.data.status === true) {
                setSuccessMessage('Saved As Draft');
                handleShow();
            }
        } catch (error) {
            if (error.response.status === 401) {
                RefreshToken(user.userInfo._id)
                saveDraft();
            }
            else {
                setErrorMessage('Please check Create Questionnaire Api');
                ErrorLogging('HTTP POST /createQuestionnairs_type/userId' + error);
                handle();
            }

        }

    };

    const nextPage = () => {
        navigate('/part_5/should_statements', { replace: true });
    };

    const previousPage = () => {

        navigate(`/part_4/continue`, { replace: true })

    };

    // close modal on its own

    setTimeout(handleClose, 1000);

    /*******   Hooks  *******/

    useEffect(() => {


        if (state !== "" && specialCharactersRegex.test(state) === false) {
            setDisabled(false);

        }

        else {
            setDisabled(true);
        }


    }, [state]);

    useEffect(() => {
        if (essay.global_disable_status === true) {
            setDisabled(false);
        }
    }, [essay]);

    useEffect(() => {

        ModuleCompletionEmails('sendmailPart4');        // Sending module completion email

    }, []);





    /*******   Render  *******/

    return (
        <>
            <div id="printAble"></div>
            <div id="mainContainer" className="dashboardContent">
                <Helmet>
                    <title> {t('partFiveWelcomeToPartFive:helmet')}</title>
                </Helmet>
                <div>
                    {/* <Header /> */}
                    <Header targeted_print_component_id={"main"} myprintFunction={myprintFunction} />
                </div>
                <div className="row">
                    <div className="col-md-2  position-static pl-0">
                        <div className="leftside">
                            <Sidebar />http://localhost:3000/part_5/essay
                        </div>
                    </div>
                    <div className="col-md-10 content-widthsidebar content-wrapper">
                        <div className="card">
                            <div className="card-body">
                                <div id="main">
                                    <div className="card-body">
                                        <div className="title mb-3">
                                        {t('partFiveWelcomeToPartFive:title')}
                                        </div>
                                        <div className='img-how-to-sty centre-align'>
                                            <img src={`${DOMAIN_NAME}images/${languageCode}/part5/Part5_Welcome.png`} alt="welcome-to-part-5" />
                                            {/* <img src={part5Image} alt="welcome-to-part-5" /> */}
                                        </div>
                                        <p>
                                        {t('partFiveWelcomeToPartFive:content.line1')}<span className='redText'>  {t('partFiveWelcomeToPartFive:content.line2')}</span>
                                        </p>
                                        <p>
                                        {t('partFiveWelcomeToPartFive:content.line3')}
                                        </p>
                                        <p className='redText'>
                                        {t('partFiveWelcomeToPartFive:content.line4')}
                                        </p>
                                        <div className="textbox">
                                            <textarea
                                                className="form-control"
                                                id="ControlTextarea"
                                                rows="6"
                                                value={benResponse}
                                                name="ben-response"
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="textbox">
                                            <p className='mt-4'>
                                            {t('partFiveWelcomeToPartFive:content.line5')}
                                            </p>
                                            {
                                                errorMessage ? (<div className="container mt-5">
                                                    <div className='row justify-content-center'>
                                                        <div className="alert alert-danger text-center" role="alert">
                                                            {errorMessage}
                                                        </div>
                                                    </div>
                                                </div>)
                                                    :
                                                    essay !== undefined && essay.length !== 0 ? (
                                                        <>
                                                            <p className='redText'>
                                                            {t('partFiveWelcomeToPartFive:content.line6')}
                                                            </p>
                                                            <textarea
                                                                readOnly={essay.obj.disable_status}
                                                                className="form-control"
                                                                id="ControlTextarea"
                                                                rows="6"
                                                                name="essay"
                                                                defaultValue={essay.obj.eassy}
                                                                onChange={handleChange()}
                                                            />
                                                            <small className="text-danger">
                                                                {textBoxValidation.errorMessage}
                                                            </small>
                                                        </>
                                                    ) :
                                                        <div className="loader-icon">
                                                            <TailSpin color="#4f5962" height={50} width={50} />
                                                        </div>
                                            }
                                        </div>
                                    </div>
                                    {/* <div>
                                        <FooterOfPrint/>
                                    </div> */}
                                </div>

                                <div className='row'>
                                    <div className='previous1 col-6'>

                                        <Button
                                            className="user-reponse-submit btn btn-primary link-sty mt-3"
                                            onClick={previousPage}

                                        >
                                           {t('actions:previous')}
                                        </Button>
                                    </div>
                                    <div className='next col-6'>
                                        {essay !== undefined && essay.length !== 0 && essay.global_disable_status === false ? (
                                            <>
                                                <Button
                                                    className="user-reponse-submit save-draft-btn mt-3"
                                                    onClick={saveDraft}
                                                >
                                                    {t('actions:save')}
                                                </Button>
                                            </>
                                        ) :
                                            <>
                                            </>
                                        }
                                        {essay !== undefined && essay.length !== 0 && essay.global_disable_status === false ? (
                                            <>
                                                <span data-tip={disabled === true ? textBoxValidation.disabledMessage : ""} data-for='toolTip'>
                                                    <Button
                                                        className="user-reponse-submit btn btn-primary link-sty mt-3"
                                                        onClick={postSubmission}
                                                        disabled={disabled}
                                                    >
                                                        {t('actions:continue')}
                                                    </Button>
                                                </span>
                                                <ReactTooltip id="toolTip" />
                                            </>
                                        ) :
                                            <>
                                                <Button
                                                    className="user-reponse-submit btn btn-primary link-sty mt-3"
                                                    onClick={nextPage}
                                                >
                                                    {t('actions:continue')}
                                                </Button>
                                            </>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal show={show} onHide={handleClose}>
                        {successMessage ?
                            <Modal.Body>{successMessage}</Modal.Body>
                            :
                            <Modal.Body>{postErrorMessage}</Modal.Body>
                        }
                    </Modal>
                    {/* error message modal */}

                    <Modal show={showError} >
                        <Modal.Body>{postErrorMessage}</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={close}>
                            {t('actions:close')}
                            </Button>

                        </Modal.Footer>
                    </Modal>
                    <div className="col-md-12 dashboard-footer">
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
};

export default WelcomeToPart5;
