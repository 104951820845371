import React from "react";
import { Helmet } from "react-helmet";
import ContentPage from "../templates/ContentPages";
import { useTranslation } from "react-i18next";

const Hello = () => {
  const { t } = useTranslation(["hello"]);
  const userInfo = localStorage.getItem("user");
  const user = JSON.parse(userInfo);

  /*******   Page Params  *******/

  const title = `${t("greet")} ${user.userInfo.Fname}`;

  const content = `<div>${t(
    "description.line1"
  )}<a href='http://www.rossmenzies.com.au/' target='_blank' rel='noreferrer'>http://www.rossmenzies.com.au/</a>${t(
    "description.line2"
  )} <a href='https://www.ai-therapy.com' target='_blank' rel='noreferrer'>https://www.ai-therapy.com.</a></div> <div>${t(
    "description.line3"
  )}</div> <div>${t("hello:description.line4")}</div>`;

  /*******   Render  *******/

  return (
    <div className="welcome-content">
      <Helmet>
        <title>Hello | ASRC iGlebe</title>
      </Helmet>
      <ContentPage
        title={title}
        content={content}
        link="intro/fne_questionnaire"
        previousLink="intro/avoidance_questionnaire"
      />
    </div>
  );
};

export default Hello;
