const myprintFunction = () => {
    var realData = document.getElementById("main").innerHTML

    var secondData = document.getElementById("printAble").innerHTML
    document.getElementById('printAble').innerHTML = realData
    document.getElementById("mainContainer").style.display = "none";
    // document.getElementById("printFooter").style.display = "block";
    window.print();
    document.getElementById("mainContainer").style.display = "block";
    document.getElementById("printAble").innerHTML = secondData;
    // if (dataSubmitted === true) {
    //     document.getElementById('main').innerHTML = realData;
    // }
}

export { myprintFunction }