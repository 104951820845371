import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";
import Header from "../../../global/Header";
import Sidebar from "../../../global/Sidebar";
import Footer from "../../../global/Footer";
import FooterOfPrint from "../../../global/FootorOfPrint";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import { useTranslation } from "react-i18next";

const PartComplete = () => {
  const { t } = useTranslation(["actions", "partOneComplete"]);

  /*******   Page Params  *******/

  const title = t("partOneComplete:title");

  const content = t("partOneComplete:description");

  /*******   Helper Functions  *******/

  let navigate = useNavigate();

  const nextPage = () => {
    navigate("/part_2/challenge_example", { replace: true });
  };
  const previousPage = () => {
    navigate(`/part_1/thinking_exercise_4_sample_answers`, { replace: true });
  };

  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title>{t("partOneComplete:helmet")}</title>
        </Helmet>
        <div>
          {/* <Header /> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-widthsidebar content-wrapper">
            <div className="card">
              <div className="card-body">
                <div id="main">
                  <div className="card-body">
                    <div className="title">{title}</div>
                    <div className="content">
                      <p></p>
                      <p>{t("partOneComplete:content.line1")}</p>
                    </div>
                    <div>
                      <p className="redText">
                        {t("partOneComplete:content.line2")}
                      </p>
                      <p className="redText">
                        {t("partOneComplete:content.line3")}
                      </p>
                      <ul>
                        <li> {t("partOneComplete:content.line4")}</li>
                        <li>{t("partOneComplete:content.line5")}</li>
                        <li>{t("partOneComplete:content.line6")}</li>
                      </ul>
                      <p className="redText">
                        {t("partOneComplete:content.line7")}
                      </p>
                      <p>{t("partOneComplete:content.line8")}</p>
                    </div>
                  </div>
                  {/* <div>
                                        <FooterOfPrint />
                                    </div> */}
                </div>

                <div className="row">
                  <div className="previous col-5">
                    <Button
                      className="user-reponse-submit btn btn-primary link-sty"
                      onClick={previousPage}
                    >
                      {t("actions:previous")}
                    </Button>
                  </div>
                  <div className="next col-7">
                    <Button
                      className="user-reponse-submit btn btn-primary link-sty"
                      onClick={nextPage}
                    >
                      {t("actions:continue")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default PartComplete;
