import React from 'react';
import { Helmet } from 'react-helmet';
import ContentPage from '../templates/ContentPages';
import '../../../../styles/dashboard-style.css'
import { useTranslation } from 'react-i18next';


const Part7FirstPage = () => {

    const { t } = useTranslation(["partSevenFirstPage", "actions"])

    const moduleName = localStorage.setItem('moduleName', 'Part 7');
    const part7Accessed = localStorage.setItem('part7Accessed', "true")


    /*******  Page Params  *******/

    const title = t('partSevenFirstPage:title');

    const content = `
  <div>
    <p>${t('partSevenFirstPage:content.line1')}</p>

    <p>${t('partSevenFirstPage:content.line2')}</p>

    <p class='redText'>${t('partSevenFirstPage:content.line3')}</p>

    <p>${t('partSevenFirstPage:content.line4')}</p>

    <p>${t('partSevenFirstPage:content.line5')}</p>

    <p class='redText'>${t('partSevenFirstPage:content.line6')}</p>

    <p>${t('partSevenFirstPage:content.line7')}</p>

    <p>${t('partSevenFirstPage:content.line8')}</p>

  </div>
`;

    /*******  Render  *******/

    return (
        <div className='welcome-content'>
            <Helmet>
                <title>{t('partSevenFirstPage:helmet')}</title>
            </Helmet>
            <ContentPage
                title={title}
                content={content}
                link='part_7/preventing_relapse_2'
                previousLink='part_6/more_observer_bias'
            />
        </div>
    );
};

export default Part7FirstPage;