import React, { useDebugValue, useEffect, useState } from 'react';
import Select from 'react-select';
import { countries_list } from '../../constants/countrieList/CountriesList';

import { DOMAIN_NAME } from '../../env/Baseurl';

const UserLocationInfo = ({ locationHandleChange, setChecked, checked, emptyCheck, values, setValues }) => {

    // ============Local Storage ==========================

    // const userInfo = localStorage.getItem('user'); 
    // const user = JSON.parse(userInfo);
    // const languageCode =user.userInfo.languageCode;



    //  styling*******************************
    const style = {
        placeHolderStyle: {
            fontSize: '14px',
            color: '#929BA2'
        }
    }

    const [country, setCountry] = useState("")
    const [countryCode, setCountryCode] = useState('')
    const [dialCode, setDialCode] = useState("")

    // Country list object*********************

    const selectHandleCountry = e => {
        setCountry(e.value);
        setCountryCode(e.code);
        setDialCode(e.dial_code);
        setValues({ ...values, ['country']: e.value, ['countryCode']: e.code, ['dialCode']: e.dial_code })
    }

    // useEffect(() => {
    //     console.log(countryCode, dialCode, "its data")
    // }, [countryCode, dialCode])

    /*******   HelperComponent  *******/
    const Checkbox = ({ label, value, onChange }) => {
        return (
            <label style={{ display: 'flex' }}>
                <input
                    type="checkbox"
                    checked={value}
                    onChange={onChange}
                />
                {label}
            </label>
        );
    };

    /*******   HelperFunctions  *******/
    const checkboxHandleChange = () => {
        setChecked(!checked);
    };

    /*******   Render  *******/

    return (
        <div>
            <div className="row align-items-center login-col-wrap m-0">
                <div className="col-md-6">
                    <div className="text-center">
                        <img
                            height="auto"
                            width="450px"
                            src={`${DOMAIN_NAME}images/login/login8464.png`}
                            alt="Login Group"
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div>
                        <h3 className="text-center pagehead-texttitle">
                            Your Location
                        </h3>
                        <form>
                            <div className="card-body">
                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">
                                        Country
                                    </label>
                                    {/* <input
                                        type="text"
                                        className="form-control"
                                        id="exampleInputPassword1"
                                        placeholder="Enter Country Name"
                                        onChange={locationHandleChange("country")}
                                        value={values.country}
                                    /> */}
                                    <Select
                                        // placeholder="Choose Your Country"
                                        placeholder={<div style={style.placeHolderStyle}>Choose Your Country</div>}
                                        defaultValue=
                                        {values.country === "" ?
                                            country
                                            :
                                            { label: values.country, value: values.country }
                                        }
                                        options={countries_list}
                                        onChange={e => { selectHandleCountry(e) }}
                                    />
                                    {values.country === "" && (
                                        <small className="text-danger">
                                            {emptyCheck.country}
                                        </small>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                        City
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="exampleInputEmail1"
                                        placeholder="Enter City Name(Optional)"
                                        onChange={locationHandleChange("city")}
                                        value={values.city}
                                    />
                                    {/* {values.city === "" && (
                                        <small className="text-danger">
                                            {emptyCheck.city}
                                        </small>
                                    )} */}
                                </div>

                                <div className="form-group cofirmlabel" >

                                    <Checkbox
                                        label={<label>I acknowledge that I am 18 years of age or older, and I have read and agree to the <a href="/terms-of-use" target="_blank">Terms & conditions</a></label>}
                                        value={checked}
                                        onChange={checkboxHandleChange}
                                        setChecked={checkboxHandleChange}

                                    />
                                    {checked === false && (
                                        <small className="text-danger">
                                            {emptyCheck.terms}
                                        </small>
                                    )}
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default UserLocationInfo;