import React from "react";
import { Helmet } from "react-helmet";
import InputForms from "../templates/InputForms/InputForms";
import { GetInputQuestions } from "../../../../services/part1/get-input-forms";
import { useTranslation } from "react-i18next";

const ThinkingExercise3 = () => {
  const { t } = useTranslation(["partOneThinkingExerciseThree"]);
  const [inputQuestions, errorMessage] = GetInputQuestions(3); // Input Questions list from API

  /*******   Page Params  *******/

  const title = t("title");

  const content = `<div>${t("description")}</div>`;

  const situation = t("situation");

  // const images = [
  //     "ben_anxious.jpg",
  //     "ben_angry.jpg",
  //     "ben_sad.jpg",
  //     "ben_happy.jpg"
  // ];
  const images = [
    "Ben_Anxious.png",
    "Ben_Angry.png",
    "Ben_Sad.png",
    "Ben_Happy.png",
  ];

  /*******   Render  *******/

  return (
    <div>
      <Helmet>
        <title>{t("helmet")}</title>
      </Helmet>
      <InputForms
        title={title}
        content={content}
        questions={inputQuestions}
        link="part_1/thinking_exercise_3_sample_answers"
        type={"create_Excercise3"}
        images={images}
        situation={situation}
        sitnum={3}
        error={errorMessage}
        previousLink="part_1/checklist_of_errors"
      />
    </div>
  );
};

export default ThinkingExercise3;
