import { useTranslation } from 'react-i18next';


const SelectOptions = () => {
  const { t } = useTranslation(["partOneWhatsCasualThought"]);

  const selectOptions1 = [
    { 
    value: `<span className="redText">${t('partOneWhatsCasualThought:selectOptions:option1:value1.line1')}</span> ${t('partOneWhatsCasualThought:selectOptions:option1:value1.line2')}`, 

    label: t('partOneWhatsCasualThought:selectOptions:option1:value1.label') 
  },

    {
       value: `<span className="redText">${t('partOneWhatsCasualThought:selectOptions:option1:value2.line1')}</span> ${t('partOneWhatsCasualThought:selectOptions:option1:value2.line2')}`, 
       
       label: t('partOneWhatsCasualThought:selectOptions:option1:value2.label') },

    {
       value: `<span className="redText">${t('partOneWhatsCasualThought:selectOptions:option1:value3.line1')}</span> ${t('partOneWhatsCasualThought:selectOptions:option1:value3.line2')}`, 
       
       label: t('partOneWhatsCasualThought:selectOptions:option1:value3.label') },

    {
       value: `<span className="redText">${t('partOneWhatsCasualThought:selectOptions:option1:value4.line1')}.</span> ${t('partOneWhatsCasualThought:selectOptions:option1:value4.line2')}`, 
       
       label: t('partOneWhatsCasualThought:selectOptions:option1:value4.label') },

  ];

const selectOptions2 = [
    { value: `<span className="redText">${t('partOneWhatsCasualThought:selectOptions:option2:value1.line1')}</span> ${t('partOneWhatsCasualThought:selectOptions:option2:value1.line2')}`, label: t('partOneWhatsCasualThought:selectOptions:option2:value1.label') },

    { value: `<span className="redText">${t('partOneWhatsCasualThought:selectOptions:option2:value2.line1')}</span> ${t('partOneWhatsCasualThought:selectOptions:option2:value2.line2')}`, label: t('partOneWhatsCasualThought:selectOptions:option2:value2.label') },

    { value: `<span className="redText">${t('partOneWhatsCasualThought:selectOptions:option2:value3.line1')}</span> ${t('partOneWhatsCasualThought:selectOptions:option2:value3.line2')}`, label: t('partOneWhatsCasualThought:selectOptions:option2:value3.label') },

    { value: `<span className="redText">${t('partOneWhatsCasualThought:selectOptions:option2:value4.line1')}</span> ${t('partOneWhatsCasualThought:selectOptions:option2:value4.line2')}`, label: t('partOneWhatsCasualThought:selectOptions:option2:value4.label') },
  ];  

   return { selectOptions1, selectOptions2 };

};
export default SelectOptions;



// export {
//     selectOptions1,
//     selectOptions2
// };

//================== for multi language============
// import React from 'react';
// import { useTranslation } from 'react-i18next';

// const SelectOptions = () => {
//   const { t } = useTranslation();

//   const selectOptions1 = [
//     { value: t('feedback.frustrated_no'), label: t('options.frustrated') },
//     { value: t('feedback.sad_no'), label: t('options.sad') },
//     { value: t('feedback.angry_no'), label: t('options.angry') },
//     { value: t('feedback.happy_yes'), label: t('options.happy') },
//   ];

//   const selectOptions2 = [
//     { value: t('feedback.frustrated_yes'), label: t('options.frustrated') },
//     { value: t('feedback.sad_no'), label: t('options.sad') },
//     { value: t('feedback.angry_no'), label: t('options.angry') },
//     { value: t('feedback.happy_no'), label: t('options.happy') },
//   ];

//   return { selectOptions1, selectOptions2 };
// };

// export default SelectOptions;
