import React from 'react';
import { Helmet } from 'react-helmet';
import CheckboxSituations from '../templates/CheckboxSituations';
import { GetCheckboxes } from '../../../../services/part3/get-checkboxes';
import { DOMAIN_NAME } from '../../../../env/Baseurl';
import { useTranslation } from 'react-i18next';

const SelfFocusedImagery = () => {
    const { t } = useTranslation(["actions", "partThreeSelfFocusedImagery"]);

    const [checkboxes, errorMessage] = GetCheckboxes('imagery');         // Checkboxes list from API

    // ============Local Storage ==========================

    const userInfo = localStorage.getItem('user'); 
    const user = JSON.parse(userInfo);
    const languageCode =user.userInfo.languageCode;


    /*******   Page Params  *******/

    const title = t('partThreeSelfFocusedImagery:title');

    const body = `<p> ${t('partThreeSelfFocusedImagery:content.line1')} </p> <p> ${t('partThreeSelfFocusedImagery:content.line2')} </p><p> ${t('partThreeSelfFocusedImagery:content.line3')} </p><p> ${t('partThreeSelfFocusedImagery:content.line4')} </p><p> ${t('partThreeSelfFocusedImagery:content.line5')} </p><p> ${t('partThreeSelfFocusedImagery:content.line6')} </p>`;


    const checkboxQuestion = t('partThreeSelfFocusedImagery:content.checkboxQuestion');

    const link="/part_3/your_model";

    const validationMessage = t('partThreeSelfFocusedImagery:content.validationMessage');

    const imageSrc = `${DOMAIN_NAME}images/${languageCode}/part3/modelphysio.png`;

    /*******   Render  *******/

    return(
        <>
         <Helmet>
                <title>{t('partThreeSelfFocusedImagery:helmet')}</title>
            </Helmet>
       
        <CheckboxSituations
            title={title}
            body={body}
            checkboxQuestion={checkboxQuestion}
            link={link}
            checkboxeslist={checkboxes}
            type={'create_imagery'}
            validationMessage={validationMessage}
            imageSrc={imageSrc}
            error={errorMessage}
            previousLink='part_3/physiological_anxiety_symptoms'
        />
         </>
    );
    


};

export default SelfFocusedImagery;