import React from "react";
import { Helmet } from "react-helmet";
import Questionnaire from "../templates/Questionnaire/Mcq";
import { options } from "../../../../constants/pretreatment/DassQuestionnaire";
import { GetQuestionnaire } from "../../../../services/pretreatment/get-questionnaire";
import { useTranslation } from "react-i18next";

const DassQuestionnaire = () => {
  const { t } = useTranslation(["dassQuestionnaire"]);

  const [questionnaire, errorMessage] = GetQuestionnaire("DASS"); // Questions list from API

  /*******   Page Params  *******/

  const title = t("title");

  const content = `<div>${t("description.line1")}</div> <div>${t(
    "description.line2"
  )}</div> <div>${t("description.line3")}</div> <div>${t(
    "description.line4"
  )}</div><div>${t("description.line5")}</div>`;

  /*******   Render  *******/

  return (
    <div>
      <Helmet>
        <title>{t("helmet")}</title>
      </Helmet>
      <Questionnaire
        title={title}
        content={content}
        link="part_1/anxiety_is_important_in_stuttering"
        questions={questionnaire}
        options={options}
        type={"DASS"}
        error={errorMessage}
        previousLink="intro/fne_questionnaire"
      />
    </div>
  );
};

export default DassQuestionnaire;
