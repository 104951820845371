/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
import React from 'react';
import { TailSpin } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import '../../styles/dashboard-style.css';
import Header from '../../components/global/Header';
import { formControlLabelClasses } from '@mui/material';
import { GetWelcomeUser } from '../../services/get-welcome-user';
import { DOMAIN_NAME } from '../../env/Baseurl';
import { useTranslation } from 'react-i18next';

const WelcomePage = () => {

    const {t} = useTranslation(["globalWelcomeLogin"])
    console.log(t)

    const [currentUser, errorMessage] = GetWelcomeUser();

    // ============Local Storage ==========================

    const userInfo = localStorage.getItem('user');
    const user = JSON.parse(userInfo);
    const languageCode = user.userInfo.languageCode;

    return (
        <div className="dashboardContent error-template">
            <Helmet>
                <title>{t('globalWelcomeLogin:helmet')}</title>
            </Helmet>
            <div>
                <Header />
            </div>
            <div>
                {
                    errorMessage ? (<div className="container mt-5">
                        <div className='row justify-content-center'>
                            <div className="alert alert-danger text-center" role="alert">
                                {errorMessage}
                            </div>
                        </div>
                    </div>)
                        :
                        currentUser !== undefined && currentUser.length !== 0 ? (
                            <div className='row'>
                                <div className='col-lg-3'></div>
                                <div className="col-lg-6 region region-content welcome">
                                    <div id="block-satp-user-page-title" className="block block-core block-page-title-block">
                                        <div className="content">
                                            <img className='weclomePng' src={`${DOMAIN_NAME}images/${languageCode}/welcome/welcomePng.png`}  ></img>
                                            <h1 className="title page-title">{currentUser.length !== 0 && <>{currentUser.statement1}</>}</h1>
                                        </div>
                                    </div>
                                    <div id="block-satp-user-content" className="block block-system block-system-main-block">
                                        <div className="content welcomeContent">
                                            {currentUser.length !== 0 && <>{currentUser.statement2}</>}
                                        </div>
                                    </div>
                                    <div id="block-satp-user-content" className="block block-system block-system-main-block mt-3">
                                        <div className="content">
                                            <Link to={currentUser.last_url}>{t('globalWelcomeLogin:linkText')}</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3'></div>
                            </div>
                        )
                            :
                            <div className="loader-icon">
                                <TailSpin color="#4f5962" height={50} width={50} />
                            </div>
                }
            </div>
        </div>
    );
};

export default WelcomePage;