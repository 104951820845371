import React from 'react';

import { DOMAIN_NAME } from '../../env/Baseurl';

const UserNameInfo = ({ handleChange, emptyCheck, values }) => {

    // ============Local Storage ==========================

    // const userInfo = localStorage.getItem('user'); 
    // const user = JSON.parse(userInfo);
    // const languageCode =user.userInfo.languageCode;


    /*******   Render  *******/

    return(
        <div>
            <div className="row align-items-center login-col-wrap">
                <div className="col-md-6">
                    <div className="text-center">
                        <img 
                            height="auto" 
                            width="450px" 
                            src={`${DOMAIN_NAME}images/login/login8464.png`} 
                            alt="Login Group"
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div>
                        <h3 className="text-center pagehead-texttitle">
                                Your Name
                        </h3>
                        <form>
                            <div className="card-body">
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                        First Name
                                    </label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        id="exampleInputEmail1"
                                        defaultValue={values.first_name} 
                                        placeholder="Enter your First Name"
                                        onChange={handleChange("first_name")} 
                                    />
                                        {values.first_name === "" && ( 
                                            <small className="text-danger">
                                                {emptyCheck.first_name}
                                            </small>
                                        )}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">
                                        Last Name
                                    </label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        id="exampleInputPassword1"
                                        defaultValue={values.last_name} 
                                        placeholder="Enter your Last Name"
                                        onChange={handleChange("last_name")}
                                    />
                                        {values.last_name === "" && ( 
                                            <small className="text-danger">
                                                {emptyCheck.last_name}
                                            </small>
                                        )}
                                </div>
                            </div>
                        </form>    
                    </div>
                </div>
            </div>
        </div>
    );

};

export default UserNameInfo;