import React, { useTransition } from "react";
import { Helmet } from "react-helmet";
import Questionnaire from "../templates/Questionnaire/Mcq";
import TranslatedOptions from "../../../../constants/pretreatment/AvoidanceQuestionnaire";
import { GetQuestionnaire } from "../../../../services/pretreatment/get-questionnaire";
import { useTranslation } from "react-i18next";

const AvoidanceQuestionnaire = () => {
  const { t } = useTranslation(["avoidanceQuestionnaire", "constantsPretreatmentAvoidanceQuestionnaire"]);

  const [questionnaire, errorMessage] = GetQuestionnaire("Avoidance"); // Questions list from API

  /*******   Page Params  *******/

  const title = t("title");

  const content = `<div>${t("description.line1")}</div><div>${t(
    "description.line2"
  )}</div>`;
  const options = TranslatedOptions();

  /*******   Render  *******/

  return (
    <div>
      <Helmet>
        <title>{t("helmet")}</title>
      </Helmet>
      <Questionnaire
        title={title}
        content={content}
        link="intro/welcome_user"
        questions={questionnaire}
        options={options}
        type={"Avoidance"}
        error={errorMessage}
        previousLink="intro/utbas_questionnaire"
      />
    </div>
  );
};

export default AvoidanceQuestionnaire;
