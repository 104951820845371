import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";
import store from "./store";
import App from "./components/App";
import "./translation/i18n.js";
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";


i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
});

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <Suspense fallback={""}>
    <Provider store={store}>
    <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </Provider>
  </Suspense>
);
