import { useTranslation } from 'react-i18next';

// const options = [
//     "Not at all characteristic of me",
//     "A little characteristic of me",
//     "Somewhat characteristic of me",
//     "Very characteristic of me",
//     "Entirely characteristic of me"
// ];

// export {
//     options
// };


const TranslatedOptions = () => {
    const { t } = useTranslation(["constantsPretreatmentFneQuestionnaire"]);
  
    return [
      t("options.not_at_all"),
      t("options.a_little"),
      t("options.somewhat"),
      t("options.very"),
      t("options.entirely")
    ];
  };
  
  export default TranslatedOptions;