import axios from "axios";

import { 
    BASE_URL
 } from '../env/Baseurl';

 const audio = (pagePath) => {
    const userInfo = localStorage.getItem('user');
    const user = JSON.parse(userInfo);
    const params = JSON.stringify({
        "page_name": pagePath,
        "languageCode": user.userInfo.languageCode,
    });

    return axios.post(BASE_URL + `getAudio_By_pageName/${user.userInfo._id}`, params, {
        "headers": {
        "content-type": "application/json",
        "Authorization": `Bearer ${user.token}`,
        "languageCode": user.userInfo.languageCode,
        },   
    }) 
    .then((response) => {
        return response.data;
    });

};

export default audio;