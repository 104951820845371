import React from "react";
import { Helmet } from "react-helmet";
import Questionnaire from "../templates/Questionnaire/Mcq";
import TranslatedOptions from "../../../../constants/pretreatment/FneQuestionnaire";
import { GetQuestionnaire } from "../../../../services/pretreatment/get-questionnaire";
import { useTranslation } from "react-i18next";

const FneQuestionnaire = () => {
  const { t } = useTranslation("fneQuestionnaire","constantsPretreatmentFneQuestionnaire");

  const [questionnaire, errorMessage] = GetQuestionnaire("BFNE"); // Questions list from API

  /*******   Page Params  *******/

  const title = t("title");

  const content = `<div>${t("description.line1")}</div>`;

  const options =TranslatedOptions();

  /*******   Render  *******/

  return (
    <div>
      <Helmet>
        <title>{t("helmet")}</title>
      </Helmet>
      <Questionnaire
        title={title}
        content={content}
        link="intro/dass_questionnaire"
        questions={questionnaire}
        options={options}
        type={"Bfne"}
        error={errorMessage}
        previousLink="intro/welcome_user"
      />
    </div>
  );
};

export default FneQuestionnaire;
