import React, { useEffect } from 'react';
import { Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import '../../../../styles/adminlte.min.css';
import '../../../../styles/dashboard-style.css';
import Header from '../../../global/Header';
import Sidebar from '../../../global/Sidebar';
import Footer from '../../../global/Footer';
import { DOMAIN_NAME } from '../../../../env/Baseurl';
import { CounterInitialize, FlagInitialize } from '../../../../services/part4/counter-initialize';
import { myprintFunction } from '../../../../actions/NormalPrintFunction';
import FooterOfPrint from '../../../global/FootorOfPrint';
// import introImage from '../../../../public/images/part4/Part4-Welcome_edited.png'
// import seanImage from '../../../../public/images/part4/Sean_edited.png'
import { useTranslation } from 'react-i18next';


const Part4FirstPage = () => {

    const {t} = useTranslation(["partFourFirstPage", "actions"])

    const userInfo = localStorage.getItem('user');
    const user = JSON.parse(userInfo);

    const moduleName = localStorage.setItem('moduleName', 'Part 4');
    const part4Accessed = localStorage.setItem('part4Accessed', "true")

    // ============Local Storage ==========================

    const languageCode =user.userInfo.languageCode;


    /*******   Helper Functions  *******/

    let navigate = useNavigate();

    const nextPage = () => {

        navigate('/part_4/perfectionism', { replace: true })

    };
    const previousPage = () => {

        navigate(`/part_3/your_model_of_social_anxiety`, { replace: true })

    };


    useEffect(() => {
        CounterInitialize();
        FlagInitialize();
    }, []);

    /*******   Render  *******/

    return (
        <>
            <div id="printAble"></div>
            <div id="mainContainer" className="dashboardContent">
                <Helmet>
                    <title> {t('partFourFirstPage:helmet')}</title>
                </Helmet>
                <div>
                    {/* <Header/> */}
                    <Header targeted_print_component_id={"main"} myprintFunction={myprintFunction} />
                </div>
                <div className="row">
                    <div className="col-md-2  position-static pl-0">
                        <div className="leftside">
                            <Sidebar />
                        </div>
                    </div>
                    <div className="col-md-10 content-widthsidebar content-wrapper">
                        <div className="card">
                            <div className="card-body">
                                <div id="main">
                                    <div className="card-body">

                                        <div className="title mb-3">
                                        {t('partFourFirstPage:title')}
                                        </div>
                                        <div className='img-how-to-sty centre-align'>
                                            {/* <img src={`${DOMAIN_NAME}images/part4/part4-welcome.png`} alt="welcome-to-part-4" /> */}
                                            <img src={`${DOMAIN_NAME}images/${languageCode}/part4/Part4-Welcome_edited.png`} alt="welcome-to-part-4" />
                                            {/* <img src={introImage} alt="welcome-to-part-4" /> */}
                                        </div>
                                        <p>
                                            {`${t('partFourFirstPage:content.line0')} ${user.userInfo.Fname}${t('partFourFirstPage:content.line1')}`}
                                        </p>
                                        <p>
                                            {t('partFourFirstPage:content.line2')} 
                                        </p>
                                        <p className='redText'>
                                        {t('partFourFirstPage:content.line3')}
                                        </p>
                                        <p>
                                        {t('partFourFirstPage:content.line4')}
                                        </p>
                                        <p>
                                        {t('partFourFirstPage:content.line5')}
                                        </p>
                                        <p>
                                        {t('partFourFirstPage:content.line6')}
                                        </p>
                                        <p>
                                        {t('partFourFirstPage:content.line7')}
                                        </p>
                                        <span></span>
                                        <p>
                                        {t('partFourFirstPage:content.line8')}
                                        </p>
                                        <div className='img-how-to-sty centre-align'>
                                            {/* <img src={`${DOMAIN_NAME}images/part4/sean.png`} alt="sean" /> */}
                                            <img className='sean_edited_image' src={`${DOMAIN_NAME}images/${languageCode}/part4/Sean_edited.png`} alt="sean" />
                                            {/* <img className='sean_edited_image' src={seanImage} alt="sean" /> */}
                                        </div>
                                        <p>
                                        {t('partFourFirstPage:content.line9')}
                                        </p>
                                        <p>
                                        {t('partFourFirstPage:content.line10')}
                                        </p>
                                        <p>
                                        {t('partFourFirstPage:content.line11')}
                                        </p>
                                        <p>
                                        {t('partFourFirstPage:content.line12')}
                                        </p>
                                        <p>
                                        {t('partFourFirstPage:content.line13')}
                                        </p>
                                        <p>
                                        {t('partFourFirstPage:content.line14')}
                                        </p>
                                        <p>
                                        {t('partFourFirstPage:content.line15')}
                                        </p>
                                        <p>
                                        {t('partFourFirstPage:content.line16')}
                                        </p>
                                        <p>
                                        {t('partFourFirstPage:content.line17')}
                                        </p>
                                        <p>
                                        {t('partFourFirstPage:content.line18')}
                                        </p>
                                    </div>
                                    {/* <div>
                                        <FooterOfPrint />
                                    </div> */}
                                </div>

                                <div className='row'>
                                    <div className='previous1 col-6'>

                                        <Button
                                            className="user-reponse-submit btn btn-primary link-sty"
                                            onClick={previousPage}

                                        >
                                            {t('actions:previous')}
                                        </Button>
                                    </div>
                                    <div className='next col-6'>
                                        <Button
                                            className="user-reponse-submit btn btn-primary link-sty"
                                            onClick={nextPage}
                                        >
                                            {t('actions:continue')}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 dashboard-footer">
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Part4FirstPage;