import React, { useEffect, useState } from 'react';
import { Button, InputGroup, Tooltip } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner';
import axios from 'axios';
import ReactTooltip from 'react-tooltip';
import Modal from 'react-bootstrap/Modal';


import '../../../../styles/adminlte.min.css';
import '../../../../styles/dashboard-style.css';
import Header from '../../../global/Header';
import Sidebar from '../../../global/Sidebar';
import Footer from '../../../global/Footer';
import { BASE_URL } from '../../../../env/Baseurl';
import ErrorLogging from '../../../../services/error-logs';
import RefreshToken from '../../../../services/refresh-token';
import FooterOfPrint from '../../../global/FootorOfPrint';
import { useTranslation } from 'react-i18next';


const CheckboxSituations = ({ title, body, checkboxQuestion, link, checkboxeslist, subtitle, type, validationMessage, imageSrc, error, previousLink }) => {
    const { t } = useTranslation(["actions"]);

    /*******   States *******/

    const [state, setState] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [disabled, setDisabled] = useState(true);

    // error modal
    const [showError, setShowError] = useState(false);
    const handle = () => setShowError(true);
    let close = () => setShowError(false);


    /*******   Helper Functions  *******/

    let navigate = useNavigate();

    const handleChange = (e) => {

        const { name, value } = e.target;

        if (state.some(item => item._id === name)) {

            setState(
                state.filter(state => state._id !== name)
            );

        } else {
            setState([...state, { "_id": name }]
            );
        }

        if (state.length === 0) {
            setDisabled(true);

        }

    };

    const postSubmission = async () => {

        const userInfo = localStorage.getItem('user');                        //  Logged in user info
        const user = JSON.parse(userInfo);

        try {
            const params = JSON.stringify({
                "data": state
            });
            const response = await axios.post(BASE_URL + `${type}/${user.userInfo._id}`, params, {
                "headers": {
                    "Authorization": `Bearer ${user.token}`,
                    "content-type": "application/json",
                },
            })
            setSuccessMessage(response.data);
            if (state.length !== 0) {
                navigate(`${link}`, { replace: true })

            } else {
                alert('Please select atleast one of the options.');
            }
        } catch (error) {
            if (error.response.status === 401) {
                RefreshToken(user.userInfo._id)
                postSubmission();
            }
            else {
                setErrorMessage('Please check Part 3 Checkbox situations Api');
                ErrorLogging('HTTP POST Part  3 /_type/userId' + error);
                handle();
            }

        }
    };

    const nextPage = () => {
        navigate(`${link}`, { replace: true })
    };

    const previousPage = () => {
        navigate(`/${previousLink}`, { replace: true })

    };

    /*******   Hooks  *******/

    useEffect(() => {

        if (state.length !== 0) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }

    }, [state]);

    const myprintFunction = () => {
        var realData = document.getElementById("main").innerHTML

        var secondData = document.getElementById("printAble").innerHTML
        document.getElementById('printAble').innerHTML = realData
        document.getElementById("mainContainer").style.display = "none";
        // document.getElementById("printFooter").style.display = "block";
        window.print();
        document.getElementById("mainContainer").style.display = "block";
        document.getElementById("printAble").innerHTML = secondData;
        // if (dataSubmitted === true) {
        //     document.getElementById('main').innerHTML = realData;
        // }
        if (checkboxeslist !== undefined && checkboxeslist.length !== 0 && checkboxeslist.global_disable_status === false) {

        } else {
            document.getElementById('main').innerHTML = realData;
        }
    }

    /*******   Render  *******/

    return (
        <>
            <div id="printAble"></div>
            <div id="mainContainer" className="dashboardContent">
                <div>
                    {/* <Header/> */}
                    <Header targeted_print_component_id={"main"} myprintFunction={myprintFunction} />
                </div>
                <div className="row">
                    <div className="col-md-2  position-static pl-0">
                        <div className="leftside">
                            <Sidebar />
                        </div>
                    </div>
                    <div className="col-md-10 content-widthsidebar content-wrapper">
                        <div className="card">
                            <div className="card-body">
                                <div id="main">
                                    <div className="card-body">

                                        <div className="title mb-3">
                                            {title}
                                        </div>
                                        <p className='redText'>
                                            {subtitle}
                                        </p>
                                        <div dangerouslySetInnerHTML={{ __html: body }}>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-6 p-2 '>
                                                <div className='img-how-to-sty'>
                                                    <img className='checkboxImages' src={imageSrc} />
                                                </div>
                                            </div>
                                            <div className='col-lg-6 p-2'>
                                                <p className='redText'>
                                                    {checkboxQuestion}
                                                </p>
                                                {
                                                    error ? (<div className="container mt-5">
                                                        <div className='row justify-content-center'>
                                                            <div className="alert alert-danger text-center" role="alert">
                                                                {error}
                                                            </div>
                                                        </div>
                                                    </div>)
                                                        :
                                                        checkboxeslist !== undefined && checkboxeslist.length !== 0 ? (
                                                            <div className="content-wrap">
                                                                {checkboxeslist.questionanxity.map((item, index) => {
                                                                    const card = (
                                                                        <div className="left-content checkbx-sty" key={index}>
                                                                            <InputGroup.Checkbox
                                                                                defaultChecked={item.checkbox_status}
                                                                                disabled={item.disable_status}
                                                                                aria-label="checkbox 1"
                                                                                value={item.question}
                                                                                name={item._id}
                                                                                selected={
                                                                                    Boolean(state[item.question]) &&
                                                                                    state[item.question] === item.question
                                                                                }
                                                                                onChange={handleChange}
                                                                            />
                                                                            {item.question}
                                                                        </div>
                                                                    )
                                                                    return card;
                                                                })}
                                                            </div>
                                                        )
                                                            :
                                                            <div className="loader-icon">
                                                                <TailSpin color="#4f5962" height={50} width={50} />
                                                            </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div>
                                        <FooterOfPrint />
                                    </div> */}
                                </div>

                                <div className='row'>
                                    <div className='previous1 col-6'>

                                        <Button
                                            className="user-reponse-submit btn btn-primary link-sty"
                                            onClick={previousPage}

                                        >
                                            {t("actions:previous")}
                                        </Button>
                                    </div>
                                    <div className='next col-6'>
                                        {checkboxeslist !== undefined && checkboxeslist.length !== 0 && checkboxeslist.global_disable_status === false ? (
                                            <>

                                                <span data-tip={disabled === true ? validationMessage : ""} data-for='toolTip'>

                                                    <Button
                                                        className="user-reponse-submit btn btn-primary link-sty"
                                                        onClick={postSubmission}
                                                        disabled={disabled}
                                                    >
                                                        {t("actions:continue")}
                                                    </Button>
                                                </span>
                                                <ReactTooltip id="toolTip" />
                                            </>
                                        ) :
                                            <>
                                                <Button
                                                    className="user-reponse-submit btn btn-primary link-sty"
                                                    onClick={nextPage}
                                                >
                                                    {t("actions:continue")}
                                                </Button>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* error message modal */}

                    <Modal show={showError} >
                        <Modal.Body>{errorMessage}</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={close}>
                                Close
                            </Button>

                        </Modal.Footer>
                    </Modal>
                    <div className="col-md-12 dashboard-footer">
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
};

export default CheckboxSituations;