import React, { useState } from "react";
import "../../../../../styles/adminlte.min.css";
import { Button } from "react-bootstrap";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

import Header from "../../../../global/Header";
import Sidebar from "../../../../global/Sidebar";
import Footer from "../../../../global/Footer";
import FooterOfPrint from "../../../../global/FootorOfPrint";
import { myprintFunction } from "../../../../../actions/NormalPrintFunction";
import { useTranslation } from "react-i18next";

const ChallengeAnswers = ({
  title,
  content,
  responses,
  link,
  type,
  error,
  previousLink,
}) => {
  const { t } = useTranslation(["partTwoChallengeAnswers", "actions"]);

  /*******   States *******/

  const [disabled, setDisabled] = useState(false);

  // error modal
  const [showError, setShowError] = useState(false);
  const handle = () => setShowError(true);
  let close = () => setShowError(false);

  /*******   Helper Functions  *******/

  let navigate = useNavigate();

  const postSubmission = () => {
    navigate(`/${link}`, { replace: true });
  };

  const previousPage = () => {
    navigate(`/${previousLink}`, { replace: true });
  };

  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer">
        <div>
          {/* <Header/> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row content-with-sidebar-row m-0">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-10 content-wrapper content-div">
            <div className="content">
              <div id="main">
                <div className="row header-titlewith-text m-0">
                  <div className="title col-md-4 p-0">{title}</div>
                  <div className="col-md-8 text-right p-0">
                    <span> {t("partTwoChallengeAnswers:title")}</span> / {title}
                  </div>
                </div>
                <div className="content-topheads-text">
                  <div className="note-textheads">
                    {t("partTwoChallengeAnswers:noteHeading")}
                  </div>
                </div>
                {error ? (
                  <div className="container mt-5">
                    <div className="row justify-content-center">
                      <div
                        className="alert alert-danger text-center"
                        role="alert"
                      >
                        {error}
                      </div>
                    </div>
                  </div>
                ) : responses !== undefined && responses.length !== 0 ? (
                  <div className="content-wrap">
                    <div className="content-topheads-text">
                      <p className="res-user-st">{responses.statement}</p>
                      <div className="thinkingEx">
                        {responses.user_responce.map((item, index) => {
                          const card = (
                            <div className="wrap" key={index}>
                              {index + 1 + "." + " " + item.question}
                              <div className="row">
                                <div className="col-lg-6">
                                  <table
                                    className="thinking_table left_table"
                                    border="0"
                                  >
                                    <tbody>
                                      <tr className="questions">
                                        <td>
                                          {t("partTwoChallengeAnswers:label1")}
                                          <br />
                                        </td>
                                      </tr>
                                      <tr className="answers">
                                        <td>{item.userRecord}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div className="col-lg-6">
                                  <table
                                    className="thinking_table left_table"
                                    border="0"
                                  >
                                    <tbody>
                                      <tr className="questions">
                                        <td>
                                          {t("partTwoChallengeAnswers:label2")}
                                          <br />
                                        </td>
                                      </tr>
                                      <tr className="answers">
                                        <td>{item.sample_answear}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          );
                          return card;
                        })}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="loader-icon">
                    <TailSpin color="#4f5962" height={50} width={50} />
                  </div>
                )}
                {/* <div>
                                    <FooterOfPrint />
                                </div> */}
              </div>
              <div className="row">
                <div className="previous col-6">
                  <Button
                    className="user-reponse-submit"
                    onClick={previousPage}
                  >
                    {t("actions:previous")}
                  </Button>
                </div>
                <div className="next col-6">
                  <Button
                    className="user-reponse-submit"
                    onClick={postSubmission}
                    disabled={disabled}
                  >
                    {t("actions:continue")}
                  </Button>
                </div>
              </div>
            </div>
          </div>

          {/* error message modal */} 

          {/* <Modal show={showError}>
            <Modal.Body>{error}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={close}>
                {t("actions:close")}
              </Button>
            </Modal.Footer>
          </Modal>

          <div className="col-md-12 dashboard-footer">
            <Footer />
          </div>  */}

          {/* error message modal */}

          <Modal show={showError}>
            <Modal.Body>{error}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={close}>
                {t("actions:close")}
              </Button>
            </Modal.Footer>
          </Modal>

          <div className="col-md-12 dashboard-footer">
            <Footer />


          </div>
        </div>
      </div>
    </>
  );
};

export default ChallengeAnswers;
