import { useTranslation } from 'react-i18next';

// const options = [
//     "Never Avoid",
//     "Infrequently Avoid",
//     "Sometimes Avoid",
//     "Frequently Avoid",
//     "Always Avoid"
// ];    

// export {
//     options
// };



const TranslatedOptions = () => {
  const { t } = useTranslation(["constantsPretreatmentAvoidanceQuestionnaire"]);

  const options = [
    t('options.never'),
    t('options.infrequently'),
    t('options.sometimes'),
    t('options.frequently'),
    t('options.always')
  ];

  return options;
};

export default TranslatedOptions;
