import React from 'react';
import { Helmet } from 'react-helmet';
import ContentPage from '../templates/ContentPages';
import { useTranslation } from 'react-i18next';

const Part7FifthPage = () => {

    const { t } = useTranslation(["partSevenFifthPage"])
    /*******  Page Params  *******/

    const title = t('partSevenFifthPage:title');

    const content = 
    `<div>
        <p>${t('partSevenFifthPage:content.line1')}</p>
        
        <p>${t('partSevenFifthPage:content.line2')}</p>
        
        <p>${t('partSevenFifthPage:content.line3')}</p>
    </div>`;
    
    /*******  Render  *******/

    return(
        <div  className='welcome-content'>
             <Helmet>
                <title>{t('partSevenFifthPage:helmet')}</title>
            </Helmet>
            <ContentPage 
                title={title}
                content={content}
                link='part_7/handouts'
                previousLink='part_7/ways_to_protect_yourself'
            />
        </div>        
    );
};

export default Part7FifthPage;