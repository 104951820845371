import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const user = JSON.parse(localStorage.getItem("user"));
const languageCode = localStorage.getItem("languageCode");
//  console.log()

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    lng:   user?.userInfo?.languageCode ? user.userInfo.languageCode : languageCode, // Set default language
    fallbackLng: "en", // Fallback language
    returnObjects: true,
    interpolation: {
      escapeValue: false, // React already escapes values
    },
  });
