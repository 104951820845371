import React from "react";
import { Helmet } from "react-helmet";
import InputForms from "../templates/InputForms/InputForms";
import { GetInputQuestions } from "../../../../services/part1/get-input-forms";
import { useTranslation } from "react-i18next";

const ThinkingExercise2 = () => {
  const { t } = useTranslation(["partOneThinkingExerciseTwo"]);
  const [inputQuestions, errorMessage] = GetInputQuestions(2); // Input Questions list from API

  /*******   Page Params  *******/

  const title = t("partOneThinkingExerciseTwo:title");

  const content = `<div>${t(
    "partOneThinkingExerciseTwo:description.line1"
  )}</div>`;

  const situation = t("partOneThinkingExerciseTwo:situation");
  console.log(situation)

  // const images = [
  //     "jane_anxious.jpg",
  //     "jane_angry.jpg",
  //     "jane_sad.jpg",
  //     "jane_happy.jpg"
  // ];

  const images = [
    "Jane_Anxious.png",
    "Jane_Angry.png",
    "Jane_sad.png",
    "Jane_Happy.png",
  ];
  /*******   Render  *******/

  return (
    <div>
      <Helmet>
        <title>{t("partOneThinkingExerciseTwo:helmet")}</title>
      </Helmet>
      <InputForms
        title={title}
        content={content}
        questions={inputQuestions}
        link="part_1/thinking_exercise_2_sample_answers"
        type={"create_Excercise2"}
        images={images}
        situation={situation}
        sitnum={2}
        error={errorMessage}
        previousLink="part_1/thinking_exercise_1_sample_answers"
      />
    </div>
  );
};

export default ThinkingExercise2;
