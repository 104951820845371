import React from "react";
import { Helmet } from "react-helmet";
import ChallengeAnswers from "../templates/Answers/ChallengeAnswers";
import { GetChallengeResponse } from "../../../../services/part2/get-challenge-response";
import { useTranslation } from "react-i18next";

const ChallengeResponse1 = () => {
  const { t } = useTranslation(["partTwoChallengeResponseOne"]);

  const [challengeResponse, errorMessage] = GetChallengeResponse("1"); // User Response from API

  /*******   Page Params  *******/

  const title = t("title");

  const content = t("content");

  /*******   Render  *******/

  return (
    <div className="welcome-content">
      <Helmet>
        <title>{t("helmet")}</title>
      </Helmet>
      <ChallengeAnswers
        title={title}
        content={content}
        responses={challengeResponse}
        link="part_2/challenge_2"
        error={errorMessage}
        previousLink="part_2/challenge_1"
      />
    </div>
  );
};

export default ChallengeResponse1;
