import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { logout } from "../../actions/auth";
import { printFunction } from '../../actions/PrintComponent';
import downloadMyGraph from '../../actions/DownloadGraph'
import { jsPDF } from "jspdf";
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import { useTranslation  } from 'react-i18next';

const Header = ({ targeted_print_component_id,myprintFunction }) => {
  let id = targeted_print_component_id;

  const {t} = useTranslation(['header']);

  const [active, setActive] = useState(false);

  /*******  Helper Functions  *******/

  // for modal
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  /*******  Helper Functions  *******/


  let navigate = useNavigate()

  const dispatch = useDispatch();

  // for logging out the user
  const logOut = () => {

    localStorage.setItem("userState", 'false');
    dispatch(logout());

    if (localStorage.getItem('userState') === 'false') {
      navigate('/');

    }
  };

  const sidebarState = () => {
    setActive(!active);
    if (active === false) {
      document.body.classList.remove('sidebar-closed');
      document.body.classList.remove('sidebar-collapse');
      document.body.classList.add('sidebar-open');
    } else {
      document.body.classList.remove('sidebar-open');
      document.body.classList.add('sidebar-closed');
      document.body.classList.add('sidebar-collapse');
    }
  };


  //Function to print Printable Content
  // const myprintFunction = () => {
  //   var realData = document.getElementById("main").innerHTML

  //   var secondData = document.getElementById("printAble").innerHTML
  //   document.getElementById('printAble').innerHTML = realData
  //   document.getElementById("mainContainer").style.display = "none";
  //   window.print();
  //   document.getElementById('main').innerHTML = realData;
  //   document.getElementById("printAble").innerHTML = secondData;

  //   // window.close()
  //   // document.getElementById("main").style.display = "block";
  //   document.getElementById("mainContainer").style.display = "block";

  // }
  // print funtion 
  // const myprintFunction = () => {
  //   console.log(dataSubmitted, "its data submitted value")
  //   var realData = document.getElementById("main").innerHTML

  //   var secondData = document.getElementById("printAble").innerHTML
  //   document.getElementById('printAble').innerHTML = realData
  //   document.getElementById("mainContainer").style.display = "none";
  //   window.print();
  //   document.getElementById("mainContainer").style.display = "block";
  //   document.getElementById("printAble").innerHTML = secondData;
  //   if (dataSubmitted === true) {
  //     document.getElementById('main').innerHTML = realData;
  //   }
  // }



  /*******  Render  *******/

  return (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      <ul className="navbar-nav">
        <li className="nav-item">
          <a className="nav-link" data-widget="pushmenu" href="#" role="button" onClick={sidebarState}><i className="fas fa-bars"></i></a>
        </li>
      </ul>

      <ul className="navbar-nav ml-auto">
        {/* <li className="nav-item">
              <a className="nav-link" data-widget="fullscreen" href="#" role="button">
                <i className="fa fa-expand"></i></a>
            </li> */}
        {/* <ReactToPrint id={id} /> */}
        <li className="nav-item">
          <a className="nav-link" data-widget="control-sidebar" data-slide="true" role="button" onClick={() => {
            if (id === "bargraph") {
              downloadMyGraph();
            }
            else {
              // printFunction(id)
              myprintFunction();
            }
          }}>
            <i className="fa fa-regular fa-print"></i></a>
        </li>
        <li className="nav-item">
          <a className="nav-link" data-widget="control-sidebar" data-slide="true" role="button" onClick={handleShow}>
            <i className="fa fa-power-off"></i></a>
        </li>

      </ul>
      <Modal show={show}>
        <Modal.Body>{t('header:body')}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
          {t('header:button.no')}
          </Button>
          <Button variant="primary" onClick={logOut}>
          {t('header:button.yes')}
          </Button>
        </Modal.Footer>
      </Modal>

    </nav>
  )



};

export default Header;