import React from "react";
import { Helmet } from "react-helmet";
import ExcerciseAnswers from "../templates/Answers/ExerciseAnswers";
import { GetUserResponse } from "../../../../services/part1/get-user-response";
import { useTranslation } from "react-i18next";

const ThinkingResponse2 = () => {
  const { t } = useTranslation(["partOneThinkingResponseTwo"]);
  const [userResponse, errorMessage] = GetUserResponse(2); // User Responses from API

  /*******   Page Params  *******/

  const title = t("partOneThinkingResponseTwo:title");

  const content = `<div>${t("partOneThinkingResponseTwo:content")}</div>`;

  /*******   Render  *******/

  return (
    <div>
      <Helmet>
        <title>{t("partOneThinkingResponseTwo:helmet")}</title>
      </Helmet>
      <ExcerciseAnswers
        title={title}
        content={content}
        responses={userResponse}
        link="part_1/what_is_a_causal_thought"
        type={"ThinkingResponse1"}
        sitnum={2}
        error={errorMessage}
        previousLink="part_1/thinking_exercise_2"
      />
    </div>
  );
};

export default ThinkingResponse2;
