import React from "react";
import { useLocation } from "react-router-dom";

import { DOMAIN_NAME } from "../../env/Baseurl";
import SelectOptions from "../../constants/global/Sidebar";
import SidebarAccordian from "./Accordion";
import { padding } from "@mui/system";
import { useTranslation } from "react-i18next";

const Sidebar = () => {

  const {
    items1,
    items2,
    items3,
    items4,
    items5,
    items6,
    items7,
    items8,
    items9,
  } = SelectOptions();

  const { t } = useTranslation(['sidebar']);
  const styles = {
    none: {
      display: "none",
    },
    show: {
      display: "block",
    },
  };

  const location = useLocation();

  /*******   Localstorage  *******/
  const moduleName = localStorage.getItem("moduleName");
  // const part1Accessed = localStorage.getItem('part1Accessed')
  // const part2Accessed = localStorage.getItem('part2Accessed')
  // const part3Accessed = localStorage.getItem('part3Accessed')
  // const part4Accessed = localStorage.getItem('part4Accessed')
  // const part5Accessed = localStorage.getItem('part5Accessed')
  // const part6Accessed = localStorage.getItem('part6Accessed')
  // const part7Accessed = localStorage.getItem('part7Accessed')
  // const postTreatmentAccess = localStorage.getItem('postTreatmentAccess')
  // console.log(part1Accessed, "its access or part 1")
  // console.log(part2Accessed, "its access or part 2")
  // console.log(part3Accessed, "its access or part 3")
  // console.log(part4Accessed, "its access or part 4")
  // console.log(part5Accessed, "its access or part 5")
  // console.log(part6Accessed, "its access or part 6")
  // console.log(part7Accessed, "its access or part 7")
  // console.log(postTreatmentAccess, "its access or postTreatmentAccess")

  console.log("modulename", moduleName);
  const userInfo = localStorage.getItem("user");
  const user = JSON.parse(userInfo);
  const languageCode =user.userInfo.languageCode;



  /*******   Render  *******/
  return (
    <div>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <div className="sidebar">
          <div className="user-panel mt-2 pb-3 d-flex">
            <div className="info">
              <img
                src={`${DOMAIN_NAME}images/${languageCode}/sidebar/top_sidebar.png`}
                alt="logo"
              ></img>
            </div>
          </div>
          <div className="user-panel d-flex name">
            <div className="info">
              <a href="#" className="d-block">
                {user.userInfo.Fname + " " + user.userInfo.Lname}
              </a>
            </div>
          </div>
          <div className="user-panel mt-2 pb-2 mb-2 d-flex">
            <div className="info">
              <div
                className={
                  moduleName === "Pre-Treatment"
                    ? "sidebar-menu-item active"
                    : "sidebar-menu-item"
                }
              >
                <SidebarAccordian
                  items={items1}
                  title={t('titles.title1')}
                  url={"/intro/welcome"}
                  expandedState={moduleName === "Pre-Treatment" ? true : false}
                  menuindex={1}
                />
              </div>
              <div
                className={
                  moduleName === "Part 1"
                    ? "sidebar-menu-item active"
                    : "sidebar-menu-item"
                }

                // style={(
                //   moduleName === "Part 1" || moduleName === "Part 2" || moduleName === "Part 3" || moduleName === "Part 4" || moduleName === "Part 5" || moduleName === "Part 6" || moduleName === "Part 7" || moduleName === "Post-Treatment") || (part1Accessed === "true") ? styles.show : styles.none}

                // style={(currentIndex.index > 7 ? styles.show : styles.none)}
              >
                <SidebarAccordian
                  items={items2}
                  title={t('titles.title2')}
                  url={"/part_1/anxiety_is_important_in_stuttering"}
                  expandedState={moduleName === "Part 1" ? true : false}
                  menuindex={8}
                />
              </div>
              <div
                className={
                  moduleName === "Part 2"
                    ? "sidebar-menu-item active"
                    : "sidebar-menu-item"
                }

                // style={(currentIndex.index>22 ? styles.show :styles.none  )}
                // style={(moduleName === "Part 2" || moduleName === "Part 3" || moduleName === "Part 4" || moduleName === "Part 5" || moduleName === "Part 6" || moduleName === "Part 7" || moduleName === "Post-Treatment") || (part2Accessed === "true") ? styles.show : styles.none}
              >
                <SidebarAccordian
                  items={items3}
                  title={t('titles.title3')}
                  url={"/part_2/challenge_example"}
                  expandedState={moduleName === "Part 2" ? true : false}
                  menuindex={23}
                />
              </div>
              <div
                className={
                  moduleName === "Part 3"
                    ? "sidebar-menu-item active"
                    : "sidebar-menu-item"
                }

                // style={(currentIndex.index>33 ? styles.show :styles.none  )}
                // style={(moduleName === "Part 3" || moduleName === "Part 4" || moduleName === "Part 5" || moduleName === "Part 6" || moduleName === "Part 7" || moduleName === "Post-Treatment") || (part3Accessed === "true") ? styles.show : styles.none}
              >
                <SidebarAccordian
                  items={items4}
                  title={t('titles.title4')}
                  url={"/part_3/intro"}
                  expandedState={moduleName === "Part 3" ? true : false}
                  menuindex={34}
                />
              </div>
              <div
                className={
                  moduleName === "Part 4"
                    ? "sidebar-menu-item active"
                    : "sidebar-menu-item"
                }

                // style={(currentIndex.index>46 ? styles.show :styles.none  )}
                //  style={(moduleName === "Part 4" || moduleName === "Part 5" || moduleName === "Part 6" || moduleName === "Part 7" || moduleName === "Post-Treatment") || (part4Accessed === "true") ? styles.show : styles.none}
              >
                <SidebarAccordian
                  items={items5}
                  title={t('titles.title5')}
                  url={"/part_4/intro"}
                  expandedState={moduleName === "Part 4" ? true : false}
                  menuindex={47}
                />
              </div>
              <div
                className={
                  moduleName === "Part 5"
                    ? "sidebar-menu-item active"
                    : "sidebar-menu-item"
                }
                // style={(currentIndex.index>59 ? styles.show :styles.none  )}

                //  style={(moduleName === "Part 5" || moduleName === "Part 6" || moduleName === "Part 7" || moduleName === "Post-Treatment") || (part5Accessed === "true") ? styles.show : styles.none}
              >
                <SidebarAccordian
                  items={items6}
                  title={t('titles.title6')}
                  url={"/part_5/essay"}
                  expandedState={moduleName === "Part 5" ? true : false}
                  menuindex={60}
                />
              </div>
              <div
                className={
                  moduleName === "Part 6"
                    ? "sidebar-menu-item active"
                    : "sidebar-menu-item"
                }
                // style={(currentIndex.index>65 ? styles.show :styles.none  )}

                // style={(moduleName === "Part 6" || moduleName === "Part 7" || moduleName === "Post-Treatment") || (part6Accessed === "true") ? styles.show : styles.none}
              >
                <SidebarAccordian
                  items={items7}
                  title={t('titles.title7')}
                  url={"/part_6/the_problem_of_self-conciousness"}
                  expandedState={moduleName === "Part 6" ? true : false}
                  menuindex={66}
                />
              </div>
              <div
                className={
                  moduleName === "Part 7"
                    ? "sidebar-menu-item active"
                    : "sidebar-menu-item"
                }
                // style={(currentIndex.index>72 ? styles.show :styles.none  )}

                //  style={(moduleName === "Part 7" || moduleName === "Post-Treatment") || (part7Accessed === "true") ? styles.show : styles.none}
              >
                <SidebarAccordian
                  items={items8}
                  title={t('titles.title8')}
                  url={"/part_7/preventing_relapse"}
                  expandedState={moduleName === "Part 7" ? true : false}
                  menuindex={73}
                />
              </div>
              <div
                className={
                  moduleName === "Post-Treatment"
                    ? "sidebar-menu-item active"
                    : "sidebar-menu-item"
                }
                // style={(currentIndex.index>78 ? styles.show :styles.none  )}

                // style={(moduleName === "Post-Treatment") || (postTreatmentAccess === "true") ? styles.show : styles.none}
              >
                <SidebarAccordian
                  items={items9}
                  title={t('titles.title9')}
                  url={"/part_7/utbas_questionnaire"}
                  expandedState={moduleName === "Post-Treatment" ? true : false}
                  menuindex={79}
                />
              </div>
            </div>
          </div>
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;
