import React from 'react';
import { Helmet } from 'react-helmet';
import Questionnaire from '../templates/Questionnaire/Mcq';
import { options } from '../../../../constants/pretreatment/DassQuestionnaire';
import { GetPostTreatmentQuestionnaire } from '../../../../services/post-treatment/get-post-treatment-questionnaire';
import { useTranslation } from 'react-i18next';

const PostDassQuestionnaire = () => {

    const { t } = useTranslation(["posttreatmentPostDassQuestionnaire"])

    const [questionnaire, errorMessage] = GetPostTreatmentQuestionnaire("DASS");         // Get Questionnaire from API

    /*******  Page Params  *******/

    const title = t('posttreatmentPostDassQuestionnaire:title');

    const content = 
    `<div>
        ${t('posttreatmentPostDassQuestionnaire:content.line1')}

    <div>
    ${t('posttreatmentPostDassQuestionnaire:content.line2')}
    </div>

    <div>
    ${t('posttreatmentPostDassQuestionnaire:content.line3')}
    </div> 

    <div>
    ${t('posttreatmentPostDassQuestionnaire:content.line4')}
    </div>

    <div>
    ${t('posttreatmentPostDassQuestionnaire:content.line5')}
    </div>`;
    
    /*******  Render  *******/

    return(
        <div>
               <Helmet>
                <title>{t('posttreatmentPostDassQuestionnaire:helmet')}</title>
            </Helmet>
            <Questionnaire 
                title={title}
                content={content}
                link='part_7/fne_questionnaire'
                questions={questionnaire}
                options={options}
                type={'dass'}
                error={errorMessage}
                previousLink='part_7/avoidance_questionnaire'
            />
        </div>
    );
};

export default PostDassQuestionnaire;