import React from 'react';
import { Helmet } from 'react-helmet';
import ContentPage from '../templates/ContentPages';
import { useTranslation } from 'react-i18next';

const Part7SecondPage = () => {

    const { t } = useTranslation(["partSevenSecondPage"])

    /*******  Page Params  *******/

    const title = t('partSevenSecondPage:title');

    const content = 
    `<div>
        <p class='paragraText'>${t('partSevenSecondPage:content.line1')}</p>

        <p>${t('partSevenSecondPage:content.line2')}</p>

        <p>${t('partSevenSecondPage:content.line3')}</p>

        <p>${t('partSevenSecondPage:content.line4')}</p>

      </div>`;
    
    /*******  Render  *******/

    return(
        <div  className='welcome-content'>
             <Helmet>
                <title>{t('partSevenSecondPage:helmet')}</title>
            </Helmet>
            <ContentPage 
                title={title}
                content={content}
                link='part_7/postevent_rumination'
                previousLink= 'part_7/preventing_relapse'
            />
        </div>        
    );
};

export default Part7SecondPage;