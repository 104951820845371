import React, { useState, useEffect } from 'react';
import { Button, InputGroup } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { TailSpin } from 'react-loader-spinner';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import { Helmet } from 'react-helmet';

import { BASE_URL } from '../../../../env/Baseurl';
import ErrorLogging from '../../../../services/error-logs';
import '../../../../styles/adminlte.min.css';
import '../../../../styles/dashboard-style.css';
import Header from '../../../global/Header';
import Sidebar from '../../../global/Sidebar';
import Footer from '../../../global/Footer';
import { GetPredictionSituation, GetPredictionQuestion } from '../../../../services/part4/predictions-situation-task';
import RefreshToken from '../../../../services/refresh-token';
import FooterOfPrint from '../../../global/FootorOfPrint';
import { useTranslation } from 'react-i18next';


const Part4FifthPage = () => {

    /*******   Multi-Language Translation *******/
    const {t} = useTranslation(["partFourFifthPage", "actions"])

    const [predictionSituation, errorMessage] = GetPredictionSituation();                               // Get Prediction Situations from api
    const [predictionQuestions, errorPredictionMessage] = GetPredictionQuestion();                      // Get Prediction Questions from api

    /*******   States *******/

    const [state, setState] = useState([]);
    const [disabled, setDisabled] = useState(true);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorApiMessage, setErrorMessage] = useState('');

    // error modal
    const [showError, setShowError] = useState(false);
    const handle = () => setShowError(true);
    let close = () => setShowError(false);

    /*******   Helper Functions  *******/

    let navigate = useNavigate();

    const handleChange = (e) => {

        const { name, value } = e.target;

        if (state.some(item => item.predictions_id === name)) {

            setState(
                state.filter(state => state.predictions_id !== name)
            );

        } else {
            setState([...state, { "predictions_id": name }]
            );
        }

        if (state.length === 0) {
            setDisabled(true);

        }

    };


    const postSubmission = async () => {

        const userInfo = localStorage.getItem('user');
        const user = JSON.parse(userInfo);

        try {
            const params = JSON.stringify({
                "data": state
            });
            const response = await axios.post(BASE_URL + `predictions_userresponce/${user.userInfo._id}`, params, {
                "headers": {
                    "Authorization": `Bearer ${user.token}`,
                    "content-type": "application/json",
                    "languageCode": user.userInfo.languageCode,
                },
            })
            setSuccessMessage(response.data);
            if (state.length !== 0) {
                navigate('/part_4/prediction_probabilities', { replace: true })
            } else {
                alert('Please select atleast one of the options.');
            }
        } catch (error) {
            if (error.response.status === 401) {
                RefreshToken(user.userInfo._id)
                postSubmission();
            }
            else {
                setErrorMessage('Please check Part 4 Prediction Userresponse Api');
                ErrorLogging('HTTP POST Part 4 /predictions_userresponce/userId' + error);
                handle();
            }

        }

    };

    const nextPage = () => {
        navigate('/part_4/prediction_probabilities', { replace: true })
    };


    const previousPage = () => {
        navigate(`/part_4/time_to_plan`, { replace: true })

    };
    /*******   Hooks  *******/

    useEffect(() => {

        if (state.length === 3) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }

    }, [state]);



    //print function
    const myprintFunction = () => {
        var realData = document.getElementById("main").innerHTML

        var secondData = document.getElementById("printAble").innerHTML
        document.getElementById('printAble').innerHTML = realData
        document.getElementById("mainContainer").style.display = "none";
        // document.getElementById("printFooter").style.display = "block";
        window.print();
        document.getElementById("mainContainer").style.display = "block";
        document.getElementById("printAble").innerHTML = secondData;
        // if (dataSubmitted === true) {
        //     document.getElementById('main').innerHTML = realData;
        // }
        if (predictionQuestions !== undefined && predictionQuestions.length !== 0 && predictionQuestions.global_disable_status === false) {

        }
        else {
            document.getElementById('main').innerHTML = realData;
        }
    }


    /*******   Render  *******/

    return (
        <>
            <div id="printAble"></div>
            <div id="mainContainer" className="dashboardContent">
                <Helmet>
                    <title>{t('partFourFifthPage:helmet')}</title>
                </Helmet>
                <div>
                    {/* <Header/> */}
                    <Header targeted_print_component_id={"main"} myprintFunction={myprintFunction} />
                </div>
                <div className="row">
                    <div className="col-md-2  position-static pl-0">
                        <div className="leftside">
                            <Sidebar />
                        </div>
                    </div>
                    <div className="col-md-10 content-widthsidebar content-wrapper">
                        <div className="card">
                            <div className="card-body">
                                <div id="main">
                                    <div className="card-body">
                                        <div className="title mb-3">
                                        {t('partFourFifthPage:title')}
                                        </div>
                                        <p>
                                            <span className='redText'>
                                            {t('partFourFifthPage:content.line1')} </span>{predictionSituation.length !== 0 && <>{predictionSituation.situationtask_data.situation}</>}
                                        </p>
                                        <p>
                                            <span className='redText'>
                                            {t('partFourFifthPage:content.line2')} </span> {predictionSituation.length !== 0 && <>{predictionSituation.situationtask_data.task}</>}
                                        </p>
                                        <p className='redText'>
                                        {t('partFourFifthPage:content.line3')}
                                        </p>
                                        <ul>
                                            {
                                                errorMessage ? (<div className="container mt-5">
                                                    <div className='row justify-content-center'>
                                                        <div className="alert alert-danger text-center" role="alert">
                                                            {errorMessage}
                                                        </div>
                                                    </div>
                                                </div>)
                                                    :
                                                    predictionSituation !== undefined && predictionSituation.length !== 0 ? (
                                                        <div>
                                                            {predictionSituation.safty_behaviour_data.map((item, index) => {
                                                                const card = (
                                                                    <li key={index}>{item.question}</li>
                                                                )
                                                                return card;
                                                            })}
                                                        </div>
                                                    )
                                                        :
                                                        <div className="loader-icon">
                                                            <TailSpin color="#4f5962" height={50} width={50} />
                                                        </div>
                                            }
                                        </ul>
                                        <p className='redText'>
                                        {t('partFourFifthPage:content.line4')}
                                        </p>
                                        <div className='col-lg-6'>
                                            {
                                                errorPredictionMessage ? (<div className="container mt-5">
                                                    <div className='row justify-content-center'>
                                                        <div className="alert alert-danger text-center" role="alert">
                                                            {errorPredictionMessage}
                                                        </div>
                                                    </div>
                                                </div>)
                                                    :
                                                    predictionQuestions !== undefined && predictionQuestions.length !== 0 ? (
                                                        <div className="content-wrap">
                                                            {predictionQuestions.Predections.map((item, index) => {
                                                                const card = (
                                                                    <div className="left-content checkbx-sty" key={index}>
                                                                        <InputGroup.Checkbox
                                                                            defaultChecked={item.responce}
                                                                            disabled={item.disable_status}
                                                                            aria-label="checkbox 1"
                                                                            value={item._id}
                                                                            name={item._id}
                                                                            selected={
                                                                                Boolean(state[item.predictions_question]) &&
                                                                                state[item.predictions_question] === item.predictions_question
                                                                            }
                                                                            onChange={handleChange}
                                                                        />
                                                                        {item.predictions_question}
                                                                    </div>
                                                                )
                                                                return card;
                                                            })}
                                                        </div>
                                                    )
                                                        :
                                                        <div className="loader-icon">
                                                            <TailSpin color="#4f5962" height={50} width={50} />
                                                        </div>
                                            }
                                        </div>
                                    </div>
                                    {/* <div>
                                        <FooterOfPrint />
                                    </div> */}
                                </div>


                                <div className='row'>
                                    <div className='previous1 col-6'>

                                        <Button
                                            className="user-reponse-submit btn btn-primary link-sty"
                                            onClick={previousPage}

                                        >
                                            {t('actions:previous')}
                                        </Button>
                                    </div>
                                    <div className='next col-6'>
                                        {predictionQuestions !== undefined && predictionQuestions.length !== 0 && predictionQuestions.global_disable_status === false ? (
                                            <>
                                                <span data-tip={disabled === true ? t('partFourFifthPage:toolTipText') : ""} data-for='toolTip'>
                                                    <Button
                                                        className="user-reponse-submit btn btn-primary link-sty mt-3"
                                                        onClick={postSubmission}
                                                        disabled={disabled}
                                                    >
                                                         {t('actions:continue')}
                                                    </Button>
                                                </span>
                                                <ReactTooltip id="toolTip" />
                                            </>
                                        ) :
                                            <>
                                                <Button
                                                    className="user-reponse-submit btn btn-primary link-sty"
                                                    onClick={nextPage}
                                                >
                                                    {t('actions:continue')}
                                                </Button>
                                            </>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* error message modal */}

                    <Modal show={showError} >
                        <Modal.Body>{errorApiMessage}</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={close}>
                            {t('actions:close')}
                            </Button>

                        </Modal.Footer>
                    </Modal>
                    <div className="col-md-12 dashboard-footer">
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Part4FifthPage;