import React, { useState } from 'react';
import { Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner';
import Modal from 'react-bootstrap/Modal';
import { Helmet } from 'react-helmet';
import '../../../../styles/adminlte.min.css';
import '../../../../styles/dashboard-style.css';
import Header from '../../../global/Header';
import Sidebar from '../../../global/Sidebar';
import Footer from '../../../global/Footer';
import { GetBehaviouralThoughtAnswers } from '../../../../services/part4/behavioural-causal-thought';
import { myprintFunction } from '../../../../actions/NormalPrintFunction';
import FooterOfPrint from '../../../global/FootorOfPrint';
import { useTranslation } from 'react-i18next';



const Part4EleventhPage = () => {

    const { t } = useTranslation(["partFourEleventhPage", "actions"])

    const [behaviouralCausalAnswers, errorAnswerMessage] = GetBehaviouralThoughtAnswers();   // Get Behavioural Answers info from api

    /*******   States *******/

    const [disabled, setDisabled] = useState(false);
    // error modal
    const [showError, setShowError] = useState(false);
    const handle = () => setShowError(true);
    let close = () => setShowError(false);

    /*******   Helper Functions  *******/

    let navigate = useNavigate();

    const postSubmission = () => {

        navigate('/part_4/what_was_learned', { replace: true })

    };
    const previousPage = () => {

        navigate(`/part_4/behavioural_causal_thought`, { replace: true })

    };


    /*******   Render  *******/

    return (
        <>
            <div id="printAble"></div>
            <div id="mainContainer" className="dashboardContent">
                <Helmet>
                    <title>{t('partFourEleventhPage:helmet')}</title>
                </Helmet>
                <div>
                    {/* <Header/> */}
                    <Header targeted_print_component_id={"main"}  myprintFunction={myprintFunction} />
                </div>
                <div className="row">
                    <div className="col-md-2  position-static pl-0">
                        <div className="leftside">
                            <Sidebar />
                        </div>
                    </div>
                    <div className="col-md-10 content-wrapper content-div btn-show">
                        <div className="content">
                            <div id="main">
                                <div className="row header-titlewith-text m-0">
                                    <div className="title col-md-6 p-0">
                                    {t('partFourEleventhPage:title')}
                                    </div>
                                    <div className="col-md-6 text-right p-0">
                                        <span> {t('partFourEleventhPage:content.line1')}</span> {t('partFourEleventhPage:content.line2')}
                                    </div>
                                </div>
                                <div className="content-topheads-text">
                                    <div className="note-textheads">
                                    {t('partFourEleventhPage:content.line3')}
                                    </div>
                                </div>
                                {
                                    errorAnswerMessage ? (<div className="container mt-5">
                                        <div className='row justify-content-center'>
                                            <div className="alert alert-danger text-center" role="alert">
                                                {errorAnswerMessage}
                                            </div>
                                        </div>
                                    </div>)
                                        :
                                        behaviouralCausalAnswers !== undefined && behaviouralCausalAnswers.length !== 0 ? (
                                            <div className="content-wrap">
                                                <div className="content-topheads-text">
                                                    <p className='res-user-st'>
                                                        {behaviouralCausalAnswers.utbus_data.statement}
                                                    </p>
                                                    <div className='thinkingEx'>
                                                        {behaviouralCausalAnswers.user_responce.map((item, index) => {
                                                            const card = (
                                                                <div className='wrap' key={index}>
                                                                    {(index + 1) + "." + " " + item.question}
                                                                    <div className='row'>
                                                                        <div className='col-lg-6'>
                                                                            <table className="thinking_table left_table" border="0">
                                                                                <tbody>
                                                                                    <tr className="questions">
                                                                                        <td>
                                                                                        {t('partFourEleventhPage:content.line4')}<br />
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr className="answers">
                                                                                        <td>
                                                                                            {item.userRecord}
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                        <div className='col-lg-6'>
                                                                            <table className="thinking_table left_table" border="0">
                                                                                <tbody>
                                                                                    <tr className="questions">
                                                                                        <td>
                                                                                        {t('partFourEleventhPage:content.line5')}<br />
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr className="answers">
                                                                                        <td>
                                                                                            {item.sample_answear}
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                            return card;
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                            :
                                            <div className="loader-icon">
                                                <TailSpin color="#4f5962" height={50} width={50} />
                                            </div>
                                }
                                {/* <div>
                                    <FooterOfPrint/>
                                </div> */}
                            </div>
                            <div className='row'>
                                <div className='previous1 col-6'>

                                    <Button
                                        className="user-reponse-submit"
                                        onClick={previousPage}

                                    >
                                        {t('actions:previous')}
                                    </Button>
                                </div>
                                <div className='next col-6'>
                                    <Button
                                        className="user-reponse-submit"
                                        onClick={postSubmission}
                                        disabled={disabled}
                                    >
                                        {t('actions:continue')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 dashboard-footer">
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Part4EleventhPage;