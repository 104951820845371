import { useEffect, useState} from 'react';
import axios from "axios";

import { BASE_URL } from '../../env/Baseurl';
import ErrorLogging from '../error-logs';
import RefreshToken from '../refresh-token';

const GetWhatWasLearned =  () => {
    
    const [whatWasLearned, setWhatWasLearned] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const fetchWhatWasLearned = async () => {

        const userInfo = localStorage.getItem('user');
        const user = JSON.parse(userInfo);
    
        try{
               const response = await axios.get(BASE_URL + `What_did_I_learn_from_this_experiment/${user.userInfo._id}`, {
                "headers": {
                "Authorization": `Bearer ${user.token}`,
                "content-type": "application/json",
                "languageCode": user.userInfo.languageCode,
                },   
            })
            setWhatWasLearned(response.data);
        } catch(error){
            if (error.response.status === 401) {
                RefreshToken(user.userInfo._id)
                fetchWhatWasLearned();
            }
            else if(error.response.status === 403 || error.response.status === 500 || error.response.status === 503)
            {
            setErrorMessage('Internal Server Error, Please Try After Sometime!');
            ErrorLogging('HTTP GET /What_did_I_learn_from_this_experiment/userId' + error);
            }
            else{
                setErrorMessage('There is some error, please try again later.');
                ErrorLogging('HTTP GET /What_did_I_learn_from_this_experiment/userId' + error);
            }
        }     
    };

    useEffect(() => {
        fetchWhatWasLearned();
    }, []);
  
  return [whatWasLearned, errorMessage];
};


const GetWhatWasLearnedFlag =  () => {
    
    const [whatWasLearnedFlag, setWhatWasLearnedFlag] = useState([]);
    const [errorMessageFlag, setErrorMessage] = useState('');

    const fetchWhatWasLearnedFlag = async () => {

        const userInfo = localStorage.getItem('user');
        const user = JSON.parse(userInfo);
    
        try{
               const response = await axios.get(BASE_URL + `part4_getflag_status/${user.userInfo._id}`, {
                "headers": {
                "Authorization": `Bearer ${user.token}`,
                "content-type": "application/json",
                "languageCode": user.userInfo.languageCode,
                },   
            })
            setWhatWasLearnedFlag(response.data);
        } catch(error){
            if (error.response.status === 401) {
                RefreshToken(user.userInfo._id)
                fetchWhatWasLearnedFlag();
            }
            else if(error.response.status === 403 || error.response.status === 500 || error.response.status === 503)
            {
            setErrorMessage('Internal Server Error, Please Try After Sometime!');
            ErrorLogging('HTTP GET /part4_getflag_status/userId' + error);
            }   
            else{
                setErrorMessage('There is some error, please try again later.');
                ErrorLogging('HTTP GET /part4_getflag_status/userId' + error);
            }
        }     
    };

    useEffect(() => {
        fetchWhatWasLearnedFlag();
    }, []);
  
  return [whatWasLearnedFlag, errorMessageFlag];
};


export {
    GetWhatWasLearned,
    GetWhatWasLearnedFlag
}