import React from 'react';
import { Helmet } from 'react-helmet';
import Questionnaire from '../templates/Questionnaire/Mcq';
import TranslatedOptions from '../../../../constants/pretreatment/FneQuestionnaire';
import { GetPostTreatmentQuestionnaire } from '../../../../services/post-treatment/get-post-treatment-questionnaire';
import { useTranslation } from 'react-i18next';

const PostFneQuestionnaire = () => {

    const { t } = useTranslation(["posttreatmentPostFneQuestionnaire"])

    const options = TranslatedOptions();

    const [questionnaire, errorMessage] = GetPostTreatmentQuestionnaire("BFNE");          // Get Questionnaire from API

    /*******  Page Params  *******/

    const title = t('posttreatmentPostFneQuestionnaire:title');

    const content = `<div>${t('posttreatmentPostFneQuestionnaire:content.line1')}</div>`;

    /*******  Render  *******/

    return (
        <div>
            <Helmet>
                <title>{t('posttreatmentPostFneQuestionnaire:helmet')}</title>
            </Helmet>
            <Questionnaire
                title={title}
                content={content}
                link='part_7/complete'
                questions={questionnaire}
                options={options}
                type={'bfne'}
                error={errorMessage}
                previousLink='part_7/dass_questionnaire'
            />
        </div>
    );

};

export default PostFneQuestionnaire;