import { useTranslation } from "react-i18next";

const SelectOptions = () => {
  const { t } = useTranslation(["partOneChecklistCognitiveErrors"]);

  const selectOptions1 = [
    {
      value: `<span className='redText'>${t(
        "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text1"
      )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,

      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label1"
      ),
    },
    {
      value: `<span className="redText">${t(
        "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text2"
      )}</span> ${t(
        "partOneChecklistCognitiveErrors:selectOptions.correctCognitive.option1.value2"
      )}`,

      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label2"
      ),
    },
    {
      value: `<span className='redText'>${t(
        "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text3"
      )}</span> ${t(
        "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
      )}`,

      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label3"
      ),
    },
    {
      value: `<span className="redText">${t(
        "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text4"
      )}</span> ${t(
        "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
      )}`,

      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label4"
      ),
    },
    {
      value: `<span className="redText">${t(
        "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text5"
      )}</span> ${t(
        "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
      )}`,

      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label5"
      ),
    },
    {
      value: `<span className="redText">${t(
        "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text6"
      )}</span> ${t(
        "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
      )}`,

      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label6"
      ),
    },
    {
      value: `<span className='redText'>${t(
        "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text7"
      )}</span> ${t(
        "partOneChecklistCognitiveErrors:selectOptions:correctCognitive:option1.value7"
      )}`,

      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label7"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text8"
        )}</span>${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,

      label:t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label8"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text9"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,

      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label9"
      ),
    },
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text10"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions:correctCognitive:option1.value10"
        )}`,

      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label10"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text11"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,

      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label11"
      ),
    },
  ];

  const selectOptions2 = [
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text1"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label1"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text2"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label2"
      ),
    },
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text3"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions:correctCognitive:option2.value3"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label3"
      ),
    },
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text4"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions:correctCognitive:option2.value4"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label4"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text5"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )} `,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label5"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text6"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label6"
      ),
    },
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text7"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label:t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label7"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text8"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label8"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text9"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label9"
      ),
    },
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text10"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label10"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text11"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label11"
      ),
    },
  ];

  const selectOptions3 = [
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text1"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label1"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text2"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label2"
      ),
    },
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text3"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label3"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text4"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label4"
      ),
    },
    {
      value:
        `<span className='redText'> ${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text5"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions:correctCognitive:option3.value5"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label5"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text6"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label6"
      ),
    },
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text7"
        )}/span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label7"
      ),
    },
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text8"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions:correctCognitive:option3.value8"
        )} `,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label8"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text9"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label9"
      ),
    },
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text10"
        )}</span>${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label11"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text11"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label11"
      ),
    },
  ];

  const selectOptions4 = [
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text1"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions:correctCognitive:option4.value1"
        )}`,
      label:t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label1"
      ),
    },
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text2"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions:correctCognitive:option4.value2"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label2"
      ),
    },
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text3"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label3"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text4"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label4"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text5"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label5"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text6"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label6"
      ),
    },
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text7"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label7"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text8"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label8"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text9"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label9"
      ),
    },
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text10"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label10"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text11"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label11"
      ),
    },
  ];

  const selectOptions5 = [
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text1"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )} `,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label1"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text2"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label2"
      ),
    },
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text3"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label3"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text4"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )} `,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label4"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text5"
        )} </span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions:correctCognitive:option5.value5"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label5"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text6"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label6"
      ),
    },
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text7"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label7"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text8"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label8"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text9"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label9"
      ),
    },
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text10"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label10"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text11"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label11"
      ),
    },
  ];

  const selectOptions6 = [
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text1"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions:correctCognitive:option6.value1"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label1"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text2"
        )}.</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label:t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label2"
      ),
    },
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text3"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label3"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text4"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label4"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text5"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label5"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text6"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label6"
      ),
    },
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text7"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label7"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text8"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label8"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text9"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label9"
      ),
    },
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text10"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label10"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text11"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label11"
      ),
    },
  ];

  const selectOptions7 = [
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text1"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label1"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text2"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label2"
      ),
    },
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text3"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label3"
      ),
    },
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text4"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions:correctCognitive:option7.value4"
        )}`,
      label:t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label4"
      ),
    },
    {
      value:
        `<span className="redText">J${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text5"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions:correctCognitive:option7.value5"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label5"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text6"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label6"
      ),
    },
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text7"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label7"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text8"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label8"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text9"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label9"
      ),
    },
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text10"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label10"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text11"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label11"
      ),
    },
  ];

  const selectOptions8 = [
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text1"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label1"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text2"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label2"
      ),
    },
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text3"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label3"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text4"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label4"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text5"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label5"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text6"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions:correctCognitive:option8.value6"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label6"
      ),
    },
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text7"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label7"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text8"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label8"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text9"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label9"
      ),
    },
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text10"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label10"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text11"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label11"
      ),
    },
  ];

  const selectOptions9 = [
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text1"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label1"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text2"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label2"
      ),
    },
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text3"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label3"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text4"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label4"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text5"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label5"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text6"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label6"
      ),
    },
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text7"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label7"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text8"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label8"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text9"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label9"
      ),
    },
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text10"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label10"
      ),
    },
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text11"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions:correctCognitive:option9.value11"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label11"
      ),
    },
  ];

  const selectOptions10 = [
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text1"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label1"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text2"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label2"
      ),
    },
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text3"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label3"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text4"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label4"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text5"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label5"
      ),
    },
    {
      value:
        `<span className="redText">J${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text6"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label6"
      ),
    },
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text7"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions:correctCognitive:option10.value7"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label7"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text8"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label8"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text9"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label9"
      ),
    },
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text10"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions:correctCognitive:option10.value10"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label10"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text11"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label11"
      ),
    },
  ];

  const selectOptions11 = [
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text1"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label1"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text2"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label2"
      ),
    },
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text3"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label3"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text4"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label4"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text5"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label5"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text6"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label6"
      ),
    },
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text7"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label7"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text8"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label8"
      ),
    },
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text9"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions:correctCognitive:option11.value9"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label9"
      ),
    },
    {
      value:
        `<span className='redText'>${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text10"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label10"
      ),
    },
    {
      value:
        `<span className="redText">${t(
          "partOneChecklistCognitiveErrors:selectOptions:valueRedText.text11"
        )}</span> ${t(
          "partOneChecklistCognitiveErrors:selectOptions.notCorrectCognitive"
        )}`,
      label: t(
        "partOneChecklistCognitiveErrors:selectOptions:labels.label11"
      ),
    },
  ];

  return {
    selectOptions1,
    selectOptions2,
    selectOptions3,
    selectOptions4,
    selectOptions5,
    selectOptions6,
    selectOptions7,
    selectOptions8,
    selectOptions9,
    selectOptions10,
    selectOptions11,
  };
};

export default SelectOptions;
