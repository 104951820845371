import React from "react";
import { Helmet } from "react-helmet";
import Questionnaire from "../templates/Questionnaire/Mcq";
import TranslatedOptions from "../../../../constants/pretreatment/UtbasQuestionnaire";
import { GetQuestionnaire } from "../../../../services/pretreatment/get-questionnaire";
import { useTranslation } from "react-i18next";

const UtbasQuestionnaire = () => {
  const { t } = useTranslation(["utbasQuestionnaire","constantsPretreatmentUtbsQuestionnaire"]);
  const [questionnaire, errorMessage] = GetQuestionnaire("UTBAS"); // Questions list from API

  /*******   Page Params  *******/

  const title = t("title");

  const content = `<div>${t("description.line1")}</div>
    <div>${t("description.line2")}</div>`;

    const options = TranslatedOptions();

// we can make a json file for each option and remove the constanat array import from these so language get dynamically changed

    // const options = [
    //   t("options.never"),
    //   t("options.rarely"),
    //   t("options.sometimes"),
    //   t("options.often"),
    //   t("options.always")
    // ];

  /*******   Render  *******/

  return (
    <div>
      <Helmet>
        <title>{t("helmet")}</title>
      </Helmet>
      <Questionnaire
        title={title}
        content={content}
        link="intro/avoidance_questionnaire"
        questions={questionnaire}
        options={options}
        type={"UTBAS"}
        error={errorMessage}
        previousLink="intro/how_to"
      />
    </div>
  );
};

export default UtbasQuestionnaire;
