import React from "react";
import { Helmet } from "react-helmet";
import ContentPage from "../templates/ContentPages";
import { DOMAIN_NAME } from "../../../../env/Baseurl";
import { useTranslation } from "react-i18next";

const HowToUse = () => {
  const { t } = useTranslation(["howtouse"]);


  // ============Local Storage ==========================

  const userInfo = localStorage.getItem('user'); 
  const user = JSON.parse(userInfo);
  const languageCode =user.userInfo.languageCode;

  /*******   Page Params  *******/

  const title = t("title");

  const content = `
    <div className='redText'>${t("description.line1")}</div>
    <div><p><span className='redText'>${t("description.line2")}</span>
    ${t("description.line3")}</p>
    <div class='imghowtosty'><img src=${DOMAIN_NAME}images/${languageCode}/pretreatment/audio_player_play.png
  )}></img></div>
    <div><p>${t("description.line4")}</p>
    <div class='imghowtosty'><img src=${DOMAIN_NAME}images/${languageCode}/pretreatment/audio_player_stop.png
  )}></img></div></div>
    <p> <span className='redText'>${t("description.line5")}</span> ${t(
    "description.line6"
  )}</p>
    <p><div class='imghowtosty'><img src=${DOMAIN_NAME}images/${languageCode}/pretreatment/module_progressbar.png
  )}></img></div></p>
    <p><div class='imghowtosty'><img src=${DOMAIN_NAME}images/${languageCode}/pretreatment/program_programbar.png
  )}></img></div></p>
    <p>${t("description.line7")}</p> 
    <p><span className='redText'>${t("description.line8")}</p>
    <p>${t("description.line9")}<p><p><span className='redText'>${t(
    "description.line10"
  )}</span></p> <p>${t("description.line11")}</p>`;

  /*******   Render  *******/

  return (
    <div className="welcome-content">
      <Helmet>
        <title>{t("helmet")}</title>
      </Helmet>
      <ContentPage
        title={title}
        content={content}
        link="intro/utbas_questionnaire"
        previousLink="intro/welcome"
      />
    </div>
  );
};

export default HowToUse;
