import React from 'react';
import { Helmet } from 'react-helmet';
import CheckboxSituations from '../templates/CheckboxSituations';
import { GetCheckboxes } from '../../../../services/part3/get-checkboxes';
import { DOMAIN_NAME } from '../../../../env/Baseurl';
import { useTranslation } from 'react-i18next';


const SafetyBehaviours = () => {
    const { t } = useTranslation(['actions', 'partThreeSafetyBehaviours']);


    const [checkboxes, errorMessage] = GetCheckboxes('behaviour');       // Checkboxes list from API

    // ============Local Storage ==========================

    const userInfo = localStorage.getItem('user'); 
    const user = JSON.parse(userInfo);
    const languageCode =user.userInfo.languageCode;


    /*******   Page Params  *******/

    const title = t('partThreeSafetyBehaviours:helmet');

    const subtitle = t('partThreeSafetyBehaviours:subtitle');

    const body = `<p>${t('partThreeSafetyBehaviours:content.line1')}</p><p>${t('partThreeSafetyBehaviours:content.line2')}</p>`;

    const checkboxQuestion = t('partThreeSafetyBehaviours:content.checkboxQuestion');
    const link = "/part_3/physiological_anxiety_symptoms";

    const validationMessage = t('partThreeSafetyBehaviours:content.validationMessage');

    const imageSrc = `${DOMAIN_NAME}images/${languageCode}/part3/safety.png`;

    /*******   Render  *******/

    return (
        <>
            <Helmet>
                <title> {t('partThreeSafetyBehaviours:title')}</title>
            </Helmet>
            <CheckboxSituations
                title={title}
                subtitle={subtitle}
                body={body}
                checkboxQuestion={checkboxQuestion}
                link={link}
                checkboxeslist={checkboxes}
                type={'create_behiviour'}
                validationMessage={validationMessage}
                imageSrc={imageSrc}
                error={errorMessage}
                previousLink='part_3/more_safety_behaviours'
            />
        </>
    );
};

export default SafetyBehaviours;