import { useTranslation } from "react-i18next";


const SelectOptions = () => {
  const { t } = useTranslation(["constantsGlobalSidebar"])

  const items1 = [
    {
      "title": t('constantsGlobalSidebar:item1.title1'),
      "url": "/intro/how_to",
      "index": 2
    },
    {
      "title": t('constantsGlobalSidebar:item1.title2'),
      "url": "/intro/utbas_questionnaire",
      "index": 3
    },
    {
      "title": t('constantsGlobalSidebar:item1.title3'),
      "url": "/intro/avoidance_questionnaire",
      "index": 4,
      "subTitle": {
  
        "url": "/intro/welcome_user",
        "index": 5
  
  
      }
    },
  
    {
      "title": t('constantsGlobalSidebar:item1.title4'),
      "url": "/intro/fne_questionnaire",
      "index": 6
    },
    {
      "title": t('constantsGlobalSidebar:item1.title5'),
      "url": "/intro/dass_questionnaire",
      "index": 7
    },
  ];
  
  const items2 = [
    {
      "title": t('constantsGlobalSidebar:item2.title1'),
      "url": "/part_1/waiting_for_the_bus",
      "index": 9
    },
    {
      "title": t('constantsGlobalSidebar:item2.title2'),
      "url": "/part_1/thinking_exercise_1",
      "index": 10,
      "subTitle": {
  
        "url": "/part_1/lets_review",
        "index": 11
      }
      ,
      "subTitle1": {
  
        "url": "/part_1/thinking_exercise_1_sample_answers",
        "index": 12
  
  
      }
    },
  
    {
      "title": t('constantsGlobalSidebar:item2.title3'),
      "url": "/part_1/thinking_exercise_2",
      "index": 13,
      "subTitle": {
  
        "url": "/part_1/thinking_exercise_2_sample_answers",
        "index": 14
      }
    },
  
    {
      "title": t('constantsGlobalSidebar:item2.title4'),
      "url": "/part_1/what_is_a_causal_thought",
      "index": 15,
      "subTitle": {
  
        "url": "/part_1/causal_thoughts",
        "index": 16
      }
    },
  
    {
      "title": t('constantsGlobalSidebar:item2.title5'),
      "url": "/part_1/checklist_of_errors",
      "index": 17
    },
    {
      "title": t('constantsGlobalSidebar:item2.title6'),
      "url": "/part_1/thinking_exercise_3",
      "index": 18,
      "subTitle": {
  
        "url": "/part_1/thinking_exercise_3_sample_answers",
        "index": 19
      }
    },
  
    {
      "title": t('constantsGlobalSidebar:item2.title7'),
      "url": "/part_1/thinking_exercise_4",
      "index": 20,
      "subTitle": {
  
        "url": "/part_1/thinking_exercise_4_sample_answers",
        "index": 21
      }
      ,
      "subTitle1": {
  
        "url": "/part_1/complete",
        "index": 22
      }
    },
  
  ];
  
  const items3 = [
  
    {
      "title": t('constantsGlobalSidebar:item3.title1'),
      "url": "/part_2/challenge_1",
      "index": 24,
      "subTitle": {
  
        "url": "/part_2/challenge_1_sample_answers",
        "index": 25
      }
    },
  
    {
      "title": t('constantsGlobalSidebar:item3.title2'),
      "url": "/part_2/challenge_2",
      "index": 26,
      "subTitle": {
  
        "url": "/part_2/challenge_2_sample_answers",
        "index": 27
      }
    },
  
    {
      "title": t('constantsGlobalSidebar:item3.title3'),
      "url": "/part_2/challenge_3",
      "index": 28,
      "subTitle": {
  
        "url": "/part_2/challenge_3_sample_answers",
        "index": 29
      }
    },
  
    {
      "title": t('constantsGlobalSidebar:item3.title4'),
      "url": "/part_2/challenge_4",
      "index": 30,
      "subTitle": {
  
        "url": "/part_2/challenge_4_sample_answers",
        "index": 31
      }
    },
  
    {
      "title": t('constantsGlobalSidebar:item3.title5'),
      "url": "/part_2/challenge_5",
      "index": 32,
      "subTitle": {
  
        "url": "/part_2/challenge_5_sample_answers",
        "index": 33
      }
    },
  
  ];
  
  const items4 = [
    {
      "title": t('constantsGlobalSidebar:item4.title1'),
      "url": "/part_3/bens_model",
      "index": 35,
      "subTitle": {
  
        "url": "/part_3/bens_model_2",
        "index": 36
      }
    },
  
    {
      "title": t('constantsGlobalSidebar:item4.title2'),
      "url": "/part_3/avoidance_and_safety_behaviours",
      "index": 37,
      "subTitle": {
  
        "url": "/part_3/another_example_of_safety_behaviours",
        "index": 38,
  
      }
      ,
      "subTitle1": {
  
        "url": "/part_3/lets_recap",
        "index": 39,
  
      }
    },
  
    {
      "title": t('constantsGlobalSidebar:item4.title3'),
      "url": "/part_3/causal_thoughts_and_safety_behaviours",
      "index": 40,
      "subTitle": {
  
        "url": "/part_3/more_safety_behaviours",
        "index": 41,
  
      },
      "subTitle1": {
  
        "url": "/part_3/safety_behaviours",
        "index": 42,
  
      }
    },
  
    {
      "title": t('constantsGlobalSidebar:item4.title4'),
      "url": "/part_3/physiological_anxiety_symptoms",
      "index": 43
    },
    {
      "title": t('constantsGlobalSidebar:item4.title5'),
      "url": "/part_3/self-focussed_imagery",
      "index": 44
    },
    {
      "title": t('constantsGlobalSidebar:item4.title6'),
      "url": "/part_3/your_model",
      "index": 45
      ,
  
      "subTitle": {
  
        "url": "/part_3/your_model_of_social_anxiety",
        "index": 46,
  
      }
    }
  
  ]
  
  const items5 = [
    {
      "title": t('constantsGlobalSidebar:item5.title1'),
      "url": "/part_4/perfectionism",
      "index": 48
    },
    {
      "title": t('constantsGlobalSidebar:item5.title2'),
      "url": "/part_4/why_it_really_doesnt_matter",
      "index": 49
    },
    {
      "title": t('constantsGlobalSidebar:item5.title3'),
      "url": "/part_4/time_to_plan",
      "index": 50
    },
    {
      "title": t('constantsGlobalSidebar:item5.title4'),
      "url": "/part_4/predictions",
      "index": 51,
      "subTitle": {
  
        "url": "/part_4/prediction_probabilities",
        "index": 52,
  
      }
    },
  
    {
      "title": t('constantsGlobalSidebar:item5.title5'),
      "url": "/part_4/the_experiment",
      "index": 53,
      "subTitle": {
  
        "url": "/part_4/experiment_completed",
        "index": 54,
  
      }
    },
  
    {
      "title": t('constantsGlobalSidebar:item5.title6'),
      "url": "/part_4/perceived_negative_evaluations",
      "index": 55
    },
    {
      "title": t('constantsGlobalSidebar:item5.title7'),
      "url": "/part_4/behavioural_causal_thought",
      "index": 56,
      "subTitle": {
  
        "url": "/part_4/behavioural_causal_thought_sample_answers",
        "index": 57,
  
      },
      "subTitle1": {
  
        "url": "/part_4/what_was_learned",
        "index": 58,
  
      },
      "subTitle2": {
  
        "url": "/part_4/avoid",
        "index": 59,
  
      },
      "subTitle3": {
  
        "url": "/part_4/continue",
        "index": 59,
  
      },
  
    },
  
  ];
  
  const items6 = [
    {
      "title": t('constantsGlobalSidebar:item6.title1'),
      "url": "/part_5/should_statements",
      "index": 61
    },
    {
      "title": t('constantsGlobalSidebar:item6.title2'),
      "url": "/part_5/tyranny_of_the_shoulds",
      "index": 62
    },
    {
      "title": t('constantsGlobalSidebar:item6.title3'),
      "url": "/part_5/what_do_my_shoulds_do_for_me",
      "index": 63
    },
    {
      "title": t('constantsGlobalSidebar:item6.title4'),
      "url": "/part_5/advantages_and_disadvantages_of_shoulds",
      "index": 64,
      "subTitle": {
  
        "url": "/part_5/should_sample_answers",
        "index": 65,
  
      },
    },
  
  ];
  
  const items7 = [
    {
  
      "index": 67,
      "url": "/part_6/child"
    },
    {
      "title": t('constantsGlobalSidebar:item7.title1'),
      "url": "/part_6/att",
      "index": 68
    },
    {
      "title": t('constantsGlobalSidebar:item7.title2'),
      "url": "/part_6/sar",
      "index": 69,
      "subTitle": {
  
        "url": "/part_6/sar_responses",
        "index": 70,
  
      },
    },
  
    {
      "title": t('constantsGlobalSidebar:item7.title3'),
      "url": "/part_6/mental_imagery",
      "index": 71
    },
    {
      "title": t('constantsGlobalSidebar:item7.title4'),
      "url": "/part_6/more_observer_bias",
      "index": 72
    }
  ];
  
  const items8 = [
    {
  
      "index": 74,
      "url": "/part_7/child",
    },
    {
      "title": t('constantsGlobalSidebar:item8.title1'),
      "url": "/part_7/postevent_rumination",
      "index": 75
    },
    {
      "title": t('constantsGlobalSidebar:item8.title2'),
      "url": "/part_7/ways_to_protect_yourself",
      "index": 76
    },
    {
      "title": t('constantsGlobalSidebar:item8.title3'),
      "url": "/part_7/a_final_thought",
      "index": 77
    },
    {
      "title": t('constantsGlobalSidebar:item8.title4'),
      "url": "/part_7/handouts",
      "index": 78
    }
  ];
  
  const items9 = [
    {
      "title": t('constantsGlobalSidebar:item9.title1'),
      "url": "/part_7/avoidance_questionnaire",
      "index": 80
    },
    {
      "title": t('constantsGlobalSidebar:item9.title2'),
      "url": "/part_7/dass_questionnaire",
      "index": 81
    },
    {
      "title": t('constantsGlobalSidebar:item9.title3'),
      "url": "/part_7/fne_questionnaire",
      "index": 82
    },
    {
      "title": t('constantsGlobalSidebar:item9.title4'),
      "url": "/part_7/complete",
      "index": 83
    }
  ];
  
  return {
    items1,
    items2,
    items3,
    items4,
    items5,
    items6,
    items7,
    items8,
    items9
  };
  

}

export default SelectOptions;

