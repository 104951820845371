import React from 'react';
import { Helmet } from 'react-helmet';
import CheckboxSituations from '../templates/CheckboxSituations';
import { GetCheckboxes } from '../../../../services/part3/get-checkboxes';
import { DOMAIN_NAME } from '../../../../env/Baseurl';
import { useTranslation } from 'react-i18next';

const AnxietySymptoms = () => {
    const { t } = useTranslation(['actions', 'partThreeAnxietySymptoms']);
     
        const [checkboxes, errorMessage] = GetCheckboxes('physiological');          // Checkboxes list from API

         // ============Local Storage ==========================

        const userInfo = localStorage.getItem('user'); 
        const user = JSON.parse(userInfo);
        const languageCode =user.userInfo.languageCode;

        /*******   Page Params  *******/

        const title = t('partThreeAnxietySymptoms:title');
        
        const body = `<p>${t('partThreeAnxietySymptoms:content.line1') }</p><p>${ t('partThreeAnxietySymptoms:content.line2')}</p>`;

        const checkboxQuestion = t('partThreeAnxietySymptoms:content.checkboxQuestion');

        const link="/part_3/self-focussed_imagery";

        const validationMessage = t('partThreeAnxietySymptoms:content.validationMessage');

        const imageSrc = `${DOMAIN_NAME}images/${languageCode}/part3/physio.png`;

        /*******   Render  *******/
    
        return(
            <>
            <Helmet>
                <title> {t('partThreeAnxietySymptoms:helmet')}</title>
            </Helmet>
            <CheckboxSituations
                title={title}
                body={body}
                checkboxQuestion={checkboxQuestion}
                link={link}
                checkboxeslist={checkboxes}
                type={'create_physiological'}
                validationMessage={validationMessage}
                imageSrc={imageSrc}
                error={errorMessage}
                previousLink='part_3/safety_behaviours'
            />
            </>
        );
     
};

export default AnxietySymptoms;
