import { useEffect, useState} from 'react';
import axios from "axios";

import { BASE_URL } from '../../env/Baseurl';
import ErrorLogging from '../error-logs';
import RefreshToken from '../refresh-token';

const GetCurrentIndex =  () => {
        
    const [currentIndex, setCurrentIndex] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const fetchCurrentIndex = async () => {

        const userInfo = localStorage.getItem('user');
        const user = JSON.parse(userInfo);
        
        try{
  
            const response = await axios.get(BASE_URL + `get_index_value/${user.userInfo._id}`, {
                "headers": {
                "Authorization": `Bearer ${user.token}`,
                "content-type": "application/json",
                "languageCode": user.userInfo.languageCode,
                },   
            })
            setCurrentIndex(response.data);
        } catch(error){
            if (error?.response?.status === 401) {
                RefreshToken(user.userInfo._id)
                fetchCurrentIndex();
            }
            else{
                setErrorMessage('There is some error, please try again later.');
                ErrorLogging('HTTP GET /userResponce_ofchallexcersizepart' + error);
            }
        }     
    };

    useEffect(() => {
        fetchCurrentIndex();
    }, []);
  
  return [currentIndex, errorMessage];
};

export {
    GetCurrentIndex,
}