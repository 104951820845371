import React, { useEffect } from 'react';
import { Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import '../../../../styles/adminlte.min.css';
import '../../../../styles/dashboard-style.css';
import Header from '../../../global/Header';
import Sidebar from '../../../global/Sidebar';
import Footer from '../../../global/Footer';
import ModuleCompletionEmails from '../../../../services/module-completion-emails';
import audio from '../../../../Audio/attention.mp3';
import { myprintFunction } from '../../../../actions/NormalPrintFunction';
import FooterOfPrint from '../../../global/FootorOfPrint';
import { useTranslation } from 'react-i18next';

const Part6SecondPage = () => {

    const { t } = useTranslation(["partSixSecondPage", "actions"])

    /*******   Helper Functions  *******/

    let navigate = useNavigate();

    const nextPage = () => {

        navigate('/part_6/sar', { replace: true })

    };

    const previousPage = () => {

        navigate(`/part_6/gaining_control`, { replace: true })

    };
    /*******   Hooks  *******/

    useEffect(() => {

        ModuleCompletionEmails('mp3_file_download_email');        // Sending module completion email

    }, []);

    /*******   Render  *******/

    return (
        <>
            <div id="printAble"></div>
            <div id="mainContainer" className="dashboardContent">
                <Helmet>
                    <title>{t('partSixSecondPage:helmet')}</title>
                </Helmet>
                <div>
                    {/* <Header/> */}
                    <Header targeted_print_component_id={"main"} myprintFunction={myprintFunction} />
                </div>
                <div className="row">
                    <div className="col-md-2  position-static pl-0">
                        <div className="leftside">
                            <Sidebar />
                        </div>
                    </div>
                    <div className="col-md-10 content-widthsidebar content-wrapper">
                        <div className="card">
                            <div className="card-body">
                                <div id="main">
                                    <div className="card-body">
                                        <div className="title mb-3">
                                        {t('partSixSecondPage:title')}
                                        </div>
                                        <h2 className='attText'>
                                            <a href="https://iglebestg.ebizontech.biz/TP_iGlebeAPI/public/audio/clinician/default/attention.mp3" target="_blank" rel="noreferrer">{t('partSixSecondPage:content.line1')}</a>
                                        </h2>
                                        <p className='styTxt'>
                                        {t('partSixSecondPage:content.line2')} <a className='atlink' href={audio} target="_blank" rel="noreferrer"> {t('partSixSecondPage:content.line3')}</a>
                                        </p>
                                        <p>
                                        {t('partSixSecondPage:content.line4')}
                                        </p>
                                        <p>
                                        {t('partSixSecondPage:content.line5')}
                                        </p>
                                    </div>
                                    {/* <div>
                                        <FooterOfPrint/>
                                    </div> */}
                                </div>

                                <div className='row'>
                                    <div className='previous1 col-6'>
                                        <Button
                                            className="user-reponse-submit btn btn-primary link-sty"
                                            onClick={previousPage}
                                        >
                                            {t('actions:previous')}
                                        </Button>
                                    </div>
                                    <div className='next col-6'>
                                        <Button
                                            className="user-reponse-submit btn btn-primary link-sty"
                                            onClick={nextPage}
                                        >
                                             {t('actions:continue')}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 dashboard-footer">
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Part6SecondPage;